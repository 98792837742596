import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import "./styles.min.css";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";

import "./smsChat.css"
export default function NotesCards({ userNumber, smsNotificationsOn }) {
  const [newMessage, setNewMessage] = React.useState("MEEP");
  const [messages, setMessages] = React.useState([]);

  function removeTags(string) {
    return string
      .replace(/<[^>]*>/g, " ")
      .replace(/\s{2,}/g, " ")
      .replace(/^(&nbsp;|<br>)+/, "")
      .replace(/(&nbsp;|<br>)+$/, "")
      .replace(/&nbsp;/g, " ")
      .trim();
  }

  const handleChange = (msg) => {
    // console.log("[handleChange] msg: " + msg);

    const cleanMessage = removeTags(msg);
    // console.log("[handleChange] cleanMessage: " + cleanMessage);

    // setMessage(cleanMessage);
    setNewMessage(cleanMessage);
  };

  const handleSend = async (text: string) => {
    console.log("text: ", text);
    setNewMessage("");
    //add message to firebase
    const cleanText = removeTags(text);

    // remove dash from userNumber
    var newUserNumber = userNumber.replace(/-/g, "");
    //add +1 to userNumber
    newUserNumber = "+1" + newUserNumber;

    const message = {
      from: process.env.REACT_APP_SMS_FROM_NUMBER,
      to: newUserNumber,
      body: cleanText,
      date: new Date(),
    };

    console.log("message: ", message);
    await addDoc(collection(getFirestore(), "messages"), message);
    await addDoc(collection(getFirestore(), "smsChat"), message);

    fetchMessages();
  };

  const fetchMessages = async () => {
    // fetch message from firebase with condition
    var newUserNumber = userNumber.replace(/-/g, "");
    //add +1 to userNumber
    newUserNumber = "+1" + newUserNumber;

    //get our messsages first
    var q = query(
      collection(getFirestore(), "smsChat"),
      where("to", "==", newUserNumber)
    );

    var toList = [];

    const querySnapshotTo = await getDocs(q);
    querySnapshotTo.forEach((doc) => {
      toList.push({ id: doc.id, ...doc.data(), direction: "outgoing" });
    });

    //get their messsages second
    q = query(
      collection(getFirestore(), "smsChat"),
      where("from", "==", newUserNumber)
    );

    var fromList = [];

    const querySnapshotFrom = await getDocs(q);
    querySnapshotFrom.forEach((doc) => {
      fromList.push({ id: doc.id, ...doc.data(), direction: "incoming" });
    });

    console.log("fromList: ", fromList);

    //merge the two lists
    var allList = toList.concat(fromList);

    //sort the list on data
    allList.sort((a, b) => {
      return a.date - b.date;
    });

    setMessages(allList);
  };

  const RenderMessages = () => {
    const messageList = [];
    //console.log("RenderMessages Loop");

    const showDataAndTime = (date) => {
      // format stirng to xx/xx/xxxx xx:xx
      const dateStr = date.toLocaleDateString("en-US");
      const timeStr = date.toLocaleTimeString("en-US");
      return dateStr + " " + timeStr;
    };

    messages.forEach((message) => {
      console.log("message: ", message);
      const date = new Date(message.date.seconds * 1000);
      console.log("date: ", date);
      messageList.push(
        <>
        {message.direction === "incoming" ? <p className="messageLeft">{showDataAndTime(date)}</p> : <p className="messageRight">{showDataAndTime(date)}</p>}
          <Message
            model={{
              message: message.body,
              sentTime: "2:30",
              direction: message.direction,
              position: "single",
            }}
          />
        </>
      );
    });

    return messageList;
  };

  React.useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <>
      <IonCard className="ion-padding">
        <IonCardHeader>
          <IonToolbar className="remove-bg">
            <IonCardTitle className="ion-text-center">
              SMS Chat (BETA)
            </IonCardTitle>
          </IonToolbar>
        </IonCardHeader>
        <IonCardContent>
          {userNumber !== "" && smsNotificationsOn ? (
            <div style={{ position: "relative", height: "500px" }}>
              <MainContainer>
                <ChatContainer>
                  <MessageList>
                    {/* <Message
                    model={{
                      message: "Hello my friend",
                      sentTime: "just now",
                      sender: "Joe",
                      direction: "outgoing",
                      position: "single",
                    }}
                  /> */}
                    {RenderMessages()}
                  </MessageList>
                  <MessageInput
                    placeholder="Type message here"
                    onChange={handleChange}
                    onSend={handleSend}
                    attachButton={false}
                  />
                </ChatContainer>
              </MainContainer>
            </div>
          ) : (
            <p>
              Member has no number on file or has turned off SMS communication
            </p>
          )}
        </IonCardContent>
      </IonCard>
    </>
  );
}
