// loading spinner
//import spinner css
import "./Loaderv2.css";
export default function Loader() {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
