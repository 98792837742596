//tempalte for component in react
import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../lib/context";

import "./MyMembers.css";
import GroupMetrics from "./Metrics/PerSelectedGroup";
import AllUsersMetrics from "./Metrics/AllUsers";
import { starHalfSharp, starSharp } from "ionicons/icons";

function RenderCompliance({ compliant, compliantSetDate }) {
  if (compliant) {
    if(compliantSetDate === null) {
      return <IonLabel color="success">Compliant</IonLabel>;
    }
    else{
      const date = compliantSetDate.toDate();
      // check to see if before 20th of month
      if(date.getDate() < 20) {
      return <IonLabel color="success">Compliant</IonLabel>;
      }
      else{
        return <IonLabel color="success">Compliant <IonIcon icon={starSharp}></IonIcon></IonLabel>;
      }
    }
  } else {
    return <IonLabel color="danger">Non-Compliant</IonLabel>;
  }
}

const RenderHraComplete = ({ hraComplete }) => {
  console.log(hraComplete);
  if (hraComplete) {
    return <IonLabel color="success">Complete</IonLabel>;
  }
  return <IonLabel color="danger">Incomplete</IonLabel>;
};

export default function MyMembers() {
  const [myMembers, setMyMembers] = useState([]);
  const { user, profile } = useContext(UserContext);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState("");

  const fetchMyMembers = async () => {
    //get sessiosn from firebase
    const q = query(
      collection(getFirestore(), "users"),
      where("groupID", "==", group),
      where("active", "==", true)
    );

    var members = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      members.push({ id: doc.id, ...doc.data() });
    });

    //sort members by lastName alphabetically
    members.sort((a, b) => {
      if (a.lastName < b.lastName) {
        return -1;
      }
      if (a.lastName > b.lastName) {
        return 1;
      }
      return 0;
    });

    setMyMembers(members);
  };

  const fetchGroups = async () => {
    //get sessiosn from firebase

    const q = query(collection(getFirestore(), "groups"));

    var groups = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      groups.push({ id: doc.id, ...doc.data() });
    });

    //sort groups by name alphabetically
    groups.sort((a, b) => {
      if (a.group < b.group) {
        return -1;
      }
      if (a.group > b.group) {
        return 1;
      }
      return 0;
    });

    setGroups(groups);
  };

  const RenderMyMembers = () => {
    let members = [];
    myMembers.forEach((member, index) => {
      members.push(
        <>
          <IonItem key={member.uid}>
            <IonAvatar slot="start">
              {" "}
              <img src={member.photoURL} />
            </IonAvatar>
            <IonLabel>
              {member.lastName}, {member.firstName} {member.dependent ? "(Dependent)" : ""} 
            </IonLabel>
            <IonLabel className="ion-text-wrap">{member.email}</IonLabel>
            <IonLabel>
              <RenderCompliance compliant={member.compliant} compliantSetDate={member.compliantSetDate || null}/>
            </IonLabel>
            <IonLabel>
              {member.preferredContactMethod
                ? member.preferredContactMethod
                : "N/A"}
            </IonLabel>
            <IonLabel className="ion-text-wrap">
              <RenderHraComplete hraComplete={member.hraComplete} />
            </IonLabel>

            <IonLabel>
              <IonButton href={"/member-dash/" + member.uid} target="_blank">
                Dashboard
              </IonButton>
            </IonLabel>
          </IonItem>
        </>
      );
    });

    if (members.length == 0) {
      return <div>No Members</div>;
    }

    return <>{members}</>;
  };

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol>
            <AllUsersMetrics />
          </IonCol>
          <IonCol>
            <GroupMetrics group={group} groupName={group} />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>My Members</IonCardTitle>
        </IonCardHeader>
        <div className="center">
          <IonItem></IonItem>
          <IonItem className="selectGroup">
            <IonLabel>Filter By Group</IonLabel>
            <IonSelect
              onFocus={(e) => fetchGroups()}
              onIonChange={(e) => setGroup(e.detail.value)}
              onIonDismiss={fetchMyMembers}
            >
              {groups.map((group) => {
                return (
                  <IonSelectOption value={group.groupID}>
                    {group.group}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
        </div>

        <IonCardContent>
          <IonItem>
            <IonAvatar slot="start"> </IonAvatar>
            <IonLabel>
              <b>Name</b>
            </IonLabel>
            <IonLabel>
              <b>Email</b>
            </IonLabel>
            <IonLabel>
              <b>Compliance</b>
            </IonLabel>
            <IonLabel>
              <b>Contact Method</b>
            </IonLabel>
            <IonLabel>
              <b>HRA Complete</b>
            </IonLabel>
            <IonLabel>
              <b>Coach Em'</b>
            </IonLabel>
          </IonItem>
          <RenderMyMembers />
        </IonCardContent>
      </IonCard>
    </>
  );
}
