import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useUserData } from "../../lib/hooks";
//import spinner
import React from "react";
import UpcomingSessions from "../../components/Appointment/UpcomingSessions";
import SessionLookup from "../../components/Appointment/SessionLookup";

const Appointment: React.FC = () => {
  const userData = useUserData();

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Appointment</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow>
            <IonCol>
              <UpcomingSessions />
            </IonCol>
            <IonCol>
              <SessionLookup />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Appointment;
