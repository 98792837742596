import React, { useCallback, useContext, useEffect, useState } from "react";
import { IonButton, useIonAlert } from "@ionic/react";
import axios from "axios";
import { MemberUidContext } from "../../../lib/context/MemberUidContext";
import { UserContext } from "../../../lib/context";
import { fetchGetUser } from "../../../API/memberAPI";
import { userType } from "../../../Types/user";

export default function ResendWelcomeEmail() {
  const memberUid = useContext(MemberUidContext);
  const { user, profile } = useContext(UserContext);
  const [presentAlert] = useIonAlert();
  const [userDatabase, setUserDatabase] = useState<userType[]>([]);
  const fetchGetUserDatabase = useCallback(async () => {
    const userData = await fetchGetUser(memberUid);
    setUserDatabase(userData);
  }, [memberUid]);

  // Use useEffect to call the fetchGetUserDatabase function when the component mounts
  useEffect(() => {
    fetchGetUserDatabase();
  }, [fetchGetUserDatabase]);
  const resendWelcomeEmail = async () => {
    const data = {
      uid: memberUid,
      email: userDatabase[0]?.email,
      access: userDatabase[0]?.access,
    };
    const config = {
      method: "post",
      url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/Login-ResendWelcomeEmail`,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        alert("Email Sent");
      })
      .catch(function (error) {
        console.log(error);
        alert("Error In Resending Invite, try again later and notify IT");
      });
  };

  return (
    <>
      <IonButton
        onClick={() =>
          presentAlert({
            header: "Resend Welcome Email",
            buttons: [
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {},
              },
              {
                text: "Send",
                handler: () => {
                  //takes the data
                  resendWelcomeEmail();
                },
              },
            ],
          })
        }
      >
        RESEND WELCOME EMAIL
      </IonButton>
    </>
  );
}
