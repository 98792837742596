export const getCurrentDate = () => {
  var today = new Date();

  var ddNum = today.getDate();
  var dd = String(today.getDate());
  if (ddNum < 10) {
    dd = "0" + dd;
  }

  var mmNum = today.getMonth() + 1; //January is 0!
  var mm = String(today.getMonth() + 1); //January is 0!
  if (mmNum < 10) {
    mm = "0" + mm;
  }

  var yyyy = today.getFullYear();
  var newdate = yyyy + "-" + mm + "-" + dd;

  return newdate;
};

export const decrementDate = (date) => {
  var dateArray = date.split("-");
  var year = Number(dateArray[0]);
  var month = Number(dateArray[1]);
  var day = Number(dateArray[2]);

  var dayFinal = "";
  var monthFinal = "";

  //if day is 1, decrement month
  if (day === 1) {
    //check if month is 1, decrement year
    if (month === 1) {
      year = year - 1;
      month = 12;
      day = 31;
    }

    //check if month has 31 days
    else if (
      month === 1 ||
      month === 3 ||
      month === 5 ||
      month === 7 ||
      month === 8 ||
      month === 10 ||
      month === 12
    ) {
      month = month - 1;
      day = 31;
    } else if (month === 4 || month === 6 || month === 9 || month === 11) {
      month = month - 1;

      day = 30;
    }

    //check if month has 28 days
    else if (month === 2) {
      if (year % 4 === 0) {
        day = 29;
      } else {
        day = 28;
      }
    }
  } else {
    day = day - 1;
  }

  //check to see if day is less than 10
  if (day < 10) {
    dayFinal = "0" + day;
  } else {
    dayFinal = String(day);
  }

  //check if month is less than 10
  if (month < 10) {
    monthFinal = "0" + month;
  } else {
    monthFinal = String(month);
  }

  return year + "-" + monthFinal + "-" + dayFinal;
};

export const incrementDate = (date) => {
  var dateArray = date.split("-");
  var year = Number(dateArray[0]);
  var month = Number(dateArray[1]);
  var day = Number(dateArray[2]);

  var dayFinal = "";
  var monthFinal = "";

  //check if it is a month with 31 days
  if (
    month === 1 ||
    month === 3 ||
    month === 5 ||
    month === 7 ||
    month === 8 ||
    month === 10 ||
    month === 12
  ) {
    //check if date goes past the end of the month
    if (day === 31) {
      day = 1;
      //check if current month is december and increment year and change month to 1
      if (month === 12) {
        month = 1;
        year = year + 1;
      } else {
        month = month + 1;
      }
    } else {
      day = day + 1;
    }
  }

  //check if it is a month with 30 days
  else if (month === 4 || month === 6 || month === 9 || month === 11) {
    //check if date goes past the end of the month
    if (day === 30) {
      //we know december is not going to be in here so we can assume the next month is not 13
      day = 1;
      month = month + 1;
    } else {
      day = day + 1;
    }
  }

  //then the final months is feb so we can do an else
  else {
    //check if date goes past the end of the month
    if (day === 28) {
      day = 1;
      month = month + 1;
    } else {
      day = day + 1;
    }
  }

  //check to see if day is less than 10
  if (day < 10) {
    dayFinal = "0" + day;
  } else {
    dayFinal = String(day);
  }

  //check if month is less than 10
  if (month < 10) {
    monthFinal = "0" + month;
  } else {
    monthFinal = String(month);
  }

  return year + "-" + monthFinal + "-" + dayFinal;
};

//export all functions
