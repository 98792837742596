/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonMenuToggle,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonAlert,
  useIonPicker,
} from "@ionic/react";
import axios from "axios";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import {
  callOutline,
  checkbox,
  chevronBackSharp,
  chevronForwardSharp,
  informationCircle,
  mail,
  squareOutline,
  trashOutline,
} from "ionicons/icons";
//import spinner
import React, { useContext, useEffect, useState } from "react";
import Loader from "../Loaderv2";
import {
  getCurrentDate,
  decrementDate,
  incrementDate,
} from "../../HelperFunctions/dates";

import SuperHappyFilled from "../../assets/icons/super-happy-filled.svg";
import HappyFilled from "../../assets/icons/happy-filled.svg";
import Neutral from "../../assets/icons/neutral-filled.svg";
import SadFilled from "../../assets/icons/sad-filled.svg";
import SuperSadFilled from "../../assets/icons/super-sad-filled.svg";

import GoalsCard from "./GoalsCard";

import CoachNotes from "./CoachNotes";

import SmsChat from "./smsChat";
import WalmartHealthCard from "./WalmartHealthCard";

import "./MemberDash.css";
import { UserContext } from "../../lib/context";
import { validateEmail } from "../../lib/helpers";
import MemberHistory from "./MemberHistory/MemberHistory";
import { MemberUidContext } from "../../lib/context/MemberUidContext";
import AddChangePhoneBTN from "./AddChangePhoneBTN";
import PreferredContactMethod from "./PreferredContactMethod";
import ComplianceModalCoachNote from "./CompliantModaCoachNote";
import MemberHRA from "./MemberHRA";
import MemberProfile from "./Profile";

const MemberDash: React.FC = () => {
  const [uid, setUid] = React.useState<string>(
    window.location.pathname.split("/")[2]
  );
  const [member, setMember] = React.useState<any>();
  const { user, profile } = useContext(UserContext);

  const fetchMember = async () => {
    const docRef = doc(getFirestore(), "users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setMember(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  function MemberFound() {
    const [scans, setScans] = React.useState([]);
    const [todos, setTodos] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loading2, setLoading2] = React.useState(false);
    const [loading3, setLoading3] = React.useState(false);
    const [date, setDate] = useState(getCurrentDate());
    const [showDataPicker, setShowDataPicker] = useState(false);
    const [mealLogs, setMealLogs] = useState([]);
    const [exerciseLogs, setExerciseLogs] = useState([]);
    const [moodLogs, setMoodLogs] = useState([]);
    const [metricsLogs, setMetricsLogs] = useState([]);
    const [glucoseLogs, setGlucoseLogs] = useState([]);
    const [changeEmail, setChangeEmail] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    const [appointment, setAppointment] = useState([]);
    const [coach, setCoach] = useState({});
    const [presentAlert] = useIonAlert();
    const memberUid = useContext(MemberUidContext);

    //facescan
    const GetScanList = async () => {
      axios
        .post(
          `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/DFX-GetMeasurementList`,
          { uid: member.uid }
        )
        .then((res) => {
          console.log(res.data);
          setScans(res.data);
          setLoading(false);
          console.log("feteched");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const fetchTodos = async () => {
      //get sessiosn from firebase
      const q = query(
        collection(getFirestore(), "todos"),
        where("uid", "==", member.uid)
      );

      var todos = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        todos.push({ id: doc.id, ...doc.data() });
      });

      setTodos(todos);
    };

    const fetchSessions = async () => {
      //get sessiosn from firebase
      const q = query(
        collection(getFirestore(), "sessions"),
        where("memberUid", "==", member.uid)
      );

      var sessions = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        sessions.push({ id: doc.id, ...doc.data() });
      });

      setAppointment(sessions);
    };

    const RenderSessionStartTimePretty = ({ startTime }) => {
      const sessionStartTime = new Date(startTime);
      const sessionStartTimeAndDatePretty = sessionStartTime.toLocaleString(
        "en-US",
        {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      );

      return <>{sessionStartTimeAndDatePretty}</>;
    };

    const fetchCoach = async () => {
      const docRef = doc(getFirestore(), "users", member.coach);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log(docSnap.data(), "coach");
        setCoach(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };

    const getDateFromTimestamp = (timestamp: number) => {
      const date = new Date(timestamp * 1000);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        getHoursFrom24HourTime(date.getHours()) +
        ":" +
        date.getMinutes() +
        " " +
        getPmorAm(date.getHours())
      );
    };

    useEffect(() => {
      GetScanList();
      fetchTodos();
      fetchSessions();
      fetchCoach();
    }, []);

    //get 12 hour time from timestamp
    const getHoursFrom24HourTime = (hour: number) => {
      if (hour > 12) {
        return hour - 12;
      } else if (hour === 0) {
        return 12;
      } else {
        return hour;
      }
    };

    const getPmorAm = (hour: number) => {
      if (hour === 24) {
        return "AM";
      } else if (hour > 11) {
        return "PM";
      } else {
        return "AM";
      }
    };

    const renderScanItem = (scan: any, index: any) => {
      if (scan.StatusID === "COMPLETE") {
        return (
          <>
            <IonItem key={index}>
              <IonLabel>{getDateFromTimestamp(scan.Created)}</IonLabel>
              <IonButton slot="end" href={"scan/" + scan.ID}>
                View
              </IonButton>
            </IonItem>
          </>
        );
      } else {
        return (
          <>
            <IonItem key={index}>
              <IonLabel>{getDateFromTimestamp(scan.Created)}</IonLabel>
              <IonLabel slot="end">
                Not-Completed
              </IonLabel>
            </IonItem>
          </>
        );
      }
    };

    const ShowCurrentDate = () => {
      if (date === getCurrentDate()) {
        return (
          <IonLabel className="date" onClick={() => setShowDataPicker(true)}>
            Today
          </IonLabel>
        );
      } else {
        return (
          <IonLabel className="date" onClick={() => setShowDataPicker(true)}>
            {date}
          </IonLabel>
        );
      }
    };

    //Get Meal Logs from firebase, unsubs when unmounting
    useEffect(() => {
      const q = query(
        collection(getFirestore(), "meals"),
        where("uid", "==", member.uid),
        where("date", "==", date)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const logs = [];
        querySnapshot.forEach((doc) => {
          logs.push({ id: doc.id, ...doc.data() });
        });

        setMealLogs(logs);
      });

      return () => {
        unsubscribe();
      };
    }, [date]);

    //Get Exercise Logs from firebase, unsubs when unmounting
    useEffect(() => {
      const q = query(
        collection(getFirestore(), "exercise"),
        where("uid", "==", member.uid),
        where("date", "==", date)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const logs = [];
        querySnapshot.forEach((doc) => {
          logs.push({ id: doc.id, ...doc.data() });
        });

        setExerciseLogs(logs);
      });

      return () => {
        unsubscribe();
      };
    }, [date]);

    //Get Exercise Logs from firebase, unsubs when unmounting
    useEffect(() => {
      const q = query(
        collection(getFirestore(), "moods"),
        where("uid", "==", member.uid),
        where("date", "==", date)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const logs = [];
        querySnapshot.forEach((doc) => {
          logs.push({ id: doc.id, ...doc.data() });
        });

        setMoodLogs(logs);
      });

      return () => {
        unsubscribe();
      };
    }, [date]);

    useEffect(() => {
      const q = query(
        collection(getFirestore(), "metrics"),
        where("uid", "==", member.uid),
        where("date", "==", date)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const logs = [];
        querySnapshot.forEach((doc) => {
          logs.push({ id: doc.id, ...doc.data() });
        });

        setMetricsLogs(logs);
      });

      return () => {
        unsubscribe();
      };
    }, [date]);

    useEffect(() => {
      const q = query(
        collection(getFirestore(), "glucose"),
        where("uid", "==", member.uid),
        where("date", "==", date)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const logs = [];
        querySnapshot.forEach((doc) => {
          logs.push({ id: doc.id, ...doc.data() });
        });

        setGlucoseLogs(logs);
      });

      return () => {
        unsubscribe();
      };
    }, [date]);

    const deleteMeal = async (id: string) => {
      const db = getFirestore();
      const ref = doc(db, `meals/${id}`);
      await deleteDoc(ref);
    };

    const deleteMood = async (id: string) => {
      const db = getFirestore();
      const ref = doc(db, `moods/${id}`);
      await deleteDoc(ref);
    };

    const deleteExercise = async (id: string) => {
      const db = getFirestore();
      const ref = doc(db, `exercise/${id}`);
      await deleteDoc(ref);
    };
    const deleteMetrics = async (id: string) => {
      const db = getFirestore();
      const ref = doc(db, `metrics/${id}`);
      await deleteDoc(ref);
    };
    const deleteGlucose = async (id: string) => {
      const db = getFirestore();
      const ref = doc(db, `glucose/${id}`);
      await deleteDoc(ref);
    };

    const openSession = (sessionLink) => {
      window.open(sessionLink, "_blank");
    };
    const openSessionDetails = (sessionID) => {
      window.open("/session/" + sessionID, "_self");
    };

    const RenderCompliance = () => {
      if (member === undefined) {
        return;
      } else if (member["compliant"]) {
        return <IonLabel color="success">Compliant</IonLabel>;
      } else {
        return <IonLabel color="danger">Non-Compliant</IonLabel>;
      }
    };

    const RenderSessions = () => {
      let sessions = [];
      appointment.forEach((session, index) => {
        sessions.push(
          <IonItem
            key={session.id}
            className="todo"
            lines="none"
            color="primary"
            routerLink={"/session/" + session.id}
          >
            <IonIcon icon={informationCircle} slot="end"></IonIcon>{" "}
            <IonLabel class="ion-text-center todo-text ion-text-wrap">
              {" "}
              <RenderSessionStartTimePretty startTime={session.startTime} />
            </IonLabel>{" "}
          </IonItem>
        );
      });

      if (sessions.length == 0) {
        return <div>No Upcomming Appointment</div>;
      }

      return <>{sessions}</>;
    };

    const RenderMoodIcon = ({ mood }) => {
      switch (mood) {
        case "super-happy":
          return <IonIcon className="mood-icon " icon={SuperHappyFilled} />;
        case "happy":
          return <IonIcon className="mood-icon" icon={HappyFilled} />;
        case "neutral":
          return <IonIcon className="mood-icon" icon={Neutral} />;
        case "sad":
          return <IonIcon className="mood-icon" icon={SadFilled} />;
        case "super-sad":
          return <IonIcon className="mood-icon" icon={SuperSadFilled} />;
        default:
          return <IonIcon className="mood-icon ion-margin" icon={Neutral} />;
      }
    };

    const RenderMetricType = ({ type }) => {
      switch (type) {
        case "a1c":
          return "A1C";
        case "blood-pressure":
          return "Blood Pressure";
        case "body-fat":
          return "Body Fat";
        case "steps":
          return "Steps";
        case "waist-circumference":
          return "Waist Circumference";
        case "weight":
          return "Weight";
        case "bmi":
          return "BMI";
        default:
          return "DEF";
      }
    };

    const RenderMetric = ({ metric }) => {
      return (
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel>{RenderMetricType({ type: metric.type })}</IonLabel>
            </IonCol>
            <IonCol>
              <IonLabel>
                {metric.type == "blood-pressure" ? (
                  <>
                    D: {metric.systolic} S: {metric.diastolic}
                  </>
                ) : (
                  <>{metric.value}</>
                )}
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    };

    const updateCompliance = async (compliant) => {
      const db = getFirestore();
      const ref = doc(db, `users/${member.uid}`);
      await updateDoc(ref, {
        compliant: compliant,
      });

      fetchMember();
    };

    const [viewComplianceHistory, setViewComplianceHistory] = useState(false);

    // const RenderComplianceCard = () => {
    //   return (
    //     <>
    //       <IonCard>
    //         <IonCardHeader>
    //           <IonCardTitle>Check In</IonCardTitle>
    //         </IonCardHeader>
    //         <IonCardContent>
    //           <p>Has the member checked in with a health coach this month?</p>

    //           {member["compliant"] ? (
    //             <IonIcon
    //               color="success"
    //               className="complianceCheckBox"
    //               icon={checkbox}
    //               onClick={() => updateCompliance(!member["compliant"])}
    //             ></IonIcon>
    //           ) : (
    //             <IonIcon
    //               color="danger"
    //               className="complianceCheckBox"
    //               icon={squareOutline}
    //               onClick={() => updateCompliance(!member["compliant"])}
    //             ></IonIcon>
    //           )}
    //           <br></br>
    //           <a
    //             onClick={() => setViewComplianceHistory(true)}
    //             className="compliance-link"
    //           >
    //             View Compliance History
    //           </a>
    //         </IonCardContent>
    //       </IonCard>
    //     </>
    //   );
    // };

    const RenderComplianceHistory = ({ tracking }) => {
      let history = [];

      const updateHistory = (day, completed, indexOf) => {
        console.log("INDEX", indexOf);
        console.log("DAY", day);
        console.log("COMPLETED", completed);
        const currentMember = member;

        if (completed === true) {
          currentMember.complianceHistory[indexOf][day] = false;

          const db = getFirestore();
          const ref = doc(db, `users/${currentMember.uid}`);
          const data = {
            complianceHistory: currentMember.complianceHistory,
          };
          updateDoc(ref, data).then(() => {
            // goal = currentGoal;
            setViewComplianceHistory(false);
            fetchMember();
          });
        } else {
          currentMember.complianceHistory[indexOf][day] = true;

          const db = getFirestore();
          const ref = doc(db, `users/${currentMember.uid}`);
          const data = {
            complianceHistory: currentMember.complianceHistory,
          };
          updateDoc(ref, data).then(() => {
            // goal = currentGoal;
            setViewComplianceHistory(false);

            fetchMember();
          });
        }
      };

      tracking.forEach((element) => {
        for (const item in element) {
          const indexOf = tracking.indexOf(element);

          if (element[item] === true) {
            history.push(
              <IonItem
                key={item}
                className="goal"
                lines="none"
                color="success"
                onClick={() => updateHistory(item, element[item], indexOf)}
              >
                <IonLabel class="goal-text ion-text-center">{item} </IonLabel>{" "}
              </IonItem>
            );
          } else {
            history.push(
              <IonItem
                key={item}
                className="goal"
                lines="none"
                color="danger"
                onClick={() => updateHistory(item, element[item], indexOf)}
              >
                <IonLabel class="goal-text ion-text-center">{item} </IonLabel>{" "}
              </IonItem>
            );
          }
        }
      });

      return <>{history}</>;
    };

    const TerminateMemberButton = () => {
      const [presentAlert] = useIonAlert();

      const terminateMember = async () => {
        console.log("Terminating Member");
        const db = getFirestore();
        const ref = doc(db, `users/${member.uid}`);
        await updateDoc(ref, {
          active: false,
        }).then(() => {
          window.location.href = "/member-dash/" + uid;
        });
      };

      return (
        <>
          <IonButton
            onClick={() =>
              presentAlert({
                header: "WARNING",
                message:
                  "Are you sure you want to terminate this member? The system is very non-reversible... For now. Once this is clicked, the member will be removed from the system and all data will be lost.",
                buttons: [
                  {
                    text: "Cancel",
                    role: "cancel",
                  },
                  {
                    text: "OK",
                    role: "confirm",
                    handler: () => {
                      terminateMember();
                    },
                  },
                ],
              })
            }
            color="danger"
            className="ion-margin"
          >
            Terminate Member
          </IonButton>
        </>
      );
    };

    const RenderCoverageCard = () => {
      const [present] = useIonPicker();

      const RenderAccessLevel = ({ access }) => {
        if (access === "telemedicineOnly") {
          return <>Telemedicine Only</>;
        } else if (access === "facescanOnly") {
          return <>Facescan Only</>;
        } else if (access === "fullAccess") {
          return <>Full Access</>;
        }
      };

      const updateTelemedicine = async (telemedicine) => {
        const db = getFirestore();
        const ref = doc(db, `users/${member.uid}`);
        await updateDoc(ref, {
          hasTelemedicine: !telemedicine,
        });
        fetchMember();
      };

      const updateFacescan = async (facescan) => {
        const db = getFirestore();
        const ref = doc(db, `users/${member.uid}`);
        await updateDoc(ref, {
          hasFacescan: !facescan,
        });
        fetchMember();
      };

      const updateCoaching = async (coaching) => {
        const db = getFirestore();
        const ref = doc(db, `users/${member.uid}`);
        await updateDoc(ref, {
          hasCoaching: !coaching,
        });
        fetchMember();
      };

      const updateQuestis = async (questis) => {
        const db = getFirestore();
        const ref = doc(db, `users/${member.uid}`);
        await updateDoc(ref, {
          hasQuestis: !questis,
        });
        fetchMember();
      };
      const updateGlucoseLog = async (glucose) => {
        const db = getFirestore();
        const ref = doc(db, `users/${member.uid}`);
        await updateDoc(ref, {
          hasGlucose: !glucose,
        });
        fetchMember();
      };

      const openPicker = async () => {
        present({
          columns: [
            {
              name: "access",
              options: [
                {
                  text: "Full Access",
                  value: "fullAccess",
                },
                {
                  text: "Facescan Only",
                  value: "facescanOnly",
                },
                {
                  text: "Telemedicine Only",
                  value: "telemedicineOnly",
                },
              ],
            },
          ],
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Confirm",
              handler: (value) => {
                //update access level
                const db = getFirestore();
                const ref = doc(db, `users/${member.uid}`);
                updateDoc(ref, {
                  access: value.access.value,
                });
                fetchMember();
              },
            },
          ],
        });
      };

      return (
        <>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Coverage and Access</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <RenderAccessLevel access={member.access} />
              <br></br>
              <IonButton onClick={openPicker}>Change Access</IonButton>
              {member.access === "fullAccess" ? (
                <IonList>
                  <IonItem>
                    <IonLabel>Telemedicine</IonLabel>
                    <IonToggle
                      slot="end"
                      checked={member.hasTelemedicine}
                      onIonChange={() =>
                        updateTelemedicine(member.hasTelemedicine)
                      }
                    ></IonToggle>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Facescan</IonLabel>
                    <IonToggle
                      slot="end"
                      checked={member.hasFacescan}
                      onIonChange={() => updateFacescan(member.hasFacescan)}
                    ></IonToggle>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Coaching</IonLabel>
                    <IonToggle
                      slot="end"
                      checked={member.hasCoaching}
                      onIonChange={() => updateCoaching(member.hasCoaching)}
                    ></IonToggle>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Questis</IonLabel>
                    <IonToggle
                      slot="end"
                      checked={member.hasQuestis}
                      onIonChange={() => updateQuestis(member.hasQuestis)}
                    ></IonToggle>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Glucose Logging</IonLabel>
                    <IonToggle
                      slot="end"
                      checked={member.hasGlucose}
                      onIonChange={() => updateGlucoseLog(member.hasGlucose)}
                    ></IonToggle>
                  </IonItem>
                </IonList>
              ) : null}
            </IonCardContent>
          </IonCard>
          {/* <IonCard>
            <IonCardTitle>Recent History</IonCardTitle>
            <MemberUidContext.Provider value={memberUid}>
              <MemberHistory />
            </MemberUidContext.Provider>
          </IonCard> */}
        </>
      );
    };

    const updateMemberEmail = async (email) => {
      setLoading2(true);
      console.log(email, member.uid);
      if (!validateEmail(email)) {
        alert("Please enter a valid email address.");
        return;
      }

      const data = { uid: member.uid, newUserEmail: email };
      const config = {
        method: "post",
        url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/Login-ChangeEmail`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          // alert("Email changed to" + email)
          alert(response.data);
          console.log(response.data);
          window.location.reload();
          setLoading2(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const resendWelcomeEmail = async () => {
      setLoading3(true);

      const data = {
        uid: member.uid,
        email: member.email,
        access: member.access,
      };
      const config = {
        method: "post",
        url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/Login-ResendWelcomeEmail`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          alert("Email Sent");
          setLoading3(false);
        })
        .catch(function (error) {
          console.log(error);
          alert("Error In Resending Invite, try again later and notify IT");
        });
    };

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>
              <b>{member.firstName + " " + member.lastName}</b>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <MemberProfile />
          {loading2 ? (
            <>
              <Loader />
              <br></br>
            </>
          ) : null}

          {/* <div className="ion-padding dashboard-photo-container">
            <img className="dashboard-photo" src={member.photoURL} alt="" />
          </div>
          <h3>
            <b>{member.groupName}</b>
          </h3>

          <p>
            <RenderCompliance />
          </p>
          <p>{member.email}</p>
          <p>{member.phone}</p>

          <p>
            {member.gender === "male" ? "Male" : null}
            {member.gender === "female" ? "Female" : null}
            {" • "} {member.weight} Lbs {" • "}
            {member.heightFt}' {member.heightIn}"{" • "} DOB: {member.dob}
            <br></br>
            Coach: {coach["firstName"] + " " + coach["lastName"]} (
            {coach["email"]})<br></br>
          </p>
          <br></br> */}
          {/* <div>
            {member.active ? (
              <IonLabel color="success">Active</IonLabel>
            ) : (
              <IonLabel color="danger">Inactive</IonLabel>
            )}
            <br></br>
          </div> */}
          {/* <IonButton routerLink={"/page/chat/" + member.cometChatUid}>
            Open Chat
          </IonButton> */}
          <br></br>
          {/* <IonButton
            onClick={() =>
              presentAlert({
                header: "Change member email",
                inputs: [
                  {
                    name: "email",
                    placeholder: "joe@mail.com",
                    type: "text",
                  },
                ],
                buttons: [
                  {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                    handler: () => {},
                  },
                  {
                    text: "Ok",
                    handler: (alertData) => {
                      //takes the data
                      updateMemberEmail(alertData.email);
                    },
                  },
                ],
              })
            }
          >
            Change Email {loading2 ? <IonSpinner></IonSpinner> : null}
          </IonButton> */}

          {/* <br></br>
          {!member.tempPasswordChanged ? (
            <IonButton
              onClick={() =>
                presentAlert({
                  header: "Resend Welcome Email",
                  buttons: [
                    {
                      text: "Cancel",
                      role: "cancel",
                      cssClass: "secondary",
                      handler: () => {},
                    },
                    {
                      text: "Send",
                      handler: () => {
                        //takes the data
                        resendWelcomeEmail();
                      },
                    },
                  ],
                })
              }
            >
              Resend Welcome Email {loading3 ? <IonSpinner></IonSpinner> : null}
            </IonButton>
          ) : null} */}

          <div className="center">{/* <RenderComplianceCard /> */}</div>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Journal</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <>
                <IonToolbar className="dateToolbar">
                  <IonButtons slot="start">
                    <IonIcon
                      size="large"
                      icon={chevronBackSharp}
                      onClick={() => setDate(decrementDate(date))}
                    />
                  </IonButtons>
                  <IonButtons slot="end">
                    <IonIcon
                      size="large"
                      icon={chevronForwardSharp}
                      onClick={() => setDate(incrementDate(date))}
                    />
                  </IonButtons>
                  <ShowCurrentDate />
                </IonToolbar>
              </>
              {showDataPicker ? (
                <>
                  <IonDatetime
                    showDefaultButtons={true}
                    presentation="date"
                    value={date}
                    doneText="All set"
                    cancelText="Never mind"
                    onIonChange={(e: any) => {
                      setDate(e.detail.value);
                      setShowDataPicker(false);
                    }}
                    onIonCancel={(e: any) => setShowDataPicker(false)}
                  ></IonDatetime>
                </>
              ) : null}

              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader
                        className="meal journal-head"
                        id="open-log-meal"
                      >
                        <IonRow>
                          <IonCol size="8">
                            <IonCardTitle className="journal-head">
                              Log Meal
                            </IonCardTitle>
                          </IonCol>
                          <IonCol size="4">
                            <IonIcon className="icon_plus"></IonIcon>
                          </IonCol>
                        </IonRow>
                      </IonCardHeader>

                      <IonCardContent>
                        <IonList>
                          {mealLogs.map((log, index) => {
                            return (
                              <IonItem key={index} className="ionLogItem">
                                {log.mealPhoto === "no-photo" ? (
                                  <img
                                    className="mealLogCover"
                                    src="https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/no_photo.png?alt=media&token=908c8412-8af8-469d-ae98-591408c933d3"
                                    alt="meal"
                                  />
                                ) : (
                                  <img
                                    className="mealLogCover"
                                    src={log.mealPhoto}
                                    alt="meal"
                                  />
                                )}
                                • {log.mealName} <br />
                                {log.mealCalories == 0 ? null : (
                                  <>
                                    • {log.mealCalories} cals
                                    <br />
                                  </>
                                )}
                                {log.mealType == "" ? null : (
                                  <>
                                    • {log.mealType}
                                    <br />
                                  </>
                                )}
                                <IonIcon
                                  onClick={() => deleteMeal(log.id)}
                                  slot="end"
                                  icon={trashOutline}
                                ></IonIcon>
                              </IonItem>
                            );
                          })}
                        </IonList>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader
                        className="activity journal-head"
                        id="open-log-exercise"
                      >
                        <IonRow>
                          <IonCol size="8">
                            <IonCardTitle className="journal-head">
                              Log Activity
                            </IonCardTitle>
                          </IonCol>
                          <IonCol size="4">
                            <IonIcon className="icon_plus"></IonIcon>
                          </IonCol>
                        </IonRow>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonList>
                          {exerciseLogs.map((log, index) => {
                            return (
                              <IonItem key={index} className="ionLogItem">
                                {log.type} <br /> • Intensity {log.intensity}{" "}
                                <br /> • {log.duration} minutes <br />{" "}
                                {log.comment}
                                <IonIcon
                                  onClick={() => deleteExercise(log.id)}
                                  slot="end"
                                  icon={trashOutline}
                                ></IonIcon>
                              </IonItem>
                            );
                          })}
                        </IonList>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader
                        className="mood journal-head"
                        id="open-log-mood"
                      >
                        <IonRow>
                          <IonCol size="8">
                            <IonCardTitle className="journal-head">
                              Log Mood
                            </IonCardTitle>
                          </IonCol>
                          <IonCol size="4">
                            <IonIcon className="icon_plus"></IonIcon>
                          </IonCol>
                        </IonRow>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonList>
                          {moodLogs.map((log, index) => {
                            return (
                              <IonItem key={index} className="ionLogItem">
                                <IonGrid>
                                  <IonRow>
                                    <IonCol size="4">
                                      <RenderMoodIcon mood={log.mood} />
                                    </IonCol>
                                    <IonCol>{log.comment}</IonCol>
                                  </IonRow>
                                </IonGrid>

                                <IonIcon
                                  onClick={() => deleteMood(log.id)}
                                  slot="end"
                                  icon={trashOutline}
                                ></IonIcon>
                              </IonItem>
                            );
                          })}
                        </IonList>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader
                        className="glucose journal-head"
                        id="open-log-metrics"
                      >
                        <IonRow>
                          <IonCol size="8">
                            <IonCardTitle className="journal-head">
                              Log Glucose
                            </IonCardTitle>
                          </IonCol>
                          <IonCol size="4">
                            <IonIcon className="icon_plus"></IonIcon>
                          </IonCol>
                        </IonRow>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonList>
                          {glucoseLogs.map((log, index) => {
                            return (
                              <IonItem key={log.id} className="ionLogItem">
                                {log.when}: {log.glucoseLevel}
                                <IonIcon
                                  onClick={() => deleteMetrics(log.id)}
                                  slot="end"
                                  icon={trashOutline}
                                ></IonIcon>
                              </IonItem>
                            );
                          })}
                        </IonList>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader
                        className="metrics journal-head"
                        id="open-log-metrics"
                      >
                        <IonRow>
                          <IonCol size="8">
                            <IonCardTitle className="journal-head">
                              Log Metrics
                            </IonCardTitle>
                          </IonCol>
                          <IonCol size="4">
                            <IonIcon className="icon_plus"></IonIcon>
                          </IonCol>
                        </IonRow>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonList>
                          {metricsLogs.map((log, index) => {
                            return (
                              <IonItem key={log.id} className="ionLogItem">
                                <RenderMetric metric={log} />
                                <IonIcon
                                  onClick={() => deleteMetrics(log.id)}
                                  slot="end"
                                  icon={trashOutline}
                                ></IonIcon>
                              </IonItem>
                            );
                          })}
                        </IonList>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          <IonGrid>
            <IonRow>
              <IonCol>
                <RenderCoverageCard />
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Diagnostic Scans</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList lines="none">
                      {loading ? (
                        <Loader />
                      ) : (
                        scans.map((scan, index) => renderScanItem(scan, index))
                      )}
                    </IonList>
                  </IonCardContent>
                </IonCard>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Sessions</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <RenderSessions />
                  </IonCardContent>
                </IonCard>
                <GoalsCard uid={member.uid} email={member.email} />
                <CoachNotes
                  uid={member.uid}
                  email={member.email}
                  name={member.firstName + " " + member.lastName}
                  coachUid={user.uid}
                  coachEmail={user.email}
                  coachName={profile.firstName + " " + profile.lastName}
                />
                {/* {member.hasTelemedicine &&
                member.walmartHealthId &&
                member.externalID ? (
                  <WalmartHealthCard
                    uid={uid}
                    walmartHealthId={member.walmartHealthId}
                    externalID={member.externalID}
                  />
                ) : <>
                    {member.hasTelemedicine &&
                      (!member.walmartHealthId || !member.externalID)}
                  </> ? (
                  <>Seems like externalID or walmartHealthId are missing...</>
                ) : (
                  <>no tele</>
                )} */}

                {member.hasTelemedicine ? (
                  <WalmartHealthCard memberData={member} />
                ) : null}
              </IonCol>

              <IonCol>
                <MemberHRA member={member} />
                <SmsChat
                  userNumber={member.phone}
                  smsNotificationsOn={member.smsNotificationsOn}
                />
                <MemberHRA member={member} />
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonModal isOpen={viewComplianceHistory}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Compliance History</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setViewComplianceHistory(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <RenderComplianceHistory tracking={member["complianceHistory"]} />
            </IonContent>
          </IonModal>
          {member.active ? <TerminateMemberButton /> : null}
        </IonContent>
      </>
    );
  }

  React.useEffect(() => {
    setUid(window.location.pathname.split("/")[2]);
    fetchMember();
  }, []);

  return (
    <IonPage> {member ? <MemberFound /> : <p>member not found</p>}</IonPage>
  );
};

export default MemberDash;
