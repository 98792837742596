import {
  IonCard,
  IonCardHeader,
  IonToolbar,
  IonCardTitle,
  IonModal,
  IonButton,
  IonCardContent,
  IonInput,
  IonItem,
  IonLabel,
  IonAlert,
} from "@ionic/react";
import axios from "axios";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../../lib/context";

import "./WalmartHealthCard.css";

//This page was written by Aaron Gingrich on 1/18/23
export default function WalmartHealthCard({ memberData }) {
  const [member, setMember] = React.useState(memberData);
  const [missingData, setMissingData] = React.useState(false);
  const { user, profile } = useContext(UserContext);
  const [policies, setPolicies] = React.useState([]);

  const checkForMissingData = () => {
    if (!member.walmartHealthId || !member.externalID) {
      setMissingData(true);
    } else if (member.walmartHealthId === "" || member.externalID === "") {
      setMissingData(true);
    } else if (member.walmartHealthId === " " || member.externalID === " ") {
      setMissingData(true);
    } else if (
      member.walmartHealthId === undefined ||
      member.externalID === undefined
    ) {
      setMissingData(true);
    } else if (member.walmartHealthId === null || member.externalID === null) {
      setMissingData(true);
    } else {
      setMissingData(false);
    }
  };

  useEffect(() => {
    checkForMissingData();
    getPolicies();
  }, []);

  const updateDataSubmited = async (e) => {
    e.preventDefault();
    console.log("updateDataSubmited", member);
    const db = getFirestore();
    const ref = doc(db, `users/${member.uid}`);
    await updateDoc(ref, {
      externalID: member.externalID,
      walmartHealthId: member.walmartHealthId,
    }).then(() => {
      //reload page
      window.location.reload();
    });

    //getPolicies();
  };

  function getPolicies() {
    console.log("Get Policies");

    const data = {
      uid: member.uid,
      walmartHealthId: member.walmartHealthId,
      externalID: member.externalID,
    };

    const config = {
      method: "post",
      url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/WalmartHealth-GetAllPolicies`,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("Success fully got data");
        console.log(response);
        if (response.data.policies) {
          setPolicies(response.data.policies);
        }

        if (response.data === "Unable to get polices with given external ID") {
          setMissingData(true);
        }
      })
      .catch(function (error) {
        console.log("Did not get data, inside catch");
        if (
          error.response.data === "Unable to get polices with given external ID"
        ) {
          setMissingData(true);
        }
        console.log(error);
      });
  }

  const termPolicy = (externalID, plancode) => {
    console.log("Term Plan");

    const data = { externalID: externalID, plancode: plancode };

    const config = {
      method: "post",
      url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/WalmartHealth-TermPolicy`,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        getPolicies();
        // window.open(response.data.url, "_blank");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const RenderPolicies = () => {
    const [termAlert, setTermAlert] = React.useState(false);
    const [addPolicy, setAddPolicy] = React.useState(false);
    const [startDate, setStartDate] = React.useState("");

    var hasOneActivePolicy = false;

    // see if there are any active policies
    policies.map((policy) => {
      if (policy.benefitend === null) {
        hasOneActivePolicy = true;
      }
    });

    const addPolicyToMember = (e) => {
      e.preventDefault();
      console.log("addPolicyToMember", member);
      console.log("startDate", startDate);
      const dateArray = startDate.split("-");

      //need to formate date to WH form
      const benefitstart = `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}T00:00:00`;

      const data = {
        uid: member.uid,
        externalID: member.externalID,
        plancode: member.walmartHealthPlanCode,
        benefitstart: benefitstart,
      };

      const config = {
        method: "post",
        url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/WalmartHealth-AddPolicy`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          getPolicies();
          console.log("successfully added policy");
          // window.open(response.data.url, "_blank");
        })
        .catch(function (error) {
          console.log(error);
          getPolicies();

          console.log("did not add policy");
        });

      setAddPolicy(false);
    };

    return (
      <>
        <IonButton
          disabled={hasOneActivePolicy}
          onClick={() => setAddPolicy(true)}
        >
          Add Policy
        </IonButton>
        {policies.map((policy) => {
          return (
            <IonCard key={policy.policyId}>
              <IonCardHeader>
                <IonToolbar className="remove-bg">
                  <IonCardTitle className="ion-text-center">
                    {policy.plancode}
                  </IonCardTitle>
                </IonToolbar>
              </IonCardHeader>
              <IonCardContent>
                <IonItem>
                  <IonLabel>Policy ID</IonLabel>
                  <IonLabel>{policy.policyId}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Benefit Start</IonLabel>
                  <IonLabel>{policy.benefitstart}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Benefit End</IonLabel>
                  <IonLabel>
                    {policy.benefitend ? policy.benefitend : "N/A"}
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Co Pay</IonLabel>
                  <IonLabel>{policy.copay}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Include Dependents</IonLabel>
                  <IonLabel>
                    {policy.includeDependents ? "True" : "False"}
                  </IonLabel>
                </IonItem>
                {policy.isactive ? (
                  <IonItem color="success">
                    <IonLabel>Is Active</IonLabel>
                    <IonLabel>{policy.isactive ? "True" : "False"}</IonLabel>
                  </IonItem>
                ) : (
                  <IonItem color="danger">
                    <IonLabel>Is Active</IonLabel>
                    <IonLabel>{policy.isactive ? "True" : "False"}</IonLabel>
                  </IonItem>
                )}
                <IonItem>
                  <IonLabel>Terminate Policies</IonLabel>
                  <IonLabel>
                    {policy.terminatePolicies ? "True" : "False"}
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Policy Number</IonLabel>
                  <IonLabel>{policy.policyNumber}</IonLabel>
                </IonItem>
                <IonButton
                  className="ion-margin-top"
                  onClick={() => setTermAlert(true)}
                >
                  Term Plan
                </IonButton>

                <IonAlert
                  isOpen={termAlert}
                  onDidDismiss={() => setTermAlert(false)}
                  header="Warning"
                  message="Are you sure you want to term this plan?"
                  buttons={[
                    {
                      text: "Cancel",
                      role: "cancel",
                      handler: () => {},
                    },
                    {
                      text: "OK",
                      role: "confirm",
                      handler: () => {
                        termPolicy(member.externalID, policy.plancode);
                      },
                    },
                  ]}
                />
              </IonCardContent>
            </IonCard>
          );
        })}
        <IonModal isOpen={addPolicy} backdropDismiss={false}>
          <IonCard>
            <IonCardHeader>
              <IonToolbar className="remove-bg">
                <IonCardTitle className="ion-text-center">
                  ADd Policy To Member
                </IonCardTitle>
              </IonToolbar>
            </IonCardHeader>
            <IonCardContent>
              <form onSubmit={(e) => addPolicyToMember(e)}>
                <IonItem>
                  <IonLabel>Start Date</IonLabel>
                  <IonInput
                    required
                    type="date"
                    onIonChange={(e) => setStartDate(e.detail.value)}
                  ></IonInput>
                </IonItem>
                <IonButton type="submit">Add</IonButton>
              </form>
            </IonCardContent>
          </IonCard>
        </IonModal>
      </>
    );
  };

  if (!member) {
    return <></>;
  }

  if (!member.hasTelemedicine) {
    return <></>;
  }

  return (
    <>
      <IonCard className="ion-padding">
        <IonCardHeader>
          <IonToolbar className="remove-bg">
            <IonCardTitle className="ion-text-center">
              Walmart Health ID - {member.walmartHealthId}
              <br></br>
              External ID - {member.externalID}
              <br></br>
              <a
                className="editWalmartHealthNumbers"
                onClick={() => setMissingData(true)}
              >
                EDIT
              </a>
            </IonCardTitle>
          </IonToolbar>
        </IonCardHeader>
        <IonCardContent>
          <RenderPolicies />
        </IonCardContent>
      </IonCard>
      <IonModal isOpen={missingData} backdropDismiss={false}>
        <IonCard>
          <IonCardHeader>
            <IonToolbar className="remove-bg">
              <IonCardTitle className="ion-text-center">
                Error with Walmart Health - missing externalID or
                walmartHealthId
              </IonCardTitle>
            </IonToolbar>
          </IonCardHeader>
          <IonCardContent>
            <form onSubmit={(e) => updateDataSubmited(e)}>
              <IonItem>
                <IonLabel position="floating">External ID</IonLabel>
                <IonInput
                  required
                  type="text"
                  value={member.externalID}
                  onIonChange={(e) =>
                    setMember({ ...member, externalID: e.detail.value })
                  }
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Walmart Health ID</IonLabel>
                <IonInput
                  required
                  type="text"
                  value={member.walmartHealthId}
                  onIonChange={(e) =>
                    setMember({ ...member, walmartHealthId: e.detail.value })
                  }
                ></IonInput>
              </IonItem>
              <IonButton type="submit">Update</IonButton>
            </form>
          </IonCardContent>
        </IonCard>
      </IonModal>
    </>
  );
}
