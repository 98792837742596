import {
  query,
  collection,
  getFirestore,
  where,
  onSnapshot,
} from "@firebase/firestore";
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../lib/context";
import "./UpcomingSessions.css";
import { UpcomingSessionsTypes } from "../../Types/upcommingsessions";
import { IonIcon } from "@ionic/react";
import {
  callOutline,
  mailOpenOutline,
  personOutline,
  timeOutline,
  peopleOutline,
} from "ionicons/icons";
import { useHistory } from "react-router";

function UpcomingSessions() {
  const { user } = useContext(UserContext);
  const [sessions, setSessions] = useState<UpcomingSessionsTypes[]>([]);
  const history = useHistory();

  const handleMemberClick = (session: UpcomingSessionsTypes) => {
    const route = "/member-dash/" + session.memberUid;
    window.location.href = route;
    history.push(route);
  };
  const handleSessionClick = (session: UpcomingSessionsTypes) => {
    const route = "/session/" + session.id;
    window.location.href = route;
    history.push("/session/" + session.id);
  };

  useEffect(() => {
    if (user) {
      const q = query(
        collection(getFirestore(), "sessions"),
        where("coachUid", "==", user.uid)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setSessions(
          snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          })
        );
      });

      return unsubscribe;
    }
  }, [user]);

  // Create an array of unique formatStartTime values
  const uniqueDates = [];
  const today = new Date(); // get the current date

  console.log("today", today);

  for (const item of sessions) {
    const startTime = item.startTimeTimestamp.toDate();
    const formatStartTime = startTime.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      weekday: "long",
    });
    const date = new Date(formatStartTime);
    console.log(date);

    // extract the month and date components from the date and today variables
    const dateMonth = date.getMonth();
    const dateDate = date.getDate();
    const todayMonth = today.getMonth();
    const todayDate = today.getDate();

    // only keep dates that are equal to or greater than the current date
    if (
      (dateMonth > todayMonth ||
        (dateMonth === todayMonth && dateDate >= todayDate)) && // compare month and date components only
      !uniqueDates.includes(formatStartTime)
    ) {
      uniqueDates.push(formatStartTime);
    }
  }

  uniqueDates.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA.getTime() - dateB.getTime();
  });

  // Get the current date
  // const today = new Date().toLocaleDateString("en-US", {
  //   month: "long",
  //   day: "numeric",
  //   weekday: "long",
  // });

  const sortedSessions = sessions.sort((a, b) => {
    return (
      a.startTimeTimestamp.toDate().getTime() -
      b.startTimeTimestamp.toDate().getTime()
    );
  });

  return (
    <div>
      {/* Map over the unique dates array */}
      {[...uniqueDates].map((date) => {
        // Get an array of sessions that match the current date
        const matchingSessions = sortedSessions.filter((session) => {
          const startTime = session.startTimeTimestamp.toDate();
          const sessionDate = startTime.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            weekday: "long",
          });
          return sessionDate === date;
        });

        // Render the sessions for the current date
        return (
          <div key={date}>
            <div className="session-date">
              {date ===
              today.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                weekday: "long",
              }) ? (
                <div className="date-today">Today</div>
              ) : date ===
                new Date(Date.now() + 86400000).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  weekday: "long",
                }) ? (
                <div className="date-tomorrow">Tomorrow</div>
              ) : null}
              {date}
            </div>

            {matchingSessions.map((session) => (
              <div key={session.id}>
                <div className="session">
                  <div
                    className="session-detail"
                    onClick={() => handleSessionClick(session)}
                  >
                    <div className="detail-icon">
                      {session.location.includes("calendly") && (
                        <IonIcon icon={peopleOutline} size="large" />
                      )}
                      {session.location.includes("Allied Wellness App") && (
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/AlliedWellnessLogo.png?alt=media&token=cf8e07cb-6b39-48f0-a86d-104eb0e78bc0"
                          alt="allied wellness logo"
                        />
                      )}
                      {!session.location.includes("calendly") &&
                        !session.location.includes("Allied Wellness App") && (
                          <IonIcon icon={callOutline} size="large" />
                        )}
                    </div>

                    <div className="details">
                      <div className="details-location">
                        {session.location.includes("calendly")
                          ? "Microsoft Teams"
                          : session.location}
                      </div>
                      <div className="details-time">
                        <IonIcon icon={timeOutline} />
                        {session.startTimeTimestamp
                          .toDate()
                          .toLocaleTimeString([], {
                            hour: "numeric",
                            minute: "2-digit",
                          })}
                        {/* {" "}
                        -{" "}
                        {session.endTimeTimestamp
                          .toDate()
                          .toLocaleTimeString([], {
                            hour: "numeric",
                            minute: "2-digit",
                          })} */}
                      </div>
                    </div>
                  </div>

                  <div className="session-member">
                    <div className="info">
                      <div
                        className="info-name"
                        onClick={() => handleMemberClick(session)}
                      >
                        <IonIcon icon={personOutline} />
                        {session.memberName}
                      </div>

                      <div className="info-email">
                        <IonIcon icon={mailOpenOutline} />
                        {session.memberEmail}
                      </div>
                      {session.status && (
                        <div className="session-status">
                          {session.status.toUpperCase()}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}

export default UpcomingSessions;
