import { doc, getFirestore, getDoc } from "firebase/firestore";
import { KoroPublicAvatar } from "../Interfaces/KoroPublicAvatar";
import { getAuth, signOut } from "firebase/auth";

//validate email
export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

//validate first name
export function validateFirstName(firstName) {
    var re = /^[a-z ,.'-]+$/i;
    return re.test(String(firstName).toLowerCase());
}

//validate last name
export function validateLastName(lastName) {
    var re = /^[a-z ,.'-]+$/i;
    return re.test(String(lastName).toLowerCase());
}

//validate phone
export function validatePhone(phone) {
    var re = /^\d{3}-\d{3}-\d{4}$/;
    return re.test(String(phone).toLowerCase());
}

export function reformatDOB(dob) {
    const dobArray = dob.split("-");
    const dobYear = dobArray[0];
    const dobMonth = dobArray[1];
    const dobDay = dobArray[2];
    const formatedDob = dobMonth + "/" + dobDay + "/" + dobYear;
    return formatedDob;
}

export function formatToIonicDate(date) {
    var dateArray = date.split("/");
    var year = dateArray[2];
    var month = dateArray[0];
    var day = dateArray[1];
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
};


export async function getUserAvatar(publicAvatarUid: string) {
  // console.log("GETTING AVATAR", publicAvatarUid)
  if (publicAvatarUid === undefined) {
    return;
  }
    const docRef = doc(getFirestore(), "koroPublicAvatars", publicAvatarUid);

    const querySnapshot = await getDoc(docRef);
    const data = querySnapshot.data() as KoroPublicAvatar;
    

    return data;
  }

  export const getAge = (dob) => {
    // get age from string xx/xx/xxxx
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    // if month is less than 0, then birthday has not passed yet
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

export function signUserOut() {
    const auth = getAuth();
    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });
}

export const validatePassword = (pass) => {
    //pasword regex
    var regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (pass === undefined || pass === "") {
      return("Password is required");
    } else if (pass.length < 8) {
      return("Password must be at least 8 characters");
    } else if (!regex.test(pass)) {
      return(
        "Password must contain a number and a special character"
      );
    } else {
      return("ok");
    }
  };
