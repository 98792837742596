/* eslint-disable jsx-a11y/anchor-is-valid */
//tempalte for component in react
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import {
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
  writeBatch,
  collection,
  getDoc,
  addDoc,
  limit,
} from "firebase/firestore";
import { closeCircleOutline } from "ionicons/icons";
import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { UserContext } from "../../lib/context";
import {
  validateEmail,
  validateFirstName,
  validatePhone,
  validateLastName,
} from "../../lib/helpers";
import axios from "axios";
import RenderEligibilityFiles from "./RenderEligibilityFiles";
import GroupExport from "./GroupExport";

export default function AddGroupCSV() {
  const [groupCSV, setGroupCSV] = React.useState<string>();
  const [file, setFile] = React.useState<File>();
  const [groups, setGroups] = React.useState([]);
  const [coaches, setCoaches] = React.useState([]);
  const [coach, setCoach] = React.useState<string>();
  const { user, profile } = useContext(UserContext);
  const [groupId, setGroupId] = React.useState<string>();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [showTemplate, setShowTemplate] = useState(false);
  const [validGroup, setValidGroup] = useState(true);
  const [present] = useIonToast();

  const validateRowFormat = (line) => {
    //console.log("Length", line.length);
    if (line.length === 17) {
      return true;
    }
    alert("Invalid CSV format for row: " + line.toString());
    return false;
  };

  const validateId = (id) => {
    //make sure id does not have any spaces, tabs, or new lines
    if (id.includes(" ") || id.includes("\t") || id.includes("\n")) {
      alert("Invalid ID: " + id);
      return false;
    }
    return true;
  };

  const validateEmailFormat = (email: string) => {
    if (validateEmail(email)) {
      return true;
    }
    alert("Invalid email format for row: " + email);
    return false;
  };

  const validatePhoneFormat = (phone: string) => {
    if (validatePhone(phone) || phone === "") {
      return true;
    }
    alert("Invalid phone format for row: " + phone);
    return false;
  };

  const validateFirstNameFormat = (firstName: string) => {
    if (validateFirstName(firstName) || firstName === "") {
      return true;
    }
    alert("Invalid first name format for row: " + firstName);
    return false;
  };

  const validateLastNameFormat = (lastName: string) => {
    if (validateLastName(lastName) || lastName === "") {
      return true;
    }
    alert("Invalid last name format for row: " + lastName);
    return false;
  };

  const validateDateOfBirthFormat = (dateOfBirth: string) => {
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    //console.log("Date of birth", dateOfBirth);

    if (dateOfBirth === "") {
      alert("DOB is required " + dateOfBirth);
      return false;
    } else if (!dateOfBirth.match(date_regex)) {
      alert(
        "Invalid date of birth format for row: " + dateOfBirth + " (MM/DD/YYYY)"
      );
      return false;
    }
    return true;
  };

  const validateAccess = (access: string) => {
    if (access === "" || access === undefined) {
      alert("Access is required");
      return false;
    } else if (
      access === "fullAccess" ||
      access === "facescanOnly" ||
      access === "telemedicineOnly"
    ) {
      return true;
    }
    alert("Invalid access format for row: " + access);
    return false;
  };

  const validateHasProduct = (hasProduct: string) => {
    if (hasProduct === "" || hasProduct === undefined) {
      return true;
    } else if (hasProduct === "Yes" || hasProduct === "No") {
      return true;
    }
    alert("Invalid hasProduct format for row: " + hasProduct);
    return false;
  };

  const validateStatus = (status: string) => {
    if (status === "" || status === undefined) {
      alert("Status is required");
      return false;
    } else if (status === "d" || status === "a") {
      return true;
    }
    alert("Invalid status format for row: " + status);
    return false;
  };

  const validateExternalID = (status: string) => {
    if (status === "" || status === undefined) {
      alert("External ID is required");
      return false;
    } else {
      return true;
    }
  };

  const addLeadingZerosToDate = (date: string) => {
    var date_array = date.split("/");
    var month = date_array[0];
    var day = date_array[1];
    var year = date_array[2];

    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }

    return month + "/" + day + "/" + year;
  };

  const addMembersToDB = (csvFile) => {
    var valid = true;
    console.log("top");
    const groupIDandName = groupId.split("%");
    let reader = new FileReader();

    var members = [];

    reader.readAsText(file);

    reader.onload = function () {
      const csv = reader.result;
      const fileContent = csv.toString();
      const fileRows = fileContent.split("\n");
      var lineno = 0;
      const db = getFirestore();
      const batch = writeBatch(db);

      fileRows.forEach((row) => {
        if (row !== "" && lineno !== 0) {
          const rowArray = row.split(",");
          const externalID = rowArray[0];
          const walmartHealthId = rowArray[1];
          const email = rowArray[2];
          const phone = rowArray[3];
          const firstName = rowArray[4];
          const lastName = rowArray[5];
          const dob = addLeadingZerosToDate(rowArray[6]);
          const addr1 = rowArray[7];
          const addr2 = rowArray[8];
          const city = rowArray[9];
          const state = rowArray[10];
          const zip = rowArray[11];
          const hasCoaching = rowArray[12] === "Yes" ? true : false;
          const hasFacescan = rowArray[13] === "Yes" ? true : false;
          const hasTelemedicine =
            rowArray[14].replace(/(\r \n|\n|\r)/gm, "") === "Yes"
              ? true
              : false;

          const hasQuestis =
            rowArray[15].replace(/(\r \n|\n|\r)/gm, "") === "Yes"
              ? true
              : false;

          const status = rowArray[16].replace(/(\r \n|\n|\r)/gm, "");

          var access;

          if (!hasFacescan && !hasTelemedicine && !hasCoaching) {
            access = "fullAccess";
          } else if (hasFacescan && !hasTelemedicine && !hasCoaching) {
            access = "facescanOnly";
          } else if (!hasFacescan && hasTelemedicine && !hasCoaching) {
            access = "telemedicineOnly";
          } else {
            access = "fullAccess";
          }

          if (
            validateRowFormat(rowArray) &&
            validateEmailFormat(email) &&
            validatePhoneFormat(phone) &&
            validateFirstNameFormat(firstName) &&
            validateLastNameFormat(lastName) &&
            validateDateOfBirthFormat(dob) &&
            validateHasProduct(rowArray[12]) &&
            validateHasProduct(rowArray[13]) &&
            validateHasProduct(rowArray[14].replace(/(\r \n|\n|\r)/gm, "")) &&
            validateHasProduct(rowArray[15].replace(/(\r \n|\n|\r)/gm, "")) &&
            validateId(externalID) &&
            validateId(walmartHealthId) &&
            validateStatus(status) &&
            validateExternalID(externalID)
          ) {
            var dobTimeStamp = new Date(dob);

            const member = {
              firstName: firstName || "",
              lastName: lastName || "",
              dob: dob || "",
              dobTimeStamp: dobTimeStamp || "",
              externalID: externalID || "",
              addr1: addr1 || "",
              addr2: addr2 || "",
              city: city || "",
              state: state || "",
              zip: zip || "",
              email: email.toLocaleLowerCase(),
              phone: phone || "",
              groupID: groupIDandName[0],
              groupName: groupIDandName[1],
              coach: coach,
              hasCoaching: hasCoaching,
              hasFacescan: hasFacescan,
              hasTelemedicine: hasTelemedicine,
              hasQuestis: hasQuestis,
              access: access || "",
              walmartHealthPlanCode: groupIDandName[2],
              status: status || "",
            };

            if (walmartHealthId !== "") {
              member["walmartHealthId"] = walmartHealthId;
            }

            // console.log("memberrrr", member);
            // batch.set(userRef, member);
            members.push(member);
          } else {
            presentToast(
              "Invalid row: " + rowArray.toString(),
              "danger",
              "bottom"
            );
            valid = false;
          }
        }
        lineno++;
      });

      if (valid) {
        console.log("Add group to DB");
        console.log(members);

        const data = {
          // members: members,
          groupId: groupIDandName[0],
          groupName: groupIDandName[1],
          status: "pending",
          requester: profile.firstName + " " + profile.lastName,
          requesterEmail: user.email,
          requesterUid: user.uid,
          requestedDate: new Date(),
          totalMembers: members.length,
          existingMembers: 0,
        };

        // console.log(data);
        // add to collected eligibility files
        addDoc(collection(db, "eligibility_files"), data)
          .then((docRef) => {
            fetchEligibilityFiles();
            console.log("Document written with ID: ", docRef.id);
            // create batch
            const batch = writeBatch(db);
            console.log("Batch created");

            // add members to batch
            members.forEach((member) => {
              const userRef = doc(db, "eligibility_file_members", uuidv4());
              batch.set(userRef, { ...member, batchId: docRef.id });
            });

            // commit batch
            batch
              .commit()
              .then(() => {
                console.log("Batch successfully committed!");
                presentToast(
                  "Successfully added group to system",
                  "success",
                  "top"
                );
              })
              .catch((error) => {
                console.log("Error committing batch: ", error);
                presentToast(
                  "Unable to add group to system. Please check file and try again",
                  "danger",
                  "top"
                );
              });
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      } else {
        presentToast(
          "Unable to add group to system. Please check file and try again",
          "danger",
          "top"
        );
      }
    };
  };

  const presentToast = (message, color, position) => {
    present({
      message: message,
      color: color,
      duration: 0,
      position: position,
      buttons: [
        {
          icon: closeCircleOutline,
          role: "cancel",
        },
      ],
    });
  };

  const checkIfCSVFile = (csvFile) => {
    if (csvFile.type === "text/csv") {
      return true;
    }
    return false;
  };

  const addGroupCSV = async (e: { preventDefault: () => void; target }) => {
    e.preventDefault();

    if (groupId === undefined) {
      presentToast("Please select a group", "danger", "top");
      return;
    } else if (coach === undefined) {
      presentToast("Please select a coach", "danger", "top");
      return;
    } else if (!checkIfCSVFile(file)) {
      presentToast("Please select a CSV file", "danger", "top");
      return;
    } else {
      addMembersToDB(file);
    }

    e.target.reset();
  };

  const fetchGroups = async () => {
    const q = query(
      collection(getFirestore(), "groups"),
      where("active", "==", true),
      orderBy("group", "asc")
    );

    var groupsData = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      groupsData.push(doc.data());
    });

    // sort groups by group name alphabetically
    groupsData.sort((a, b) => {
      if (a.group < b.group) {
        return -1;
      }
      if (a.group > b.group) {
        return 1;
      }
      return 0;
    });

    setGroups(groupsData);
  };

  const fetchCoaches = async () => {
    const q = query(
      collection(getFirestore(), "users"),
      where("role", "==", "coach")
    );

    var coachesData = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      coachesData.push(doc.data());
    });

    // sort groups by group name alphabetically
    coachesData.sort((a, b) => {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    });

    setCoaches(coachesData);
  };

  const listCoaches = coaches.map((coach) => (
    <IonSelectOption key={coach.uid} value={coach.uid}>
      {coach.firstName} {coach.lastName}
    </IonSelectOption>
  ));

  const listGroups = groups.map((group) => (
    <IonSelectOption
      key={group.groupID}
      value={
        group.groupID + "%" + group.group + "%" + group.walmartHealthPlanCode
      }
    >
      {group.group}
    </IonSelectOption>
  ));

  const [eligibilityFiles, setEligibilityFiles] = useState([]);

  const fetchEligibilityFiles = async () => {
    const q = query(collection(getFirestore(), "eligibility_files"), orderBy("requestedDate", "desc"), limit(5));

    var eligibilityFilesData = [];

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      eligibilityFilesData.push({ id: doc.id, ...doc.data() });
    });

    // sort groups by group name alphabetically
    // sort by requested date
    eligibilityFilesData.sort((a, b) => {
      if (a.requestedDate < b.requestedDate) {
        return 1;
      }
      if (a.requestedDate > b.requestedDate) {
        return -1;
      }
      return 0;
    });

    setEligibilityFiles(eligibilityFilesData);

    console.log(eligibilityFilesData);
  };

  React.useEffect(() => {
    fetchEligibilityFiles();
  }, []);

  const GetExistingMembers = ({ id }) => {
    const [existingMembersList, setExistingMembersList] = useState([]);

    const fetchExistingMembers = async () => {
      const q = query(
        collection(getFirestore(), "eligibility_file_members"),
        where("batchId", "==", id),
        where("completed", "==", true),
        where("eligibilityStatus", "==", "Existing")
      );

      var existingMembers = [];

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        existingMembers.push({ id: doc.id, ...doc.data() });
      });

      console.log("Existing members", existingMembers);

      setExistingMembersList(existingMembers);
    };

    React.useEffect(() => {
      fetchExistingMembers();
    }, []);

    return <>{existingMembersList.length}</>;

    // sort groups by group name alphabetically
  };

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Eligibility File Upload</IonCardTitle>
          <a className="link" onClick={() => setShowTemplate(true)}>
            Template File
          </a>
        </IonCardHeader>
        <IonCardContent>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={3000}
            position="top"
            color={toastColor}
          />
          <form onSubmit={addGroupCSV}>
            <IonItem lines="none">
              <IonLabel position="stacked">Group</IonLabel>
              <IonSelect
                onFocus={(e) => fetchGroups()}
                cancelText="Cancel"
                okText="Okay"
                onIonChange={(e) => {
                  setGroupId(e.detail.value);
                }}
              >
                {listGroups}
              </IonSelect>
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Coach</IonLabel>
              <IonSelect
                onFocus={(e) => fetchCoaches()}
                cancelText="Cancel"
                okText="Okay"
                onIonChange={(e) => {
                  setCoach(e.detail.value);
                }}
              >
                {listCoaches}
              </IonSelect>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Group Eligibility FIle</IonLabel>
              <input
                required
                type={"file"}
                onChange={(e) => setFile(e.target.files[0])}
              ></input>
            </IonItem>
            <div id="add-group-csv-help"></div>
            <IonButton type="submit">Upload File</IonButton>
          </form>
        </IonCardContent>

        <IonModal isOpen={showTemplate}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Template Download</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowTemplate(false)}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <p className="ion-margin">
              Only Column that is required is email and DOB. Make sure to follow
              the format of the template when uploading groups. Only add Walmart
              Health ID if they are already an existing WH user. Use external ID
              if they are coming from Triada system, otherwise leave blank. For
              yes/no questions make sure to put 'Yes' or 'No' in the column.
            </p>
            <IonButton
              className="ion-margin"
              href="https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/helpers%2Fgroup_eligibility_template.csv?alt=media&token=79558fdf-c4b6-42de-ad2f-2356d7b95592"
            >
              Download Template File
            </IonButton>
          </IonContent>
        </IonModal>
      </IonCard>
      <IonCardContent>
        <IonButton onClick={fetchEligibilityFiles}>Refresh</IonButton>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <b>Group</b>
          </IonLabel>
          <IonLabel className="ion-text-wrap">
            <b>Requester</b>
          </IonLabel>
          <IonLabel className="ion-text-wrap">
            <b>Date</b>
          </IonLabel>
          <IonLabel className="ion-text-wrap">
            <b>Total Members</b>
          </IonLabel>
          <IonLabel className="ion-text-wrap">
            <b>New Members</b>
          </IonLabel>
          <IonLabel className="ion-text-wrap">
            <b>Existing Members</b>
          </IonLabel>
          <IonLabel className="ion-text-wrap">
            <b>Errors</b>
          </IonLabel>
        </IonItem>
        {eligibilityFiles.map((file) => {
          return <RenderEligibilityFiles file={file} />;
        })}
      </IonCardContent>
      <GroupExport/>
    </>
  );
}
