import { Redirect, Route } from "react-router-dom";
import * as react from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import ProfileCreate from "../ProfileCreate";
import NotAllowed from "../NotAllowed";

import Menu from "../../../components/Menu";

import Loader from "../../../components/Loader";

import Topbar from "../Topbar";
import Add from "../Add";
import Chat from "../Chat";
import Home from "../Home";
import Preferences from "../Preferences";
import Content from "../Content";
import Appointment from "../Appointment";
import Members from "../Members";
import MyProfile from "../MyProfile";
import MyAccount from "../MyAccount";
import Reporting from "../Reporting";

import Session from "../../../components/Appointment/Session";
import MemberDash from "../../../components/MemberDash/MemberDash";
import Scan from "../../../components/DFX/Scan";
import Meeting from "../../../components/Appointment/Meeting";

import { UserContext } from "../../../lib/context";
import { useContext, useEffect, useState } from "react";
import { CometChat } from "@cometchat-pro/chat";
import Coaches from "../Coaches";
import Communication from "../Communication";
import Bodee from "../Bodee";
import Eligibility from "../Eligibility";
import Affiliates from "../Affiliates";
import TestEmailDigest from "../TestEmailDigest";
import IndividualQuestion from "../../../components/Bodee/IndividualQuestion";

const PrivateRoutes = () => {
  //user user context
  const { user, profile } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  function loginCometUser() {
    const authKey = process.env.REACT_APP_COMET_AUTH_KEY;
    const uid = user.uid;

    CometChat.login(uid, authKey).then(
      (user) => {},
      (error) => {
        console.log("Login failed with exception:", { error });
      }
    );
  }

  useEffect(() => {
    //set loading true for 2 seconds
    loginCometUser();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}

      {profile && profile.hraComplete ? (
        profile.role === "coach" ? (
          <>
            <IonReactRouter>
              <react.IonHeader>
                <Topbar />
              </react.IonHeader>

              <react.IonContent>
                <react.IonSplitPane contentId="main">
                  <Menu />

                  <div className="ion-page" id="main">
                    <react.IonRouterOutlet id="main">
                      <Route path="/" exact={true}>
                        <Redirect to="/page/home" />
                      </Route>
                      <Route path="/page/add" exact={true}>
                        <Add />
                      </Route>
                      <Route path="/page/eligibility" exact={true}>
                        <Eligibility />
                      </Route>
                      <Route path="/page/home" exact={true}>
                        <Home />
                      </Route>
                      <Route path="/page/members" exact={true}>
                        <Members />
                      </Route>
                      <Route path="/page/affiliates" exact={true}>
                        <Affiliates />
                      </Route>
                      <Route path="/page/chat" exact={true}>
                        <Chat />
                      </Route>
                      <Route path="/page/communication" exact={true}>
                        <Communication />
                      </Route>
                      <Route path="/page/testEmailDigest" exact={true}>
                        <TestEmailDigest />
                      </Route>
                      <Route path="/page/chat/:id" exact={true}>
                        <Chat />
                      </Route>
                      <Route path="/page/appointment" exact={true}>
                        <Appointment />
                      </Route>
                      <Route path="/page/bodee" exact={true}>
                        <Bodee />
                      </Route>
                      <Route path="/page/bodee/:id">
                        <IndividualQuestion />
                      </Route>
                      <Route path="/page/reporting" exact={true}>
                        <Reporting />
                      </Route>
                      <Route path="/page/content" exact={true}>
                        <Content />
                      </Route>
                      <Route path="/page/coaches" exact={true}>
                        <Coaches />
                      </Route>
                      <Route path="/page/MyProfile" exact={true}>
                        <MyProfile />
                      </Route>
                      <Route path="/page/MyAccount" exact={true}>
                        <MyAccount />
                      </Route>
                      <Route path="/page/Preferences" exact={true}>
                        <Preferences />
                      </Route>
                      <Route path="/session/:id" exact={true}>
                        <Session />
                      </Route>
                      <Route path="/meeting/:id" exact={true}>
                        <Meeting />
                      </Route>
                      <Route path="/scan/:id" exact={true}>
                        <Scan />
                      </Route>
                      <Route path="/member-dash/:id" exact={true}>
                        <MemberDash />
                      </Route>
                    </react.IonRouterOutlet>
                  </div>
                </react.IonSplitPane>
              </react.IonContent>
            </IonReactRouter>
          </>
        ) : (
          <NotAllowed />
        )
      ) : (
        <>
          <ProfileCreate />
        </>
      )}
    </>
  );
};

export default PrivateRoutes;
