import { heartOutline } from "ionicons/icons";
import { useEffect } from "react";

export interface Interest {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  img: string;
  id: string;
  questions: number;
}

const testImg =
  "https://www.villaggioorizzonte.it/wp-content/uploads/2019/11/vacanza-yoga-natura.jpg";

// Maternal Health & Fertility
// Men's Health
// Naturopathic Medicine
// Nutrition
// Physical Therapy
// Preventative Medicine
// Rehabilitation
// Self-Care
// Sexual Health
// Sleep Health
// Specialists & Expert Advice
// Sun Care
// Supplementation & Vitamins
// Member Stories
// Vaccine Information
// Weight Loss
// Wellness & Self Care
// Women's Health
// Yoga & Stretching

// create interest object from list above
export const InterestList: Interest[] = [
  {
    title: "Anti-Aging & Aesthetics",
    url: "/interest-anti-aging",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FAnti-Aging%20%26%20Aesthetics.png?alt=media&token=b467be88-4a25-40ed-94ec-7130682cda4e",
    id: "interest-anti-aging",
    questions: 0,
  },
  {
    title: "Behavioral Health & Therapy",
    url: "/interest-behavioral-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FBehavioral%20Health%20and%20Therapy.png?alt=media&token=9e29bfa7-34d8-4e56-b077-7f6282127e6a",
    id: "interest-behavioral-health",
    questions: 0,
  },
  {
    title: "Child and Adolescent Health",
    url: "/interest-child-adolescent-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FChild%20and%20Adolescent%20Health.png?alt=media&token=cb21757b-d11b-4eae-bee7-7513928d5457",
    id: "interest-child-adolescent-health",
    questions: 0,
  },
  {
    title: "Children's Health",
    url: "/interest-childrens-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FChildren's%20Health.png?alt=media&token=b837151a-a7bb-4d36-b0f3-1d308a77973e",
    id: "interest-childrens-health",
    questions: 0,
  },
  {
    title: "Coping Skills",
    url: "/interest-coping-skills",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FCoping%20Skills%20(Anxiety%2C%20Stress%2C%20Depression).png?alt=media&token=be6ac265-195e-4f6d-a26f-6bcf278674ca",
    id: "interest-coping-skills",
    questions: 0,
  },
  {
    title: "COVID-19",
    url: "/interest-covid-19",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FCOVID-19.jpg?alt=media&token=5c465b85-a6aa-4367-87c9-f6f7f28568be",
    id: "interest-covid-19",
    questions: 0,
  },
  {
    title: "Diet and Nutrition",
    url: "/interest-diet-nutrition",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FDiet%20and%20Nutrition.jpg?alt=media&token=f7f517f2-7942-43f7-b14e-9a1c51d2b64b",
    id: "interest-diet-nutrition",
    questions: 0,
  },
  {
    title: "Fitness & Exercise",
    url: "/interest-fitness-exercise",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FFitness%20%26%20Exercise.jpg?alt=media&token=62f3e530-f163-4f0c-a475-c805ec54ec31",
    id: "interest-fitness-exercise",
    questions: 0,
  },
  {
    title: "Flexibility & Range of Motion",
    url: "/interest-flexibility",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FFlexibility%20%26%20Range%20of%20Motion.jpg?alt=media&token=760492a7-b984-473e-aad9-8077c8154e74",
    id: "interest-flexibility",
    questions: 0,
  },
  {
    title: "Functional & Integrative Medicine",
    url: "/interest-functional-medicine",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FFunctional%20%26%20Integrative%20Medicine.jpg?alt=media&token=7a6de8a4-0cd6-4de6-a639-bd5c367654bd",
    id: "interest-functional-medicine",
    questions: 0,
  },
  {
    title: "Healthy Heart",
    url: "/interest-healthy-heart",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FHealthy%20Heart.jpg?alt=media&token=2b408099-7a76-476b-a3f2-162d0ad8b7e2",
    id: "interest-healthy-heart",
    questions: 0,
  },
  {
    title: "Healthy Lifestyle",
    url: "/interest-healthy-lifestyle",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FHealthy%20Lifestyle.jpg?alt=media&token=2b611b56-381e-4f20-913f-0504c4e3a15a",
    id: "interest-healthy-lifestyle",
    questions: 0,
  },
  {
    title: "Healthy Recipes",
    url: "/interest-healthy-recipes",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FHealthy%20Recipes.jpg?alt=media&token=8436d012-4a10-4159-b71c-a79492e94ee0",
    id: "interest-healthy-recipes",
    questions: 0,
  },
  {
    title: "Healthy Travel",
    url: "/interest-healthy-travel",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FHealthy%20Travel.png?alt=media&token=b5c4333a-7107-4e7e-a918-0ea5a9390069",
    id: "interest-healthy-travel",
    questions: 0,
  },
  {
    title: "Holistic Medicine",
    url: "/interest-holistic-medicine",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FHolistic%20Medicine.jpg?alt=media&token=d2c72136-471c-4136-bc4a-ae4a72aeb1fe",
    id: "interest-holistic-medicine",
    questions: 0,
  },
  {
    title: "Hormones & Peptides",
    url: "/interest-hormones-peptides",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FHormones%20%26%20Peptides.png?alt=media&token=fe2ca967-6dba-41a3-acaa-a2b603e3f26d",
    id: "interest-hormones-peptides",
    questions: 0,
  },
  {
    title: "Immune health",
    url: "/interest-immune-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FImmune%20health.png?alt=media&token=cf802386-a013-4e02-859c-11714be972a6",
    id: "interest-immune-health",
    questions: 0,
  },
  {
    title: "Latest Health News",
    url: "/interest-latest-health-news",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FLatest%20Health%20News.png?alt=media&token=709ac24a-868e-45f8-863f-3ab351e3aece",
    id: "interest-latest-health-news",
    questions: 0,
  },
  {
    title: "Longevity",
    url: "/interest-longevity",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FLongevity.png?alt=media&token=21f67494-b2e6-4495-9131-f0854b53cbb8",
    id: "interest-longevity",
    questions: 0,
  },
  {
    title: "Maternal Health & Fertility",
    url: "/interest-maternal-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FMaternal%20Health%20%26%20Fertility.png?alt=media&token=d7da9f0c-f1ea-46bf-b9a7-dfa8f4adf06e",
    id: "interest-maternal-health",
    questions: 0,
  },
  {
    title: "Men's Health",
    url: "/interest-mens-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FMen's%20Health.png?alt=media&token=f56cd20f-029a-4dd8-b540-d9a780a86367",
    id: "interest-mens-health",
    questions: 0,
  },
  {
    title: "Naturopathic Medicine",
    url: "/interest-naturopathic-medicine",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FNaturopathic%20Medicine.png?alt=media&token=d62dd482-dd84-4672-b44a-22d3e923206d",
    id: "interest-naturopathic-medicine",
    questions: 0,
  },
  {
    title: "Nutrition",
    url: "/interest-nutrition",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FNutrition.png?alt=media&token=36b47656-7d2b-46a4-9744-02d785ee414b",
    id: "interest-nutrition",
    questions: 0,
  },
  {
    title: "Physical Therapy",
    url: "/interest-physical-therapy",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FPhysical%20Therapy.png?alt=media&token=5e406328-6cd9-44a5-8b75-5dc39d66deb5",
    id: "interest-physical-therapy",
    questions: 0,
  },
  {
    title: "Preventative Medicine",
    url: "/interest-preventative-medicine",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FPreventive%20Medicine.png?alt=media&token=1fad09d7-7a22-444d-95b2-8fe194e9f94e",
    id: "interest-preventative-medicine",
    questions: 0,
  },
  {
    title: "Rehabilitation",
    url: "/interest-rehabilitation",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FRehabilitation.png?alt=media&token=03599c9b-8d46-4d6b-a287-60483d1fe74b",
    id: "interest-rehabilitation",
    questions: 0,
  },
  {
    title: "Self-Care",
    url: "/interest-self-care",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FSelf-Care.png?alt=media&token=2ceb73d2-f51f-4ada-83ab-06e4a3e62942",
    id: "interest-self-care",
    questions: 0,
  },
  {
    title: "Sexual Health",
    url: "/interest-sexual-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FSexual%20Health.png?alt=media&token=67b0cd32-c5c5-44ce-928e-f64f9bf4b491",
    id: "interest-sexual-health",
    questions: 0,
  },
  {
    title: "Sleep Health",
    url: "/interest-sleep-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FSleep%20Health.png?alt=media&token=9b0ef291-d670-47e4-bcdc-312d7209535b",
    id: "interest-sleep-health",
    questions: 0,
  },
  {
    title: "Specialists & Expert Advice",
    url: "/interest-specialists-expert-advice",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FSpecialist%20%26%20Expert%20Advice.png?alt=media&token=efc24975-2fa7-4035-acb4-fe747a00740c",
    id: "interest-specialists-expert-advice",
    questions: 0,
  },
  {
    title: "Sun Care",
    url: "/interest-sun-care",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FSun%20Care.png?alt=media&token=ee9aac8c-5bc5-4919-860b-05327907570d",
    id: "interest-sun-care",
    questions: 0,
  },
  {
    title: "Supplementation & Vitamins",
    url: "/interest-supplementation-vitamins",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FSupplementation%20%26%20Vitamins.png?alt=media&token=89c8e10c-3b0b-45f7-b7ab-0d7d0e30ef52",
    id: "interest-supplementation-vitamins",
    questions: 0,
  },
  {
    title: "Member Stories",
    url: "/interest-member-stories",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FMember%20Stories.png?alt=media&token=19b1ae92-55e0-4830-9604-8c49e13f7c2f",
    id: "interest-member-stories",
    questions: 0,
  },
  {
    title: "Vaccine Information",
    url: "/interest-vaccine-information",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FVaccine%20Information.png?alt=media&token=20e3cb29-82c8-4321-be39-e2113c47a4c9",
    id: "interest-vaccine-information",
    questions: 0,
  },
  {
    title: "Weight Loss",
    url: "/interest-weight-loss",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FWeight%20Loss.png?alt=media&token=307c4395-3c91-4e8d-a690-5f7f69133fb8",
    id: "interest-weight-loss",
    questions: 0,
  },
  {
    title: "Wellness & Self Care",
    url: "/interest-wellness-self-care",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FWellness%20%26%20Self%20Care.png?alt=media&token=456c487c-28ea-4062-a9fe-1128ab370eb5",
    id: "interest-wellness-self-care",
    questions: 0,
  },
  {
    title: "Women's Health",
    url: "/interest-womens-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FWomen's%20Health.png?alt=media&token=ffa2ae6d-da69-43da-a7be-57df78f5c584",
    id: "interest-womens-health",
    questions: 0,
  },
  {
    title: "Yoga & Stretching",
    url: "/interest-yoga-stretching",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Finterest%2FYoga%20%26%20Stretching.png?alt=media&token=27b54010-8f59-4f95-af33-3c7e9fa6e15c",
    id: "interest-yoga-stretching",
    questions: 0,
  },
];
