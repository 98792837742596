import { IonAlert, IonButton } from "@ionic/react";
import { useState } from "react";
import { TerminateActivateGroupUpdate } from "../../../../../../API/metrics/TerminateGroupApi";

export default function TerminateGroupButton(props) {
  const [showConfirmTerminateAlert, setShowConfirmTerminateAlert] = useState(false);
  const [showTerminateSuccessAlert, setShowTerminateSuccessAlert] = useState(false);

  const handleTerminateGroupButton = async () => {
    setShowConfirmTerminateAlert(true);
  };

  const handleTerminateGroupConfirm = async () => {
    try {
      await TerminateActivateGroupUpdate(props.group, { active: false });
      console.log("Group Terminate Successfully");
      setShowTerminateSuccessAlert(true);
    } catch (error) {
      console.log("Can't Terminate Group: ", error);
    }
  };

  const handleTerminateGroupCancel = async () => {
    setShowConfirmTerminateAlert(false);
  };

  const handleTerminateSuccessAlertDismiss = () => {
    setShowTerminateSuccessAlert(false);
    window.location.reload();
  };

  return (
    <>
      <IonButton color="warning" onClick={handleTerminateGroupButton}>
        TERMINATE GROUP
      </IonButton>
      <IonAlert
        isOpen={showConfirmTerminateAlert}
        onDidDismiss={() => setShowConfirmTerminateAlert(false)}
        header={`Confirm Terminate GroupID: ${props.group}`}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: handleTerminateGroupCancel,
          },
          {
            text: "Terminate",
            handler: handleTerminateGroupConfirm,
          },
        ]}
      />
      <IonAlert
        isOpen={showTerminateSuccessAlert}
        onDidDismiss={handleTerminateSuccessAlertDismiss}
        header={`Group ${props.group} successfully terminated.`}
      />
    </>
  );
}
