import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
} from "@ionic/react";
import "./style.css";
import { useEffect, useState } from "react";
import { refresh } from "ionicons/icons";
import QuestionCard from "..";
// import MessageBox from "../../Home/MessageBox";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
// import { getDepOptimizationConfig } from "vite";
import Swiper from "swiper";
import { SwiperSlide } from "swiper/react";

import { getAge } from "../../../../lib/helpers";

import { getUserAvatar } from "../../../../lib/helpers";
import Avatar from "avataaars";

type Props = { src; publicAvatarUid; expert };

const AvatarMini: React.FC<Props> = ({ src, publicAvatarUid, expert }) => {
  const [viewAvatar, setViewAvatar] = useState(false);
  const [avatarData, setAvatarData] = useState({}) as any;
  const [coachData, setCoachData] = useState({}) as any;

  const [questionsData, setQuestionsData] = useState([]);

  const getAvatarData = async () => {
    const data = await getUserAvatar(publicAvatarUid);
    setAvatarData(data);
  };

  const getCoachData = async () => {
    const docRef = doc(getFirestore(), "users", publicAvatarUid);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setCoachData(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  useEffect(() => {
      if (expert) {
        getCoachData();
      } else {
        getAvatarData();
      }
  }, [viewAvatar]);

  const getQuestions = async () => {
    var questions = [];

    var q = query(
      collection(getFirestore(), "koroQuestions"),
      where("hidden", "==", false),
      where("poster", "==", publicAvatarUid)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      questions.push({ id: doc.id, ...doc.data() });
    });

    // sort questions by timestamp.seconds in ascending order
    questions.sort((a, b) => {
      return b.dateCreated.seconds - a.dateCreated.seconds;
    });

    setQuestionsData(questions);
  };

  useEffect(() => {
    getQuestions();
  }, [publicAvatarUid]);

  return (
    <>
      {/* {expert ? "t" : "f"} */}
      {/* <IonAvatar
        className="koroQuestionAvatarMini"
        onClick={() => setViewAvatar(true)}
      >
        <img src={src} />
      </IonAvatar> */}
      {expert ? (
        <IonAvatar
          className="koroQuestionAvatarMini"
          onClick={() => setViewAvatar(true)}
        >
          <img src={coachData.photoURL} />
        </IonAvatar>
      ) : (
        <div onClick={() => setViewAvatar(true)} className="ion-padding-end">
          <Avatar
            style={{ width: "40px", height: "40px" }}
            avatarStyle="Circle"
            {...avatarData.avatarConfig}
          />
        </div>
      )}
      <IonModal isOpen={viewAvatar}>
        {expert ? (
          <>
            <IonHeader>
              <IonToolbar>
                <IonTitle>
                  {coachData.firstName} {coachData.lastName}
                </IonTitle>
                <IonButtons slot="start">
                  <IonButton onClick={() => setViewAvatar(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="backgroundColor ion-padding-bottom">
              <div className="ionPaddingTopLarge">
                <div className="setPublicAvatarContainer">
                  <IonAvatar className={"setAvatarPhoto"}>
                    <img src={coachData.photoURL} alt="profile" />
                  </IonAvatar>

                  <h2 className="ion-text-center"> {avatarData.displayName}</h2>
                  {/* <p className="ion-text-center">
                    {avatarData.gender === "male" ? "Male" : "Female"},{" "}
                    <span className="bold">{getAge(avatarData.dob)}</span>
                  </p> */}

                  {/* <div className="ionPaddingTopSmall">
                    <h3 className="ion-text-center">Interest</h3>
                    <div className="ion-text-center">
                      {avatarData.interest ? (
                        <>
                          {avatarData.interest.map((interest, index) => (
                            <IonChip key={index}>{interest}</IonChip>
                          ))}
                        </>
                      ) : (
                        <p className="ion-text-center">No interest selected</p>
                      )}
                    </div> */}
                  {/* </div> */}
                  <IonLabel className="ion-text-center ion-margin ion-padding">
                    <h2 className="ion-text-center ion-margin ion-padding">
                      {coachData.title}
                    </h2>
                    <h3>{coachData.qualifications}</h3>
                  </IonLabel>
                  <IonLabel className="ion-text-center">
                    <p>{coachData.about}</p>
                  </IonLabel>
                  <div className="ionPaddingTopSmall">
                    <p className="ion-text-center">Bodee Contributor Score</p>
                    <h3 className="ion-text-center">
                      {coachData.koroScore === undefined
                        ? 0
                        : coachData.koroScore}
                    </h3>
                  </div>
                  <div className="ionPaddingTopSmall">
                    <h3 className="ion-text-center">Post</h3>
                    {questionsData.length === 0 ? (
                      <IonLabel className="ion-text-center">
                        <p>
                          <>No post yet...</>
                        </p>
                      </IonLabel>
                    ) : null}
                    {questionsData.map((question, index) => {
                      return (
                        <QuestionCard
                          question={question}
                          key={question.id}
                          refresh={refresh}
                          refreshHandler={null}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </IonContent>
          </>
        ) : (
          <>
            {" "}
            <IonHeader>
              <IonToolbar>
                <IonTitle>{avatarData.displayName}</IonTitle>
                <IonButtons slot="start">
                  <IonButton onClick={() => setViewAvatar(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="backgroundColor ion-padding-bottom">
              <div className="ionPaddingTopLarge">
                <div className="setPublicAvatarContainer">
                  <Avatar
                    style={{ width: "150px", height: "150px" }}
                    avatarStyle="Circle"
                    {...avatarData.avatarConfig}
                  />

                  <h2 className="ion-text-center"> {avatarData.displayName}</h2>
                  <p className="ion-text-center">
                    {avatarData.gender === "male" ? "Male" : "Female"},{" "}
                    <span className="bold">{getAge(avatarData.dob)}</span>
                  </p>

                  <div className="ionPaddingTopSmall">
                    <h3 className="ion-text-center">Interest</h3>
                    <div className="ion-text-center">
                      {avatarData.interest ? (
                        <>
                          {avatarData.interest.map((interest, index) => (
                            <IonChip key={index}>{interest}</IonChip>
                          ))}
                        </>
                      ) : (
                        <p className="ion-text-center">No interest selected</p>
                      )}
                    </div>
                  </div>
                  <div className="ionPaddingTopSmall">
                    <p className="ion-text-center">Bodee Contributor Score</p>
                    <h3 className="ion-text-center">{avatarData.koroScore}</h3>
                  </div>
                  <div className="ionPaddingTopSmall">
                    <h3 className="ion-text-center">Post</h3>
                    {questionsData.map((question, index) => {
                      return (
                        <QuestionCard
                          question={question}
                          key={question.id}
                          refresh={refresh}
                          refreshHandler={null}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </IonContent>
          </>
        )}
      </IonModal>
    </>
  );
};

export default AvatarMini;
