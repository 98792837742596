import { useEffect, useState } from "react";
import "./style.css";
import { doc, getDoc, getFirestore } from "@firebase/firestore";
import { IonLabel } from "@ionic/react";

type Props = {
  id: string;
};

const KoroQuiz: React.FC<Props> = ({ id }) => {
  const [quizData, setQuizData] = useState(null);

  const fetchQuizData = async () => {
    // fetch quiz data from firestore
    const docRef = doc(getFirestore(), "koroQuiz", id);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setQuizData(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      // console.log("No such document!");
    }
  };

  useEffect(() => {
    fetchQuizData();
  }, []);

  if (quizData == undefined || quizData == null || quizData == "") {
    return <div>Did not fill out Bodee Quiz</div>;
  }

  return (
    <>
      <div>
        <h2>Bodee Quiz</h2>
        <IonLabel>
          <p>
            The question is for:{" "}
            <span className="bold">{quizData["onBehalfOf"]}</span>
          </p>
        </IonLabel>
        <IonLabel>
          <p>
            Emergency: <span className="bold">{quizData["emergency"]}</span>
          </p>
        </IonLabel>
        <IonLabel>
          <p>
            Is this a chronic issue:{" "}
            <span className="bold">{quizData["chronicIssue"]}</span>
          </p>
        </IonLabel>
        <IonLabel>
          <p>
            What part of the body is being affected:{" "}
            <span className="bold">
              {quizData["bodyPart"].map((bodyPart, index) => {
                return <span key={index}>{bodyPart}, </span>;
              })}
            </span>
          </p>
        </IonLabel>
        <IonLabel>
          <p>
           What type of expert answer are you looking for:{" "}
           <span className="bold">
              {quizData["expertAnswerType"].map((bodyPart, index) => {
                return <span key={index}>{bodyPart}, </span>;
              })}
            </span>
          </p>
        </IonLabel>
      </div>
    </>
  );
};

export default KoroQuiz;
