//this function pretty much just takes in result object and renders div blocks based on their ID with conditions from
//https://docs.deepaffex.ai/core/8_results.html this is where all results are explained
export const setColor = (resultsObj: any) => {
  const green = "#62db99";
  const yellow = "#ffec8c";
  const red = "#ff848c";

  let ppm = document.getElementById("HR_BPM")!; // heart rate

  let irregularHeartBeats = document.getElementById("IHB_COUNT")!; // irregular heart beats

  let breathing = document.getElementById("BR_BPM")!; // irregular heart beats

  let systolic = document.getElementById("BP_SYSTOLIC")!; //systolic blood pressure

  let diastolic = document.getElementById("BP_DIASTOLIC")!; //diastolic blood pressure

  let hrv = document.getElementById("HRV_SDNN")!; //heart rate variability

  let cardiacWorkoad = document.getElementById("BP_RPP")!; // cardiac Workload

  let msi = document.getElementById("MENTAL_STRESS_INDEX")!; // stess index

  let bmi = document.getElementById("BMI_CALC")!; // mody mass index
  let waistToHeight = document.getElementById("WAIST_TO_HEIGHT")!; // waist To height
  let absi = document.getElementById("ABSI")!; // body shape index
  let cvdRisk = document.getElementById("BP_CVD")!; // cardio vascular disease risk
  let heartAttackRisk = document.getElementById("BP_HEART_ATTACK")!; // heart attack risk
  let strokeRisk = document.getElementById("BP_STROKE")!; // stroke risk

  /*
    let HypertensionRisk = document.getElementById('HypertensionRisk')!; // Hypertension Risk
    let HypertriglyceridemiaRisk = document.getElementById('HypertriglyceridemiaRisk')!; // Hypertriglyceridemia Risk
    let HypercholesterolemiaRisk = document.getElementById('HypercholesterolemiaRisk')!; // Hypercholesterolemia Risk
    let DiabetesRisk = document.getElementById('DiabetesRisk')!; // Diabetes Risk
*/
  //PPM
  if (100 > resultsObj["HR_BPM"] && resultsObj["HR_BPM"] > 60)
    ppm.style.background = green;
  else ppm.style.background = yellow;

  //Irregular Heart Beats
  if (resultsObj["IHB_COUNT"] < 1) irregularHeartBeats.style.background = green;
  else irregularHeartBeats.style.background = red;

  //breathing
  if (resultsObj["BR_BPM"] > 12 && resultsObj["BR_BPM"] < 25)
    breathing.style.background = green;
  else breathing.style.background = yellow;

  //systolic
  if (resultsObj["BP_SYSTOLIC"] > 140) systolic.style.background = red;
  else if (resultsObj["BP_SYSTOLIC"] > 90 && resultsObj["BP_SYSTOLIC"] < 130)
    systolic.style.background = green;
  else systolic.style.background = yellow;

  //diastolic
  if (resultsObj["BP_DIASTOLIC"] > 90) diastolic.style.background = red;
  else if (resultsObj["BP_DIASTOLIC"] > 60 && resultsObj["BP_DIASTOLIC"] < 80)
    diastolic.style.background = green;
  else diastolic.style.background = yellow;

  //heart rate var.
  if (resultsObj["HRV_SDNN"] < 16.4) hrv.style.background = red;
  else if (resultsObj["HRV_SDNN"] > 35.5) hrv.style.background = green;
  else hrv.style.background = yellow;

  //cardiac workload
  if (resultsObj["BP_RPP"] > 4.08) cardiacWorkoad.style.background = red;
  else if (resultsObj["BP_RPP"] < 3.9) cardiacWorkoad.style.background = green;
  else cardiacWorkoad.style.background = yellow;

  //mental stress index
  if (resultsObj["MENTAL_STRESS_INDEX"] > 4.0) msi.style.background = red;
  else if (resultsObj["MENTAL_STRESS_INDEX"] < 3.0)
    msi.style.background = green;
  else msi.style.background = yellow;

  //body mass index
  if (resultsObj["BMI_CALC"] > 30) bmi.style.background = red;
  else if (resultsObj["BMI_CALC"] < 25.0 && resultsObj["BMI_CALC"] > 18.5)
    bmi.style.background = green;
  else bmi.style.background = yellow;

  //waist to height
  if (resultsObj["WAIST_TO_HEIGHT"] > 58.0)
    waistToHeight.style.background = red;
  else if (
    resultsObj["WAIST_TO_HEIGHT"] < 53.0 &&
    resultsObj["WAIST_TO_HEIGHT"] > 43.0
  )
    waistToHeight.style.background = green;
  else waistToHeight.style.background = yellow;

  //body shape index
  if (resultsObj["ABSI"] > 7.95) absi.style.background = red;
  else if (resultsObj["ABSI"] < 7.07) absi.style.background = green;
  else absi.style.background = yellow;

  //cardio vasculare risk
  if (resultsObj["BP_CVD"] > 10.0) cvdRisk.style.background = red;
  else if (resultsObj["BP_CVD"] < 7.25) cvdRisk.style.background = green;
  else cvdRisk.style.background = yellow;

  //heart attack risk
  if (resultsObj["BP_HEART_ATTACK"] > 3.3)
    heartAttackRisk.style.background = red;
  else if (resultsObj["BP_HEART_ATTACK"] < 2.39)
    heartAttackRisk.style.background = green;
  else heartAttackRisk.style.background = yellow;

  //stroke risk
  if (resultsObj["BP_STROKE"] > 6.6) strokeRisk.style.background = red;
  else if (resultsObj["BP_STROKE"] < 4.79) strokeRisk.style.background = green;
  else strokeRisk.style.background = yellow;
  /*
    //HypertensionRisk
    if(resultsObj['HypertensionRisk'] > 60) HypertensionRisk.style.background = red
    else if (resultsObj['HypertensionRisk'] < 40) HypertensionRisk.style.background = green
    else HypertensionRisk.style.background = yellow
    
    //HypertriglyceridemiaRisk
    if(resultsObj['HypertriglyceridemiaRisk'] > 60) HypertriglyceridemiaRisk.style.background = red
    else if (resultsObj['HypertriglyceridemiaRisk'] < 40) HypertriglyceridemiaRisk.style.background = green
    else HypertriglyceridemiaRisk.style.background = yellow

    //HypercholesterolemiaRisk
    if(resultsObj['HypercholesterolemiaRisk'] > 60) HypercholesterolemiaRisk.style.background = red
    else if (resultsObj['HypertriglyceridemiaRisk'] < 40) HypercholesterolemiaRisk.style.background = green
    else HypercholesterolemiaRisk.style.background = yellow

    //DiabetesRisk
    if(resultsObj['DiabetesRisk'] > 60) DiabetesRisk.style.background = red
    else if (resultsObj['DiabetesRisk'] < 40) DiabetesRisk.style.background = green
    else DiabetesRisk.style.background = yellow
   */
};

//this opens email client with URL to results page
export const openEmail = (name: string) => {
  const body =
    "Please do not change link below, this is how your health coach will view your data and give you feedback on your assessment. ";
  const url = window.location.href;
  const link =
    "mailto:healthcoaches@triada.com?subject=HRM Face Scan&body=" +
    body +
    " %0D%0A%0D%0AName: " +
    name +
    "%0D%0A%0D%0A" +
    url;
  window.location.href = link;
};

export const getColors = (resultsObj: any) => {
  const green = "#62db99";
  const yellow = "#ffec8c";
  const red = "#ff848c";

  let colors: any = {};

  // add ppm to colors

  /*
    let HypertensionRisk = document.getElementById('HypertensionRisk')!; // Hypertension Risk
    let HypertriglyceridemiaRisk = document.getElementById('HypertriglyceridemiaRisk')!; // Hypertriglyceridemia Risk
    let HypercholesterolemiaRisk = document.getElementById('HypercholesterolemiaRisk')!; // Hypercholesterolemia Risk
    let DiabetesRisk = document.getElementById('DiabetesRisk')!; // Diabetes Risk
*/

  if (100 > resultsObj["ppm"] && resultsObj["ppm"] > 60) colors["ppm"] = green;
  else colors["ppm"] = yellow;

  //Irregular Heart Beats
  if (resultsObj["irregularHeartBeats"] < 1)
    colors["irregularHeartBeats"] = green;
  else colors["irregularHeartBeats"] = red;

  //breathing
  if (resultsObj["breathing"] > 12 && resultsObj["breathing"] < 25)
    colors["breathing"] = green;
  else colors["breathing"] = yellow;

  //systolic
  if (resultsObj["systolic"] > 140) colors["systolic"] = red;
  else if (resultsObj["systolic"] > 90 && resultsObj["systolic"] < 130)
    colors["systolic"] = green;
  else colors["systolic"] = yellow;

  //diastolic
  if (resultsObj["diastolic"] > 90) colors["diastolic"] = red;
  else if (resultsObj["diastolic"] > 60 && resultsObj["diastolic"] < 80)
    colors["diastolic"] = green;
  else colors["diastolic"] = yellow;

  //heart rate var.
  if (resultsObj["heartRateVariability"] < 16.4)
    colors["heartRateVariability"] = red;
  else if (resultsObj["heartRateVariability"] > 35.5)
    colors["heartRateVariability"] = green;
  else colors["heartRateVariability"] = yellow;

  //cardiac workload
  if (resultsObj["cardiacWorkload"] > 4.08) colors["cardiacWorkload"] = red;
  else if (resultsObj["cardiacWorkload"] < 3.9)
    colors["cardiacWorkload"] = green;
  else colors["cardiacWorkload"] = yellow;

  //mental stress index
  if (resultsObj["msi"] > 4.0) colors["msi"] = red;
  else if (resultsObj["msi"] < 3.0) colors["msi"] = green;
  else colors["msi"] = yellow;

  //body mass index
  if (resultsObj["bmi"] > 30) colors["bmi"] = red;
  else if (resultsObj["bmi"] < 25.0 && resultsObj["bmi"] > 18.5)
    colors["bmi"] = green;
  else colors["bmi"] = yellow;

  //waist to height
  if (resultsObj["waistToHeight"] > 58.0) colors["waistToHeight"] = red;
  else if (
    resultsObj["waistToHeight"] < 53.0 &&
    resultsObj["waistToHeight"] > 43.0
  )
    colors["waistToHeight"] = green;
  else colors["waistToHeight"] = yellow;

  //body shape index
  if (resultsObj["absi"] > 7.95) colors["absi"] = red;
  else if (resultsObj["absi"] < 7.07) colors["absi"] = green;
  else colors["absi"] = yellow;

  //cardio vasculare risk
  if (resultsObj["cvdRisk"] > 10.0) colors["cvdRisk"] = red;
  else if (resultsObj["cvdRisk"] < 7.25) colors["cvdRisk"] = green;
  else colors["cvdRisk"] = yellow;

  //heart attack risk
  if (resultsObj["heartAttackRisk"] > 3.3) colors["heartAttackRisk"] = red;
  else if (resultsObj["heartAttackRisk"] < 2.39)
    colors["heartAttackRisk"] = green;
  else colors["heartAttackRisk"] = yellow;

  //stroke risk
  if (resultsObj["strokeRisk"] > 6.6) colors["strokeRisk"] = red;
  else if (resultsObj["strokeRisk"] < 4.79) colors["strokeRisk"] = green;
  else colors["strokeRisk"] = yellow;
  /*
    //HypertensionRisk
    if(resultsObj['HypertensionRisk'] > 60) HypertensionRisk.style.background = red
    else if (resultsObj['HypertensionRisk'] < 40) HypertensionRisk.style.background = green
    else HypertensionRisk.style.background = yellow
    
    //HypertriglyceridemiaRisk
    if(resultsObj['HypertriglyceridemiaRisk'] > 60) HypertriglyceridemiaRisk.style.background = red
    else if (resultsObj['HypertriglyceridemiaRisk'] < 40) HypertriglyceridemiaRisk.style.background = green
    else HypertriglyceridemiaRisk.style.background = yellow

    //HypercholesterolemiaRisk
    if(resultsObj['HypercholesterolemiaRisk'] > 60) HypercholesterolemiaRisk.style.background = red
    else if (resultsObj['HypertriglyceridemiaRisk'] < 40) HypercholesterolemiaRisk.style.background = green
    else HypercholesterolemiaRisk.style.background = yellow

    //DiabetesRisk
    if(resultsObj['DiabetesRisk'] > 60) DiabetesRisk.style.background = red
    else if (resultsObj['DiabetesRisk'] < 40) DiabetesRisk.style.background = green
    else DiabetesRisk.style.background = yellow
   */

  return colors;
};
