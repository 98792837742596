import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPopover,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { add, checkbox, chevronForward, squareOutline } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import GoalsDailyGraph from "./GoalsDailyGraph";
import GoalsOneTimeGraph from "./GoalsOneTimeGraph";
import GoalsWeeklyGraph from "./GoalsWeeklyGraph";

import "./GoalsCard.css";
import { getCurrentDate } from "../../HelperFunctions/dates";
import { UserContext } from "../../lib/context";
import { get } from "https";

export default function GoalsCard({ uid, email }) {
  const [date, setDate] = useState(getCurrentDate());
  const [goals, setGoals] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [addGoal, setAddGoal] = useState(false);

  const [viewMoreGoalOpen, setViewMoreGoalOpen] = useState(false);
  const [viewMoreGoal, setViewMoreGoal] = useState({});

  const [viewHistory, setViewHistory] = useState(false);

  const [goalTitle, setGoalTitle] = useState("");
  const [goalDescription, setGoalDescription] = useState("");
  const [goalFreq, setGoalFreq] = useState("");
  const [goalStartDate, setGoalStartDate] = useState(getCurrentDate());
  const [goalEndDate, setGoalEndDate] = useState("0000-00-00");
  const [validStartDate, setValidStartDate] = useState(false);
  const [validEndDate, setValidEndDate] = useState(false);

  const [startToday, setStartToday] = useState(true);
  const [hasEndDate, setHasEndDate] = useState(false);

  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  const RenderActiveGoalsDaily = () => {
    let goalsList = [];
    goals.forEach((goal) => {
      if (goal.active === true && goal.freq === "daily") {
        if (goal.completed === true) {
          goalsList.push(
            <IonItem
              className="goal"
              key={goal.id}
              lines="none"
              color="success"
            >
              {" "}
              <IonIcon
                onClick={() => markGoalIncomplete(goal)}
                icon={checkbox}
                slot="end"
              ></IonIcon>{" "}
              <IonLabel
                class="goal-text"
                onClick={() => {
                  setViewMoreGoalOpen(true);
                  setViewMoreGoal(goal);
                }}
              >
                {goal.title}{" "}
              </IonLabel>{" "}
            </IonItem>
          );
        } else {
          goalsList.push(
            <IonItem
              className="goal need-goal goal-text"
              lines="none"
              color="primary"
              fill="outline"
              key={goal.id}
            >
              {" "}
              <IonIcon
                icon={squareOutline}
                color="primary"
                slot="end"
                onClick={() => markGoalComplete(goal)}
              ></IonIcon>{" "}
              <IonLabel
                className="goal-title "
                onClick={() => {
                  setViewMoreGoalOpen(true);
                  setViewMoreGoal(goal);
                }}
              >
                {" "}
                {goal.title}{" "}
              </IonLabel>{" "}
            </IonItem>
          );
        }
      }
    });

    if (goalsList.length === 0) {
      return <></>;
    }

    return (
      <>
        Daily
        {goalsList}
      </>
    );
  };

  const RenderActiveGoalsWeekly = () => {
    let goalsList = [];
    goals.forEach((goal) => {
      if (goal.active === true && goal.freq === "weekly") {
        if (goal.completed === true) {
          goalsList.push(
            <IonItem
              className="goal"
              key={goal.id}
              lines="none"
              color="success"
            >
              {" "}
              <IonIcon
                onClick={() => markGoalIncomplete(goal)}
                icon={checkbox}
                slot="end"
              ></IonIcon>{" "}
              <IonLabel
                class="goal-text"
                onClick={() => {
                  setViewMoreGoalOpen(true);
                  setViewMoreGoal(goal);
                }}
              >
                {goal.title}{" "}
              </IonLabel>{" "}
            </IonItem>
          );
        } else {
          goalsList.push(
            <IonItem
              className="goal need-goal goal-text"
              lines="none"
              color="primary"
              fill="outline"
              key={goal.id}
            >
              {" "}
              <IonIcon
                icon={squareOutline}
                color="primary"
                slot="end"
                onClick={() => markGoalComplete(goal)}
              ></IonIcon>{" "}
              <IonLabel
                className="goal-title "
                onClick={() => {
                  setViewMoreGoalOpen(true);
                  setViewMoreGoal(goal);
                }}
              >
                {" "}
                {goal.title}{" "}
              </IonLabel>{" "}
            </IonItem>
          );
        }
      }
    });

    if (goalsList.length === 0) {
      return <></>;
    }

    return <>Weekly{goalsList}</>;
  };

  const RenderUncompletedGoalsOneTime = () => {
    let goalsList = [];
    goals.forEach((goal) => {
      if (goal.active === true && goal.freq === "one-time") {
        if (goal.completed === true) {
          goalsList.push(
            <>
              <IonItem className="goal" lines="none" color="success">
                {" "}
                <IonIcon
                  onClick={() => completeOneTimeGoal(goal)}
                  icon={checkbox}
                  slot="end"
                ></IonIcon>{" "}
                <IonLabel
                  class="goal-text"
                  onClick={() => {
                    setViewMoreGoalOpen(true);
                    setViewMoreGoal(goal);
                  }}
                >
                  {goal.title}{" "}
                </IonLabel>{" "}
              </IonItem>
            </>
          );
        } else {
          goalsList.push(
            <>
              <IonItem
                className="goal need-goal goal-text"
                lines="none"
                color="primary"
                fill="outline"
              >
                {" "}
                <IonIcon
                  icon={squareOutline}
                  color="primary"
                  slot="end"
                  onClick={() => completeOneTimeGoal(goal)}
                ></IonIcon>{" "}
                <IonLabel
                  className="goal-title "
                  onClick={() => {
                    setViewMoreGoalOpen(true);
                    setViewMoreGoal(goal);
                  }}
                >
                  {" "}
                  {goal.title}{" "}
                </IonLabel>{" "}
              </IonItem>
            </>
          );
        }
      }
    });

    if (goalsList.length === 0) {
      return <></>;
    }

    return <>One Time{goalsList}</>;
  };

  const RenderInactiveGoals = () => {
    let goalsList = [];
    goals.forEach((goal) => {
      if (goal.active === false) {
        goalsList.push(
          <>
            <IonItem className="goal" lines="none" color="success">
              {" "}
              <IonIcon icon={checkbox} slot="end"></IonIcon>{" "}
              <IonLabel
                class="goal-text"
                onClick={() => {
                  setViewMoreGoalOpen(true);
                  setViewMoreGoal(goal);
                }}
              >
                {goal.title}{" "}
              </IonLabel>{" "}
            </IonItem>
          </>
        );
      }
    });

    if (goalsList.length === 0) {
      return <div>No completed goals yet</div>;
    }

    return <>{goalsList}</>;
  };

  const fetchGoals = async () => {
    const q = query(
      collection(getFirestore(), "goals"),
      where("uid", "==", uid)
    );

    var goalsList = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      goalsList.push({ id: doc.id, ...doc.data() });
    });

    setGoals(goalsList);
  };

  useEffect(() => {
    fetchGoals();
  }, []);

  const addNewGoal = async () => {
    const dateStartArray = goalStartDate.split("-");
    const timestampStartDate = new Date(
      parseInt(dateStartArray[0]),
      parseInt(dateStartArray[1]) - 1,
      parseInt(dateStartArray[2])
    );

    const dateEndArray = goalEndDate.split("-");
    const timestampEndDate = new Date(
      parseInt(dateEndArray[0]),
      parseInt(dateEndArray[1]) - 1,
      parseInt(dateEndArray[2])
    );

    const newGoal = {
      title: goalTitle,
      description: goalDescription,
      freq: goalFreq,
      startDate: timestampStartDate,
      endDate: timestampEndDate,
      completed: false,
      uid: uid,
      email: email,
      active: true,
      hasEndDate: hasEndDate,
      numberCompletes: 0,
    };

    await addDoc(collection(getFirestore(), "goals"), newGoal);

    setAddGoal(false);
    setGoalTitle("");
    setGoalDescription("");
    setGoalFreq("");
    setGoalStartDate(getCurrentDate());
    setGoalEndDate("0000-00-00");

    fetchGoals();
  };

  const completeOneTimeGoal = async (goal) => {
    const db = getFirestore();
    const ref = doc(db, `goals/${goal.id}`);
    const data = {
      completed: true,
      active: false,
      numberCompletes: 1,
    };
    await updateDoc(ref, data);

    fetchGoals();
  };

  const changeGoalStatus = async (goal) => {
    const db = getFirestore();
    const ref = doc(db, `goals/${goal.id}`);
    const data = {
      completed: !goal.completed,
    };
    await updateDoc(ref, data);

    fetchGoals();
  };

  const markGoalComplete = async (goal) => {
    const db = getFirestore();
    const ref = doc(db, `goals/${goal.id}`);
    const data = {
      completed: true,
      numberCompletes: goal.numberCompletes + 1,
    };
    await updateDoc(ref, data);

    fetchGoals();
  };

  const markGoalIncomplete = async (goal) => {
    const db = getFirestore();
    const ref = doc(db, `goals/${goal.id}`);
    const data = {
      completed: false,
      numberCompletes: goal.numberCompletes - 1,
    };
    await updateDoc(ref, data);

    fetchGoals();
  };

  const changeGoalActive = async (goal) => {
    const db = getFirestore();
    const ref = doc(db, `goals/${goal.id}`);
    const data = {
      active: !goal.active,
    };
    await updateDoc(ref, data);

    fetchGoals();
  };

  const deleteGoal = async (goal) => {
    console.log("delete", goal);

    const db = getFirestore();
    const ref = doc(db, `goals/${goal.id}`);
    await deleteDoc(ref);

    setViewMoreGoalOpen(false);
    setViewMoreGoal({});
    fetchGoals();
  };

  const deactivateGoal = async (goal) => {
    console.log("deactivate", goal);

    const db = getFirestore();
    const ref = doc(db, `goals/${goal.id}`);
    const data = {
      active: false,
    };
    await updateDoc(ref, data);

    setViewMoreGoalOpen(false);
    setViewMoreGoal({});
    fetchGoals();
  };

  const validateStartDate = () => {
    var startDateHelper = document.getElementById("startDateHelp")!;
    const today = new Date();
    const startDate = new Date(goalStartDate);

    console.log("today", today);
    console.log("startDate", goalStartDate);

    if (startDate < today) {
      startDateHelper.innerHTML = "Start date cannot be in the past";
      setValidStartDate(false);
      return false;
    } else {
      startDateHelper.innerHTML = "";
      setValidStartDate(true);
      return true;
    }
  };

  const validateEndDate = () => {
    var endDateHelper = document.getElementById("endDateHelp")!;
    const startDate = new Date(goalStartDate);
    const endDate = new Date(goalEndDate);

    if (endDate < startDate) {
      endDateHelper.innerHTML = "End date cannot be before start date";
      setValidEndDate(false);
      return false;
    } else {
      endDateHelper.innerHTML = "";
      setValidEndDate(true);
      return true;
    }
  };

  const RenderTracking = ({ goal }) => {
    let daysArray = [];
    const [tester, setTester] = useState(5);

    
    console.log("GOALLALA", goal)

    if (goal.tracking === undefined) {
      return <></>;
    }

    const updateHistory = (day, completed, currentGoal, indexOf) => {
      console.log("INDEX", indexOf)
      console.log("DAY", day)
      console.log("COMPLETED", completed)
      console.log("CURRENT GOAL", currentGoal)

      if(completed === true) {
        currentGoal.tracking[indexOf][day] = false;
        currentGoal.numberCompletes = currentGoal.numberCompletes - 1;

        const db = getFirestore();
        const ref = doc(db, `goals/${currentGoal.id}`);
        const data = {
          tracking: currentGoal.tracking,
          numberCompletes: currentGoal.numberCompletes,
        };
        updateDoc(ref, data).then(() => {
          goal = currentGoal;
          setTester(tester + 1);
        });

      } else {
        currentGoal.tracking[indexOf][day] = true;
        currentGoal.numberCompletes = currentGoal.numberCompletes + 1;

        const db = getFirestore();
        const ref = doc(db, `goals/${currentGoal.id}`);
        const data = {
          tracking: currentGoal.tracking,
          numberCompletes: currentGoal.numberCompletes,
        };
        updateDoc(ref, data).then(() => {
          goal = currentGoal;
          setTester(tester + 1);
        });
      }
    };

    goal.tracking.forEach((element) => {
      for (const item in element) {

        const indexOf = goal.tracking.indexOf(element);


        if (element[item] === true) {
          // get index of element in tracking array
          daysArray.push(
            <IonItem key={item} className="goal" lines="none" color="success" onClick={() => updateHistory(item, element[item], goal, indexOf)}>
              <IonLabel class="goal-text ion-text-center">{item} </IonLabel>{" "} 
            </IonItem>
          );
        } else {
          daysArray.push(
            <IonItem key={item} className="goal" lines="none" color="danger" onClick={() => updateHistory(item, element[item], goal, indexOf)}>
              <IonLabel class="goal-text ion-text-center">{item} </IonLabel>{" "}
            </IonItem>
          );
        }
      }
    });

    return <>{daysArray}</>;
  };

  return (
    <>
      <IonCard className="ion-padding">
        <IonCardHeader>
          <IonToolbar className="remove-bg">
            <IonCardTitle className="ion-text-center">Goals</IonCardTitle>
          </IonToolbar>
          <IonButton onClick={() => setAddGoal(true)}>Add Goal</IonButton>
        </IonCardHeader>
        <IonCardContent>
          {goals.length > 0 ? null : (
            <>
              <p>
                Create your first goal{" "}
                <a
                  className="create-first-goal"
                  onClick={() => setAddGoal(true)}
                >
                  here
                </a>
              </p>
            </>
          )}
          <IonGrid>
            <IonRow>
              <IonCol>
                <RenderActiveGoalsDaily />
                <RenderActiveGoalsWeekly />
                <RenderUncompletedGoalsOneTime />
              </IonCol>
              <IonCol>
                Completed
                <RenderInactiveGoals />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>

      <IonModal isOpen={viewMoreGoalOpen}>
        {viewMoreGoal["title"] === undefined ? (
          <></>
        ) : (
          <>
            {" "}
            <IonHeader>
              <IonToolbar>
                <IonTitle slot="start" className="ion-text-wrap">
                  Goal
                </IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setViewMoreGoalOpen(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <h1>{viewMoreGoal["title"]!}</h1>

              <IonCardSubtitle>{viewMoreGoal["freq"]}</IonCardSubtitle>
              {viewMoreGoal["description"]}
              <br></br>
              <br></br>
              <br></br>

              {viewMoreGoal["hasEndDate"] ? (
                <p>
                  {new Date(
                    viewMoreGoal["startDate"]!.seconds * 1000
                  ).toDateString()}{" "}
                  -{" "}
                  {new Date(
                    viewMoreGoal["endDate"]!.seconds * 1000
                  ).toDateString()}
                </p>
              ) : (
                <>
                  Started on{" "}
                  {new Date(
                    viewMoreGoal["startDate"]!.seconds * 1000
                  ).toDateString()}
                </>
              )}

              <br></br>

              {viewMoreGoal["freq"] === "daily" ? (
                <GoalsDailyGraph goalData={viewMoreGoal} />
              ) : null}

              {viewMoreGoal["freq"] === "weekly" ? (
                <GoalsWeeklyGraph goalData={viewMoreGoal} />
              ) : null}

              {viewMoreGoal["freq"] === "one-time" ? (
                <GoalsOneTimeGraph goalData={viewMoreGoal} />
              ) : null}

              <a
                className="create-first-goal"
                onClick={() => setViewHistory(true)}
              >
                {" "}
                View History{" "}
              </a>

              <IonButton
                expand="block"
                color="danger"
                className="ion-margin-top"
                onClick={() => deleteGoal(viewMoreGoal)}
              >
                Delete
              </IonButton>
              {viewMoreGoal["active"] ? (
                <IonButton
                  expand="block"
                  onClick={() => deactivateGoal(viewMoreGoal)}
                >
                  Deactivate
                </IonButton>
              ) : null}
            </IonContent>
          </>
        )}
      </IonModal>

      <IonModal isOpen={viewHistory}>
        <IonHeader>
          <IonToolbar>
            <IonTitle slot="start">History</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setViewHistory(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <RenderTracking goal={viewMoreGoal} />
        </IonContent>
      </IonModal>

      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle slot="start">Completed</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
            </IonButtons>
            <IonButtons slot="start">
              <IonButton onClick={() => setAddGoal(true)}>
                {" "}
                <IonIcon icon={add} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <RenderInactiveGoals />
        </IonContent>
      </IonModal>

      <IonModal isOpen={addGoal}>
        <IonHeader>
          <IonToolbar>
            <IonTitle slot="start">Add New Goal</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setAddGoal(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>
            <IonLabel position="stacked">Title*</IonLabel>
            <IonInput
              type="text"
              className="goal-input"
              placeholder="Walk for 5 minutes"
              autoCorrect="on"
              autoCapitalize="on"
              value={goalTitle}
              onIonChange={(e) => setGoalTitle(e.detail.value!)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Description</IonLabel>

            <IonInput
              type="text"
              className="goal-input"
              placeholder="Walking 5 minutes everyday will help with building disciplined"
              value={goalDescription}
              autoCorrect="on"
              autoCapitalize="on"
              onIonChange={(e) => setGoalDescription(e.detail.value!)}
            />
          </IonItem>
          <IonItem>
            <IonSegment
              color="primary"
              value={goalFreq}
              onIonChange={(e) => setGoalFreq(e.detail.value!)}
            >
              <IonSegmentButton value="daily">
                <IonLabel>Daily</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="weekly">
                <IonLabel>Weekly</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="one-time">
                <IonLabel>One Time</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonItem>

          <IonItem className="item-pad">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <p className="label">Start Today?</p>
                </IonCol>
                <IonCol></IonCol>
                <IonCol>
                  <IonCol>
                    <IonToggle
                      checked={startToday}
                      onIonChange={(e) => {
                        setStartToday(e.detail.checked);
                        setGoalStartDate(getCurrentDate());
                      }}
                    />
                  </IonCol>
                  <IonCol className="test">{startToday ? "Yes" : "No"}</IonCol>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>

          {startToday ? (
            <></>
          ) : (
            <IonItem className="item-pad">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <p className="label">Start Date</p>
                  </IonCol>
                  <IonCol>
                    <a
                      className="underline"
                      onClick={(e) =>
                        setShowStartDatePicker(!showStartDatePicker)
                      }
                    >
                      {goalStartDate}
                    </a>
                    <IonPopover
                      isOpen={showStartDatePicker}
                      onDidDismiss={() => setShowStartDatePicker(false)}
                      className="ddd"
                      size="auto"
                    >
                      <IonDatetime
                        presentation="date"
                        value={goalStartDate}
                        min={getCurrentDate()}
                        onIonChange={(e: any) => {
                          setGoalStartDate(e.detail.value);
                          setShowStartDatePicker(false);
                          console.log("changed!!!", e.detail.value);
                        }}
                      ></IonDatetime>
                    </IonPopover>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          )}

          <IonItem className="item-pad">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <p className="label">Has End Date?</p>
                </IonCol>
                <IonCol></IonCol>
                <IonCol>
                  <IonCol>
                    <IonToggle
                      checked={hasEndDate}
                      onIonChange={(e) => {
                        setHasEndDate(e.detail.checked);
                        setGoalEndDate("0000-00-00");
                      }}
                    />
                  </IonCol>
                  <IonCol className="test">{hasEndDate ? "Yes" : "No"}</IonCol>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>

          {!hasEndDate ? (
            <></>
          ) : (
            <IonItem className="item-pad">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <p className="label">End Date</p>
                  </IonCol>
                  <IonCol>
                    <a
                      className="underline"
                      onClick={(e) => setShowEndDatePicker(!showEndDatePicker)}
                    >
                      {goalEndDate === "0000-00-00"
                        ? "Set End Date"
                        : goalEndDate}
                    </a>
                    <IonPopover
                      isOpen={showEndDatePicker}
                      onDidDismiss={() => setShowEndDatePicker(false)}
                      className="ddd"
                      size="auto"
                    >
                      <IonDatetime
                        presentation="date"
                        value={goalEndDate}
                        min={goalStartDate}
                        onIonChange={(e: any) => {
                          setGoalEndDate(e.detail.value);
                          setShowEndDatePicker(false);
                          console.log("changed!!!", e.detail.value);
                        }}
                      ></IonDatetime>
                    </IonPopover>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          )}
          {/* {goalDescription}
          <br></br>
          {goalFreq}
          <br></br>
          {goalStartDate}
          <br></br>
          {goalEndDate}
          <br></br>
          {goalTitle} */}

          <IonButton
            expand="block"
            onClick={() => addNewGoal()}
            disabled={
              goalTitle === "" ||
              goalDescription === "" ||
              goalFreq === "" ||
              goalStartDate === "" ||
              goalEndDate === ""
            }
          >
            Add
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
}
