import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonMenuToggle,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Add.css";
//import spinner
import { useContext, useState } from "react";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import React from "react";
import { UserContext } from "../../lib/context";

import "./MyProfile.css";

const Home: React.FC = () => {
  const { user, profile } = useContext(UserContext);

  const [firstName, setFirstName] = useState(profile.firstName);
  const [lastName, setLastName] = useState(profile.lastName);
  const [title, setTitle] = useState(profile.title);
  const [qualifications, setQualifications] = useState(profile.qualifications);
  const [about, setAbout] = useState(profile.about);
  const [calendlyLink, setCalendlyLink] = useState(profile.calendlyLink);
  const [changes, setChanges] = useState(false);
  const [phone, setPhone] = useState(profile.phone);
  const [state, setState] = useState(profile.state);
  const [zip, setZip] = useState(profile.zip);
  const [viewMyProfile, setViewMyProfile] = useState(false);

  const updateMyProfile = async () => {
    const ref = doc(getFirestore(), "users", user.uid);
    if (validatePhone()) {
      await updateDoc(ref, {
        firstName: firstName,
        lastName: lastName,
        title: title,
        qualifications: qualifications,
        about: about,
        calendlyLink: calendlyLink,
        phone: phone,
        state: state,
        zip: zip || "",
      });

      setChanges(false);
    }
  };

  const validatePhone = () => {
    const phoneInput = document.getElementById("phoneHelp");
    console.log("phone", phone);
    var re = /^\d{3}-\d{3}-\d{4}$/;

    if (phone === undefined || phone === "") {
      phoneInput.innerHTML = "Phone number is required";
      return false;
    } else if (!re.test(String(phone).toLowerCase())) {
      phoneInput.innerHTML = "Phone number must be in the format 123-456-7890";
      return false;
    } else {
      phoneInput.innerHTML = "";
      return true;
    }
  };

  const states = [
    { name: "None", value: "" },

    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const renderStates = states.map((state) => (
    <IonSelectOption key={state.abbreviation} value={state.name}>
      {state.name}
    </IonSelectOption>
  ));

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>My Profile</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">First Name</IonLabel>
                <IonInput
                  value={firstName}
                  onIonChange={(e) => {
                    setChanges(true);
                    setFirstName(e.detail.value!);
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Last Name</IonLabel>
                <IonInput
                  value={lastName}
                  onIonChange={(e) => {
                    setChanges(true);
                    setLastName(e.detail.value!);
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Title</IonLabel>
                <IonInput
                  value={title}
                  onIonChange={(e) => {
                    setChanges(true);
                    setTitle(e.detail.value!);
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Qualifications</IonLabel>
                <IonInput
                  value={qualifications}
                  onIonChange={(e) => {
                    setChanges(true);
                    setQualifications(e.detail.value!);
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">State</IonLabel>
                {/* <IonInput
                  value={state}
                  onIonChange={(e) => {
                    setChanges(true);
                    setState(e.detail.value!);
                  }}
                ></IonInput> */}

                <IonSelect
                  value={state}
                  onIonChange={(e) => {
                    setChanges(true);
                    setState(e.detail.value!);
                  }}
                >
                  {renderStates}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Zip</IonLabel>
                <IonInput
                  value={zip}
                  onIonChange={(e) => {
                    setChanges(true);
                    setZip(e.detail.value!);
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">About</IonLabel>
                <IonInput
                  value={about}
                  onIonChange={(e) => {
                    setChanges(true);
                    setAbout(e.detail.value!);
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Calendly Link</IonLabel>
                <IonInput
                  value={calendlyLink}
                  onIonChange={(e) => {
                    setChanges(true);
                    setCalendlyLink(e.detail.value!);
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Phone xxx-xxx-xxxx</IonLabel>
                <IonInput
                  value={phone}
                  onIonChange={(e) => {
                    setChanges(true);
                    setPhone(e.detail.value!);
                  }}
                  onIonBlur={(e: any) => {
                    validatePhone();
                  }}
                ></IonInput>
                <p className="formHelp" id="phoneHelp"></p>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonModal
          isOpen={viewMyProfile}
          onDidDismiss={() => setViewMyProfile(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setViewMyProfile(false)}>
                  Close
                </IonButton>
              </IonButtons>
              <IonTitle>My Profile</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonCardHeader className="ion-text-center">
              <div className="wrapper-my-coach">
                <img src={profile.photoURL} alt="" />
              </div>
            </IonCardHeader>
            <IonCardContent>
              <h1> {profile.firstName + " " + profile.lastName} </h1>
              <IonLabel>
                {profile.title}
                <br></br>
              </IonLabel>
              <IonLabel className="qualifications">
                {profile.qualifications}
                <br></br>
                <br></br>
              </IonLabel>
              <IonText className="ion-text-center">
                {" "}
                {profile.about} <br></br> <br></br>
              </IonText>
              <IonButton
                className="ion-text-center"
                routerLink="/schedule/my-health-coach"
                disabled={true}
              >
                Schedule a call
              </IonButton>
            </IonCardContent>
          </IonContent>
        </IonModal>

        <IonButton
          expand="block"
          disabled={!changes}
          onClick={() => updateMyProfile()}
        >
          Update
        </IonButton>
        <IonButton expand="block" onClick={() => setViewMyProfile(true)}>
          View My Profile
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Home;
