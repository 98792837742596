import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonMenuToggle,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Add.css";
//import spinner
import { useContext, useEffect, useState } from "react";
import { collection, doc, getDocs, getFirestore, orderBy, query, updateDoc, where } from "firebase/firestore";
import React from "react";
import { UserContext } from "../../lib/context";

import "./MyProfile.css";

const Coaches: React.FC = () => {

  const [coaches, setCoaches] = useState<any>([]);

  const fetchCoaches = async () => {
    var q = query(
      collection(getFirestore(), "users"),
      where("active", "==", true),
      where("role", "==", "coach"),
    );

    var coach = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      coach.push(doc.data());
    }
    );

    console.log(coach)

    // order by active members

    coach.sort((a, b) => {
      return b.activeMembers - a.activeMembers;
    })

    
    setCoaches(coach);
  };

  useEffect(() => {
    fetchCoaches();
  }, []);

  // const addActiveMembers = () => {
  //   coaches.forEach(async (coach) => {
  //     var q = query(
  //       collection(getFirestore(), "users"),
  //       where("active", "==", true),
  //       where("coach", "==", coach.uid),
  //     );

  //     var members = [];

  //     const querySnapshot = await getDocs(q);
  //     querySnapshot.forEach((doc) => {
  //       members.push(doc.data());
  //     }
  //     );

  //     console.log(members.length)

  //     const coachRef = doc(getFirestore(), "users", coach.uid);
  //     await updateDoc(coachRef, {
  //       activeMembers: members.length,
  //     });
  //   })

  // }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Coaches</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonItem>
          <IonAvatar slot="start"> </IonAvatar>
          <IonLabel>
            <b>Name</b>
          </IonLabel>
          <IonLabel>
            <b>Email</b>
          </IonLabel>
          <IonLabel>
            <b>State</b>
          </IonLabel>
          <IonLabel>
            <b>Zip</b>
          </IonLabel>
          <IonLabel>
            <b>Active Members</b>
          </IonLabel>
          
        </IonItem>
        {coaches.map((coach) => (
          <>
          <IonItem key={coach.uid}>
          <IonAvatar slot="start">
            {" "}
            <img src={coach.photoURL} />
          </IonAvatar>
          <IonLabel>
            {coach.firstName} {coach.lastName}
          </IonLabel>
          <IonLabel className="ion-text-wrap">{coach.email}</IonLabel>
          <IonLabel>
            {coach.state}
          </IonLabel>
          <IonLabel>{coach.zip}</IonLabel>
          <IonLabel className="ion-text-wrap">
            {coach.activeMembers}
          </IonLabel>
        </IonItem>
          </>
        ))}

        {/* <IonButton onClick={addActiveMembers}>
          Add Active Members
        </IonButton> */}
          

      </IonContent>
    </IonPage>
  );
};

export default Coaches;
