import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/react";
import { MemberUidContext } from "../../../lib/context/MemberUidContext";
import { fetchGetUser } from "../../../API/memberAPI";
import { userType } from "../../../Types/user";
import { callOutline, chatboxOutline, mailOutline } from "ionicons/icons";
import AddChangePhoneBTN from "../AddChangePhoneBTN";
import { useHistory } from "react-router";
import ComplianceModalCoachNote from "../CompliantModaCoachNote";
import PreferredContactMethod from "../PreferredContactMethod";
import ChangeMemberEmail from "../ChangeMemberEmail";
import ResendWelcomeEmail from "../ResendWelcomeEmail";
import MemberHistory from "../MemberHistory/MemberHistory";
import AccountType from "../AccountType";

export default function MemberProfile() {
  const memberUid = useContext(MemberUidContext);
  const [userDatabase, setUserDatabase] = useState<userType[]>([]);
  const [coachDatabase, setCoachDatabase] = useState<userType[]>([]);
  const history = useHistory();

  const fetchGetUserDatabase = useCallback(async () => {
    try {
      const userData = await fetchGetUser(memberUid);
      const coachData = await fetchGetUser(userData[0]?.coach);
      setUserDatabase(userData);
      setCoachDatabase(coachData);
    } catch (error) {
      // Handle error
      console.error("Error MemberProfile fetching user/coach", error);
    }
  }, [memberUid]);

  useEffect(() => {
    fetchGetUserDatabase();
  }, [fetchGetUserDatabase]);

  const handleChatClick = () => {
    const route = "/page/chat/" + userDatabase[0]?.cometChatUid;
    window.location.href = route;
    history.push(route);
  };

  return (
    <>
      {userDatabase.map((userDB) => (
        <IonCard>
          <IonItem lines="none">
            <IonAvatar slot="start">
              <img alt="Member Profile" src={userDB.photoURL} />
            </IonAvatar>
            {userDB.active ? null : (
              <IonBadge slot="end" color={"warning"}>
                INACTIVE
              </IonBadge>
            )}
            <IonButton onClick={handleChatClick}>CHAT</IonButton>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonItem>
                    {userDB.tempPasswordChanged ? null : <ResendWelcomeEmail />}
                    <ChangeMemberEmail />
                    <IonLabel>&nbsp;{userDB.email}</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <AddChangePhoneBTN />
                    <IonLabel>&nbsp;{userDB.phone}</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    {coachDatabase.map((coachDB) => (
                      <IonLabel>
                        {"Coach:"}&nbsp;{coachDB.firstName}&nbsp;
                        {coachDB.lastName}
                        &nbsp;({coachDB.email})
                      </IonLabel>
                    ))}
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>Group:&nbsp;{userDB.groupName}</IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem>
            <IonList>
              <IonLabel>Sex: {userDB.gender}</IonLabel>
              <IonLabel>
                Weight: {userDB.weight} lbs, Height: {userDB.heightFt}'{" "}
                {userDB.heightIn}"
              </IonLabel>
              <IonLabel>DOB: {userDB.dob}</IonLabel>
            </IonList>
          </IonItem>
          <IonRow>
            <IonCol>
              <ComplianceModalCoachNote />
              <MemberHistory />
              <AccountType profile={userDB} />
            </IonCol>
            <IonCol>
              <PreferredContactMethod />
            </IonCol>
          </IonRow>
        </IonCard>
      ))}
    </>
  );
}
