/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonAccordion,
  IonAccordionGroup,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import {
  arrowDownCircle,
  heartOutline,
  heartCircle,
  pulseOutline,
  heartDislikeOutline,
} from "ionicons/icons";
//import spinner
import React, { useCallback, useEffect, useState } from "react";
import lungs from "../../assets/icons/lungs-svgrepo-com.svg";
import stethoscope from "../../assets/icons/stethoscope-svgrepo-com.svg";
import workload from "../../assets/icons/work-bag-svgrepo-com.svg";
import broken_heart from "../../assets/icons/broken-heart-svgrepo-com.svg";
import brain from "../../assets/icons/brain-svgrepo-com.svg";
import brain_stroke from "../../assets/icons/brain-stroke-svgrepo-com.svg";
import scale from "../../assets/icons/scale-svgrepo-com.svg";
import skin from "../../assets/icons/face-svgrepo-com.svg";
import height from "../../assets/icons/height-svgrepo-com.svg";
import people from "../../assets/icons/group-of-people-cartoon-variant-svgrepo-com.svg";
import person from "../../assets/icons/running-person-svgrepo-com.svg";
//import PopupModal from "../PopupModals/PopupModals";
import { setColor } from "../../controll";
import Loader from "../Loaderv2";
import { userType } from "../../Types/user";

import { fetchGetUser } from "../../API/memberAPI";

import "./Scan.css";

const ScanList: React.FC = () => {
  const [scanData, setScanData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [partnerID, setPartnerID] = React.useState("");

  const getMearurementData = () => {
    var url = window.location.href;
    const id = url.split("/").pop();

    axios
      .post(
        `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/DFX-GetMeasurementData`,
        { id: id }
      )
      .then((res) => {
        setScanData(res.data);
        setLoading(false);
        setPartnerID(res.data.PartnerID);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log("partnerID", partnerID);
  const [userData, setUserData] = useState<userType[]>([]);

  const getUserData = useCallback(async () => {
    const userData = await fetchGetUser(partnerID);
    setUserData(userData);
  }, [partnerID]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const renderValue = (data: string, decimalDigits: number) => {
    Math.pow(10, decimalDigits);
    return (
      Math.round(
        (scanData["Results"][data][0]["Data"][0] /
          scanData["Results"][data][0]["Multiplier"]) *
          Math.pow(10, decimalDigits)
      ) / Math.pow(10, decimalDigits)
    );
  };

  const setResultsClean = () => {
    let results: any = {};

    results["HR_BPM"] =
      Math.round(
        (scanData["Results"]["HR_BPM"][0]["Data"][0] /
          scanData["Results"]["HR_BPM"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["IHB_COUNT"] =
      Math.round(
        (scanData["Results"]["IHB_COUNT"][0]["Data"][0] /
          scanData["Results"]["IHB_COUNT"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["BR_BPM"] =
      Math.round(
        (scanData["Results"]["BR_BPM"][0]["Data"][0] /
          scanData["Results"]["BR_BPM"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["BP_SYSTOLIC"] =
      Math.round(
        (scanData["Results"]["BP_SYSTOLIC"][0]["Data"][0] /
          scanData["Results"]["BP_SYSTOLIC"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["BP_DIASTOLIC"] =
      Math.round(
        (scanData["Results"]["BP_DIASTOLIC"][0]["Data"][0] /
          scanData["Results"]["BP_DIASTOLIC"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["HRV_SDNN"] =
      Math.round(
        (scanData["Results"]["HRV_SDNN"][0]["Data"][0] /
          scanData["Results"]["HRV_SDNN"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["BP_RPP"] =
      Math.round(
        (scanData["Results"]["BP_RPP"][0]["Data"][0] /
          scanData["Results"]["BP_RPP"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["MENTAL_STRESS_INDEX"] =
      Math.round(
        (scanData["Results"]["MENTAL_STRESS_INDEX"][0]["Data"][0] /
          scanData["Results"]["MENTAL_STRESS_INDEX"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["BMI_CALC"] =
      Math.round(
        (scanData["Results"]["BMI_CALC"][0]["Data"][0] /
          scanData["Results"]["BMI_CALC"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["WAIST_TO_HEIGHT"] =
      Math.round(
        (scanData["Results"]["WAIST_TO_HEIGHT"][0]["Data"][0] /
          scanData["Results"]["WAIST_TO_HEIGHT"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["ABSI"] =
      Math.round(
        (scanData["Results"]["ABSI"][0]["Data"][0] /
          scanData["Results"]["ABSI"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["BP_CVD"] =
      Math.round(
        (scanData["Results"]["BP_CVD"][0]["Data"][0] /
          scanData["Results"]["BP_CVD"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["BP_HEART_ATTACK"] =
      Math.round(
        (scanData["Results"]["BP_HEART_ATTACK"][0]["Data"][0] /
          scanData["Results"]["BP_HEART_ATTACK"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    results["BP_STROKE"] =
      Math.round(
        (scanData["Results"]["BP_STROKE"][0]["Data"][0] /
          scanData["Results"]["BP_STROKE"][0]["Multiplier"]) *
          Math.pow(10, 2)
      ) / Math.pow(10, 2);

    return results;
  };

  const renderResultBox = (data: string, decimalDigits: number) => {
    return (
      <div id={data} className="result-box">
        {renderValue(data, decimalDigits)}
      </div>
    );
  };

  useEffect(() => {
    var url = window.location.href;
    const id = url.split("/").pop();
    if (id === "no-scan") {
    } else {
      getMearurementData();
    }
  }, []);

  useEffect(() => {
    if (loading === false) {
      setColor(setResultsClean());
    }
  }, [loading]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>
              {" "}
              {userData.map((user) => (
                <div key={user.id}>
                  {user.firstName && user.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : "No name"}
                </div>
              ))}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        {loading ? (
          <Loader />
        ) : (
          <>
            <IonCard className="results-card card-center ion-text-center ion-padding-bottom">
              <IonIcon
                icon={person}
                size="large"
                className="ion-padding"
              ></IonIcon>
              <h2>Overall Score: {renderValue("HEALTH_SCORE", 0)}</h2>

              {/*<IonGrid><IonRow className='justify-content: center'><IonCol><IonButton onClick={()=>openEmail('Aaron')}>Share</IonButton></IonCol></IonRow></IonGrid>*/}
            </IonCard>

            <IonCard>
              <IonAccordionGroup multiple value="vitals">
                {/*-- VITALS --*/}
                <IonAccordion value="vitals" toggleIcon={arrowDownCircle}>
                  <IonItem slot="header">
                    <IonLabel>Vitals</IonLabel>
                  </IonItem>

                  <IonList slot="content">
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={heartOutline} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">HEART RATE</p>
                            <p className="info-p">
                              60-100 bpm <a id="trigger-ppm">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("HR_BPM", 0)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={heartCircle} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">IRREGULAR HEARTBEATS</p>
                            <p className="info-p">
                              <a id="trigger-irregularHeartBeats">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("IHB_COUNT", 0)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={lungs} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">BREATHING</p>
                            <p className="info-p">
                              12-25 brpm&nbsp;
                              <a id="trigger-breathing">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("BR_BPM", 0)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={stethoscope} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">BLOOD PREASURE</p>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2"></IonCol>
                          <IonCol size="7">
                            <p className="bp-sub">SYSTOLIC BLOOD PRESSURE</p>
                            <p className="info-p">
                              120-129 mmHg&nbsp;
                              <a id="trigger-systolic">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("BP_SYSTOLIC", 0)}
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2"></IonCol>
                          <IonCol size="7">
                            <p className="bp-sub">DIASTOLIC BLOOD PRESSURE</p>
                            <p className="info-p">
                              80-84 mmHg&nbsp;
                              <a id="trigger-diastolic">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("BP_DIASTOLIC", 0)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  </IonList>
                </IonAccordion>

                {/*-- PHYSIOLOGIICAL --*/}
                <IonAccordion
                  value="physiological"
                  toggleIcon={arrowDownCircle}
                >
                  <IonItem slot="header">
                    <IonLabel>Physiological</IonLabel>
                  </IonItem>

                  <IonList slot="content">
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={pulseOutline} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">HEART RATE VARIABILITY</p>
                            <p className="info-p">
                              37.9-54 ms&nbsp;
                              <a id="trigger-heartRateVariability">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("HRV_SDNN", 0)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={workload} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">CARDIAC WORKLOAD</p>
                            <p className="info-p">
                              dB&nbsp;
                              <a id="trigger-cardiacWorkload">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("BP_RPP", 0)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  </IonList>
                </IonAccordion>
                {/*-- MENTAL --*/}
                <IonAccordion value="mental" toggleIcon={arrowDownCircle}>
                  <IonItem slot="header">
                    <IonLabel>Mental</IonLabel>
                  </IonItem>

                  <IonList slot="content">
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={brain} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">STRESS INDEX</p>
                            <p className="info-p">
                              <a id="trigger-msi">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("MENTAL_STRESS_INDEX", 0)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  </IonList>
                </IonAccordion>
                {/*-- PHYSICAL --*/}
                <IonAccordion value="physical" toggleIcon={arrowDownCircle}>
                  <IonItem slot="header">
                    <IonLabel>Physical</IonLabel>
                  </IonItem>

                  <IonList slot="content">
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={scale} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">BODY MASS INDEX</p>
                            <p className="info-p">
                              18.5-24.9 kg/m<sup>2</sup>&nbsp;
                              <a id="trigger-bmi">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("BMI_CALC", 0)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={skin} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">FACIAL SKIN AGE</p>
                            <p className="info-p">
                              <a id="trigger-age">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">{renderResultBox("AGE", 0)}</IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={height} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">WAIST-TO-HEIGHT RATIO</p>
                            <p className="info-p">
                              &plusmn;4% of result&nbsp;
                              <a id="trigger-waistToHeight">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("WAIST_TO_HEIGHT", 0)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={people} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">BODY SHAPE INDEX</p>
                            <p className="info-p">
                              <a id="trigger-absi">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("ABSI", 0)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  </IonList>
                </IonAccordion>
                {/*-- GENERAL RISK --*/}
                <IonAccordion value="general_risk" toggleIcon={arrowDownCircle}>
                  <IonItem slot="header">
                    <IonLabel>General Risk</IonLabel>
                  </IonItem>

                  <IonList slot="content">
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={heartDislikeOutline} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">
                              CARDIOVASCULAR DISEASE RISK
                            </p>
                            <p className="info-p">
                              <sup>2</sup>&nbsp;
                              <a id="trigger-cvdRisk">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("BP_CVD", 2)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={broken_heart} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">HEART ATTACK RISK</p>
                            <p className="info-p">
                              <a id="trigger-heartAttackRisk">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("BP_HEART_ATTACK", 2)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="2">
                            <IonIcon icon={brain_stroke} size="large" />
                          </IonCol>
                          <IonCol size="7">
                            <p className="title-p">STROKE RISK</p>
                            <p className="info-p">
                              &plusmn;4% of result&nbsp;
                              <a id="trigger-strokeRisk">more info</a>
                            </p>
                          </IonCol>
                          <IonCol size="3 ">
                            {renderResultBox("BP_STROKE", 2)}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  </IonList>
                </IonAccordion>
              </IonAccordionGroup>
            </IonCard>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ScanList;
