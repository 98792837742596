// api/members.js
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  limit,
  doc,
  updateDoc,
} from "firebase/firestore";
import { NewNotificationType } from "../Types/notification";
import { trackingTYPES } from "../Types/tracking";
import { userType } from "../Types/user";
import moment from "moment";
import { CoachNoteType } from "../Types/coachNotes";

export async function fetchMemberNotifications(memberUid: string) {
  const q = query(
    collection(getFirestore(), "notifications"),
    where("memberUid", "==", memberUid),
    limit(15)
  );
  const querySnapshot = await getDocs(q);
  const notificationData = querySnapshot.docs.map(
    (doc) => ({ id: doc.id, ...doc.data() } as NewNotificationType)
  );
  const sortedNotifications = notificationData.sort((a, b) => {
    if (!a.timeStamp || !b.timeStamp) {
      return null;
    }
    return moment(b.timeStamp.toDate()).diff(moment(a.timeStamp.toDate()));
  });
  return sortedNotifications;
}

export async function fetchMemberTracking(memberUid: string) {
  const q = query(
    collection(getFirestore(), "tracking"),
    where("uid", "==", memberUid)
  );
  const querySnapshot = await getDocs(q);
  const trackingData = querySnapshot.docs.map((doc) => {
    const data = doc.data() as trackingTYPES;
    if (!data.createdDate) {
      console.error(`No createdDate found for item ${doc.id}`);
    }
    return { id: doc.id, ...data };
  });
  return trackingData;
}

export async function fetchGetUser(memberUid: string) {
  const q = query(
    collection(getFirestore(), "users"),
    where("uid", "==", memberUid)
  );
  const querySnapshot = await getDocs(q);
  const getUserData = querySnapshot.docs.map((doc) => {
    const data = doc.data() as userType;
    return { id: doc.id, ...data };
  });
  return getUserData;
}

export async function fetchMemberCompliantNote(memberUid: string) {
  const q = query(
    collection(getFirestore(), "coach_notes"),
    where("uid", "==", memberUid)
  );
  const querySnapshot = await getDocs(q);
  const compliantNoteData = querySnapshot.docs.map((doc) => {
    const data = doc.data() as CoachNoteType;
    if (!data.date) {
      console.error(`No createdDate found for item ${doc.id}`);
    }
    return { id: doc.id, ...data };
  });
  return compliantNoteData;
}



export async function fetchUpdateUser(memberUid: string, userData: Partial<userType>) {
  const userRef = doc(getFirestore(), "users", memberUid);
  await updateDoc(userRef, userData);
}

export async function fetchGetAllUsersMetric() {
  const q = query(collection(getFirestore(), "users"));
  const querySnapshot = await getDocs(q);
  const getUserData = querySnapshot.docs.map((doc) => {
    const data = doc.data() as userType;
    return { id: doc.id, ...data };
  });
  return { totalUsers: getUserData.length, users: getUserData };
}

export async function fetchGetAllActiveUsersMetric() {
  const q = query(
    collection(getFirestore(), "users"),
    where("active", "==", true)
  );

  const querySnapshot = await getDocs(q);
  const getActiveUserData = querySnapshot.docs.map((doc) => {
    const data = doc.data() as userType;
    return { id: doc.id, ...data };
  });
  return {
    totalActiveUsers: getActiveUserData.length,
    users: getActiveUserData,
  };
}

export async function fetchMonthlyCompliantUsers() {
  const q = query(
    collection(getFirestore(), "users"),
    where("compliant", "==", true)
  );

  const querySnapshot = await getDocs(q);
  const getCompliantUsers = querySnapshot.docs.map((doc) => {
    const data = doc.data() as userType;
    return { id: doc.id, ...data };
  });
  return {
    totalMonthlyCompliant: getCompliantUsers.length,
    users: getCompliantUsers,
  };
}

export async function fetchCompletedHRA() {
  const q = query(
    collection(getFirestore(), "users"),
    where("hraComplete", "==", true)
  );

  const querySnapshot = await getDocs(q);
  const getCompletedHRA = querySnapshot.docs.map((doc) => {
    const data = doc.data() as userType;
    return { id: doc.id, ...data };
  });
  return {
    totalCompletedHRA: getCompletedHRA.length,
    users: getCompletedHRA,
  };
}



