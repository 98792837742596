import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useUserData } from "../../lib/hooks";
import "./Add.css";
//import spinner
import React from "react";
import AddCoach from "../../components/Add/AddCoach";
import AddGroup from "../../components/Add/AddGroup";
import AddGroupCSV from "../../components/Add/AddGroupCSV";
import AddIndividualMember from "../../components/Add/AddIndividualMember";

const Add: React.FC = () => {
  const userData = useUserData();

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Add</IonTitle>
          </IonToolbar>
        </IonHeader>

        <AddCoach />

        <AddGroup />

        <AddGroupCSV />

        <AddIndividualMember />
      </IonContent>
    </IonPage>
  );
};

export default Add;
