import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  query,
  collection,
  getFirestore,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";
import React, { useContext } from "react";
import { UserContext } from "../../lib/context";
import { CSVLink, CSVDownload } from "react-csv";
import "./ComplianceReport.css";
import { starSharp } from "ionicons/icons";

export default function ComplianceReport() {
  const { user, profile } = useContext(UserContext);

  const [reportOf, setReportOf] = React.useState<string>();
  const [reportYear, setReportYear] = React.useState<string>();
  const [reportMonth, setReportMonth] = React.useState<string>();
  const [groups, setGroups] = React.useState([]);
  const [groupId, setGroupId] = React.useState<string>();
  const [csvData, setCsvData] = React.useState([]);

  const fetchGroups = async () => {
    const q = query(
      collection(getFirestore(), "groups"),
      where("active", "==", true),
      orderBy("group", "asc")
    );

    var groupsData = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      groupsData.push(doc.data());
    });

    setGroups(groupsData);
  };

  const createReportAll = async () => {
    //get todays month and year and see if it matches up
    const today = new Date();

    var month = today.toLocaleString("default", { month: "long" });
    var year = today.getFullYear();

    var todayDateString = month + " " + year;
    var reportDateString = reportMonth + " " + reportYear;

    var members = [];

    // console.log("dateString:", todayDateString);
    // console.log("reportDateString:", reportDateString);

    // chosen report date is the same as current month and year. Can just look directly at the "compliant" field on user document
    if (todayDateString === reportDateString) {
      // console.log("Chosen month is dis month");
      const q = query(
        collection(getFirestore(), "users"),
        where("active", "==", true),
        where("role", "==", "member")
      );
      //

      members = [];

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {

        var daysUncompliant;

        if (doc.data().markedUncompliantDate) {
          var date = doc.data().markedUncompliantDate.toDate();
          var diff = Math.abs(today.getTime() - date.getTime());
          var days = Math.ceil(diff / (1000 * 60 * 60 * 24));
          daysUncompliant = days;
        }

        else {
          daysUncompliant = "N/A";
        }

        members.push({
          Date: reportDateString,
          "External ID": doc.data().externalID,
          "First Name": doc.data().firstName,
          "Dependent": doc.data().dependent ? "Yes" : "",
          "Last Name": doc.data().lastName,
          Email: doc.data().email,
          "Group Name": doc.data().groupName,
          "Group ID": doc.data().groupID,
          Compliant: doc.data().compliant,
          hraComplete: doc.data().hraComplete,
          compliantSetDate: doc.data().compliantSetDate || null,
          compliantSetDateString: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().toLocaleString("default", { month: "long" }) + " " + doc.data().compliantSetDate.toDate().getDate() : null,
          beforeThe20th: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().getDate() <= 20 ? "true" : "false" : null,
          daysUncompliant: daysUncompliant,
        });
      });

      setCsvData(members);
    }
    // chosen report date is not currect month and year
    else {
      //console.log("Chosen report date is NOT same as this months date.");
      const q = query(
        collection(getFirestore(), "users"),
        where("active", "==", true),
        where("role", "==", "member")
      );
      //

      members = [];

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {


        var daysUncompliant;

        if (doc.data().markedUncompliantDate) {
          var date = doc.data().markedUncompliantDate.toDate();
          var diff = Math.abs(today.getTime() - date.getTime());
          var days = Math.ceil(diff / (1000 * 60 * 60 * 24));
          daysUncompliant = days;
        }

        else {
          daysUncompliant = "N/A";
        }

        doc.data().complianceHistory.forEach((compliance) => {
          if (reportDateString in compliance) {
            var compliant = compliance[reportDateString];
            members.push({
              Date: reportDateString,
              "External ID": doc.data().externalID,
              "First Name": doc.data().firstName,
              "Last Name": doc.data().lastName,
              "Dependent": doc.data().dependent ? "Yes" : "",
              Email: doc.data().email,
              "Group Name": doc.data().groupName,
              "Group ID": doc.data().groupID,
              Compliant: compliant,
              hraComplete: doc.data().hraComplete,
              compliantSetDate: doc.data().compliantSetDate || null,
              compliantSetDateString: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().toLocaleString("default", { month: "long" }) + " " + doc.data().compliantSetDate.toDate().getDate() : null,
              beforeThe20th: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().getDate() <= 20 ? "true" : "false" : null,
              daysUncompliant: daysUncompliant,
            });
          }
        });
      });

      setCsvData(members);
    }
  };

  const createReportGroup = async () => {
    //get todays month and year and see if it matches up
    const today = new Date();

    var month = today.toLocaleString("default", { month: "long" });
    var year = today.getFullYear();
    var gid = groupId.split("%")[0];

    var todayDateString = month + " " + year;
    var reportDateString = reportMonth + " " + reportYear;

    var members = [];

    // console.log("dateString:", todayDateString);
    // console.log("reportDateString:", reportDateString);

    // chosen report date is the same as current month and year. Can just look directly at the "compliant" field on user document
    if (todayDateString === reportDateString) {
      // console.log("Chosen month is dis month");
      const q = query(
        collection(getFirestore(), "users"),
        where("active", "==", true),
        where("role", "==", "member"),
        where("groupID", "==", gid)
      );
      //

      members = [];

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {

        var daysUncompliant;

        if (doc.data().markedUncompliantDate) {
          var date = doc.data().markedUncompliantDate.toDate();
          var diff = Math.abs(today.getTime() - date.getTime());
          var days = Math.ceil(diff / (1000 * 60 * 60 * 24));
          daysUncompliant = days;
        }

        else {
          daysUncompliant = "N/A";
        }

        members.push({
          Date: reportDateString,
          "External ID": doc.data().externalID,
          "First Name": doc.data().firstName,
          "Last Name": doc.data().lastName,
          "Dependent": doc.data().dependent ? "Yes" : "",
          Email: doc.data().email,
          "Group Name": doc.data().groupName,
          "Group ID": doc.data().groupID,
          Compliant: doc.data().compliant,
          hraComplete: doc.data().hraComplete,
          compliantSetDate: doc.data().compliantSetDate || null,
          compliantSetDateString: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().toLocaleString("default", { month: "long" }) + " " + doc.data().compliantSetDate.toDate().getDate() : null,
          beforeThe20th: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().getDate() <= 20 ? "true" : "false" : null,
          daysUncompliant: daysUncompliant,
        });
      });

      setCsvData(members);
    }
    // chosen report date is not currect month and year
    else {
      //console.log("Chosen report date is NOT same as this months date.");
      const q = query(
        collection(getFirestore(), "users"),
        where("active", "==", true),
        where("role", "==", "member"),
        where("groupID", "==", gid)
      );
      //

      members = [];

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {

        var daysUncompliant;

        if (doc.data().markedUncompliantDate) {
          var date = doc.data().markedUncompliantDate.toDate();
          var diff = Math.abs(today.getTime() - date.getTime());
          var days = Math.ceil(diff / (1000 * 60 * 60 * 24));
          daysUncompliant = days;
        }

        else {
          daysUncompliant = "N/A";
        }

        doc.data().complianceHistory.forEach((compliance) => {
          if (reportDateString in compliance) {
            var compliant = compliance[reportDateString];
            members.push({
              Date: reportDateString,
              "External ID": doc.data().externalID,
              "First Name": doc.data().firstName,
              "Dependent": doc.data().dependent ? "Yes" : "",
              "Last Name": doc.data().lastName,
              Email: doc.data().email,
              "Group Name": doc.data().groupName,
              "Group ID": doc.data().groupID,
              Compliant: compliant,
              hraComplete: doc.data().hraComplete,
              compliantSetDate: doc.data().compliantSetDate || null,
              compliantSetDateString: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().toLocaleString("default", { month: "long" }) + " " + doc.data().compliantSetDate.toDate().getDate() : null,
              beforeThe20th: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().getDate() <= 20 ? "true" : "false" : null,
              daysUncompliant: daysUncompliant,
            });
          }
        });
      });

      setCsvData(members);
    }
  };

  // const getEveryoneReport = async () => {
  //   const q = query(collection(getFirestore(), "users"));

  //   var members = [];

  //   members = [];

  //   const querySnapshot = await getDocs(q);

  //   querySnapshot.forEach((doc) => {
  //     members.push(doc.data());
  //   });

  //   setCsvData(members);
  // };

  const createReportMyMembers = async () => {
    //console.log("createReportMyMembers", user.uid, reportYear, reportMonth);

    //get todays month and year and see if it matches up
    const today = new Date();

    var month = today.toLocaleString("default", { month: "long" });
    var year = today.getFullYear();

    var todayDateString = month + " " + year;
    var reportDateString = reportMonth + " " + reportYear;

    var members = [];

    // console.log("dateString:", todayDateString);
    // console.log("reportDateString:", reportDateString);

    // chosen report date is the same as current month and year. Can just look directly at the "compliant" field on user document
    if (todayDateString === reportDateString) {
      // console.log("Chosen month is dis month");
      const q = query(
        collection(getFirestore(), "users"),
        where("coach", "==", user.uid),
        where("active", "==", true)
      );
      //

      members = [];

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {

        var daysUncompliant;

        if (doc.data().markedUncompliantDate) {
          var date = doc.data().markedUncompliantDate.toDate();
          var diff = Math.abs(today.getTime() - date.getTime());
          var days = Math.ceil(diff / (1000 * 60 * 60 * 24));
          daysUncompliant = days;
        }

        else {
          daysUncompliant = "N/A";
        }

        members.push({
          Date: reportDateString,
          "External ID": doc.data().externalID,
          "First Name": doc.data().firstName,
          "Last Name": doc.data().lastName,
          "Dependent": doc.data().dependent ? "Yes" : "",
          Email: doc.data().email,
          "Group Name": doc.data().groupName,
          "Group ID": doc.data().groupID,
          Compliant: doc.data().compliant,
          hraComplete: doc.data().hraComplete,
          compliantSetDate: doc.data().compliantSetDate || null,
          compliantSetDateString: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().toLocaleString("default", { month: "long" }) + " " + doc.data().compliantSetDate.toDate().getDate() : null,
          beforeThe20th: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().getDate() <= 20 ? "true" : "false" : null,
          daysUncompliant: daysUncompliant,
        });
      });

      setCsvData(members);
    }
    // chosen report date is not currect month and year
    else {
      //console.log("Chosen report date is NOT same as this months date.");
      const q = query(
        collection(getFirestore(), "users"),
        where("coach", "==", user.uid),
        where("active", "==", true)
      );
      //

      members = [];

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {

        var daysUncompliant;

        if (doc.data().markedUncompliantDate) {
          var date = doc.data().markedUncompliantDate.toDate();
          var diff = Math.abs(today.getTime() - date.getTime());
          var days = Math.ceil(diff / (1000 * 60 * 60 * 24));
          daysUncompliant = days;
        }

        else {
          daysUncompliant = "N/A";
        }

        doc.data().complianceHistory.forEach((compliance) => {
          if (reportDateString in compliance) {
            var compliant = compliance[reportDateString];
            members.push({
              Date: reportDateString,
              "External ID": doc.data().externalID,
              "First Name": doc.data().firstName,
              "Last Name": doc.data().lastName,
              "Dependent": doc.data().dependent ? "Yes" : "",
              Email: doc.data().email,
              "Group Name": doc.data().groupName,
              "Group ID": doc.data().groupID,
              Compliant: compliant,
              hraComplete: doc.data().hraComplete,
              compliantSetDate: doc.data().compliantSetDate || null,
              compliantSetDateString: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().toLocaleString("default", { month: "long" }) + " " + doc.data().compliantSetDate.toDate().getDate() : null,
              beforeThe20th: doc.data().compliantSetDate ? doc.data().compliantSetDate.toDate().getDate() <= 20 ? "true" : "false" : null,
              daysUncompliant: daysUncompliant,
            });
          }
        });
      });

      setCsvData(members);
    }
  };

  const generateReport = () => {
    if (reportOf && reportYear && reportMonth) {
      if (reportOf === "group") {
        if (groupId) {
          createReportGroup();
        } else {
          alert("Please select a group");
        }
      } else if (reportOf === "all") {
        createReportAll();
      } else if (reportOf === "my-members") {
        createReportMyMembers();
      }
    } else {
      alert("Please select all the fields");
    }
  };

  const listGroups = groups.map((group) => (
    <IonSelectOption
      key={group.groupID}
      value={group.groupID + "%" + group.group}
    >
      {group.group}
    </IonSelectOption>
  ));

  function test() {
    console.log(csvData);
  }

  const resetReport = () => {
    setCsvData([]);
  };

  function RenderCompliance({ compliant, compliantSetDate }) {
    if (compliant) {
      if(compliantSetDate === null) {
        return <IonLabel color="success">Compliant</IonLabel>;
      }
      else{
        const date = compliantSetDate.toDate();
        // check to see if before 20th of month
        if(date.getDate() < 20) {
        return <IonLabel color="success">Compliant</IonLabel>;
        }
        else{
          return <IonLabel color="success">Compliant<IonIcon icon={starSharp}></IonIcon></IonLabel>;
        }
      }
    } else {
      return <IonLabel color="danger">Non-Compliant</IonLabel>;
    }
  }

  function RenderHraComplete({ hraComplete }) {
    if (hraComplete) {
      return <IonLabel color="success">Complete</IonLabel>;
    } else {
      return <IonLabel color="danger">Not Complete</IonLabel>;
    }
  }

  const RenderDataOnWeb = () => {
    let members = [];
    csvData.forEach((member, index) => {
      console.log("HEHAW", member);
      members.push(
        <IonItem key={member.Email}>
          <IonLabel className="ion-text-wrap">{member.Date}</IonLabel>
          <IonLabel className="ion-text-wrap">
            {member["First Name"] + " " + member["Last Name"]} {member["Dependent"] === "Yes" ? "(Dependent)" : ""}
          </IonLabel>
          <IonLabel className="ion-text-wrap">
            <RenderCompliance compliant={member.Compliant} compliantSetDate={member.compliantSetDate || null}/>
          </IonLabel >
          <IonLabel className="ion-text-wrap">{member["External ID"]}</IonLabel>

          <IonLabel className="ion-text-wrap">{member.Email}</IonLabel>
          <IonLabel className="ion-text-wrap">{member["Group Name"]}</IonLabel>
          <IonLabel className="ion-text-wrap">{member["Group ID"]}</IonLabel>
          <IonLabel className="ion-text-wrap">
            <RenderHraComplete hraComplete={member.hraComplete} />
          </IonLabel>
        </IonItem>
      );
    });

    if (members.length == 0) {
      return <></>;
    }

    return (
      <>
        {" "}
        <IonItem>
          <IonLabel>
            <b>Date</b>
          </IonLabel>
          <IonLabel>
            <b>Name</b>
          </IonLabel>
          <IonLabel>
            <b>Compliant</b>
          </IonLabel>
          <IonLabel>
            <b>External ID</b>
          </IonLabel>
          <IonLabel>
            <b>Email</b>
          </IonLabel>
          <IonLabel>
            <b>Group</b>
          </IonLabel>
          <IonLabel>
            <b>Group ID</b>
          </IonLabel>
          <IonLabel>
            <b>HRA Complete</b>
          </IonLabel>
        </IonItem>
        {members}
      </>
    );
  };
  const getAllMembersInSystem = async () => {
    var members = [];

    // get all docs from users
    const q = query(collection(getFirestore(), "users"));

    members = [];

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      members.push({ ...doc.data() });
    });

    setCsvData(members);
  };
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Compliance Report</IonCardTitle>
        {/* <IonButton onClick={() => getAllMembersInSystem()}>TestMe</IonButton> */}
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">
                  Which would you like a report of?
                </IonLabel>
                <IonSelect
                  onIonChange={(e) => {
                    setReportOf(e.detail.value);
                  }}
                >
                  <IonSelectOption value="my-members">
                    My Members
                  </IonSelectOption>
                  <IonSelectOption value="group">Group </IonSelectOption>
                  <IonSelectOption value="all">
                    All Active Members in System
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          {reportOf === "group" && (
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="stacked">Group</IonLabel>
                  <IonSelect
                    onFocus={(e) => fetchGroups()}
                    cancelText="Cancel"
                    okText="Okay"
                    onIonChange={(e) => {
                      setGroupId(e.detail.value);
                    }}
                  >
                    {listGroups}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">Year</IonLabel>
                <IonSelect onIonChange={(e) => setReportYear(e.detail.value)}>
                  <IonSelectOption value="2024">2024</IonSelectOption>
                  <IonSelectOption value="2023">2023</IonSelectOption>
                  <IonSelectOption value="2022">2022</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">Month</IonLabel>
                <IonSelect onIonChange={(e) => setReportMonth(e.detail.value)}>
                  <IonSelectOption value="January">January</IonSelectOption>
                  <IonSelectOption value="February">February</IonSelectOption>
                  <IonSelectOption value="March">March</IonSelectOption>
                  <IonSelectOption value="April">April</IonSelectOption>
                  <IonSelectOption value="May">May</IonSelectOption>
                  <IonSelectOption value="June">June</IonSelectOption>
                  <IonSelectOption value="July">July</IonSelectOption>
                  <IonSelectOption value="August">August</IonSelectOption>
                  <IonSelectOption value="September">September</IonSelectOption>
                  <IonSelectOption value="October">October</IonSelectOption>
                  <IonSelectOption value="November">November</IonSelectOption>
                  <IonSelectOption value="December">December</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        {/* {reportOf +" " +reportYear +' ' + reportMonth + " " + groupId } */}

        <br></br>
        {csvData.length === 0 ? (
          <IonButton onClick={generateReport}>Generate</IonButton>
        ) : (
          <>
            <CSVLink
              data={csvData}
              className="downloadButton"
              filename={"complianceReport.csv"}
            >
              Download Report Here
            </CSVLink>{" "}
            <br></br>
            <IonButton onClick={resetReport}>New Report</IonButton>
          </>
        )}
      </IonCardContent>
      <RenderDataOnWeb />
      {/* <IonButton onClick={() => getEveryoneReport()}>Get Full Report</IonButton> */}
    </IonCard>
  );
}
