import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import {
  query,
  collection,
  getFirestore,
  where,
  onSnapshot,
} from "@firebase/firestore";
import "./ThisWeekSessions.css";
import { UpcomingSessionsTypes } from "../../Types/upcommingsessions";
import { UserContext } from "../../lib/context";

function ThisWeekSessions() {
  const { user } = useContext(UserContext);
  const [sessions, setSessions] = useState<UpcomingSessionsTypes[]>([]); // state to store upcoming sessions
  const history = useHistory();

  // function to handle when a session is clicked
  const handleSessionClick = (session: UpcomingSessionsTypes) => {
    const route = "/session/" + session.id;
    window.location.href = route;
    history.push(route);
  };

  // function to handle when a member is clicked
  const handleMemberClick = (session: UpcomingSessionsTypes) => {
    const route = "/member-dash/" + session.memberUid;
    window.location.href = route;
    history.push(route);
  };

  useEffect(() => {
    // listen to changes in the sessions collection in Firestore for the current coach
    if (user) {
      const q = query(
        collection(getFirestore(), "sessions"),
        where("coachUid", "==", user.uid)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setSessions(
          snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          })
        );
      });

      return unsubscribe;
    }
  }, [user]);

  // generate an array of dates for the next 7 days
  const dates = [];
  const today = new Date();
  for (let i = 0; i < 7; i++) {
    const date = new Date(today.getTime());
    date.setDate(today.getDate() + i);
    dates.push(date);
  }

  // sort the sessions by start time
  const sortedSessions = sessions.sort((a, b) => {
    if (!a.startTimeTimestamp || !b.startTimeTimestamp) {
      return null;
    }
    return (
      a.startTimeTimestamp.toDate().getTime() -
      b.startTimeTimestamp.toDate().getTime()
    );
  });

  return (
    <div>
      <IonRow>
        {dates.map((date) => (
          <IonCol key={date.getTime()} size="12/7">
            <IonToolbar>
              {/* display the day of the week */}
              <div>{date.toLocaleDateString("en-US", { weekday: "long" })}</div>
              {/* display the month and date */}
              <div>
                {date.toLocaleDateString("en-US", { month: "numeric" })}/
                {date.toLocaleDateString("en-US", { day: "numeric" })}
              </div>
            </IonToolbar>
            {sortedSessions
              .filter((session) => {
                // filter the sessions for this date
                const sessionDate = session.startTimeTimestamp?.toDate();
                return (
                  session.startTimeTimestamp &&
                  sessionDate.getFullYear() === date.getFullYear() &&
                  sessionDate.getMonth() === date.getMonth() &&
                  sessionDate.getDate() === date.getDate()
                );
              })
              .map((session) => (
                // display each session as a card
                <IonCard
                  key={session.id}
                  className={`thisweek-sessions-card ${
                    session.location.includes("calendly")
                      ? "microsoft-teams"
                      : session.location.includes("Allied Wellness App")
                      ? "allied-wellness-app"
                      : ""
                  }`}
                >
                  <IonCardSubtitle color="primary">
                    {/* display the start time */}
                    {session.startTimeTimestamp
                      .toDate()
                      .toLocaleTimeString([], {
                        hour: "numeric",
                        minute: "2-digit",
                      })}
                  </IonCardSubtitle>
                  <IonCardTitle
                    className="thisweek-session-cardtitle"
                    onClick={() => {
                      handleSessionClick(session);
                    }}
                  >
                    {/* display the location */}
                    {session.location.includes("calendly") && <div>Teams</div>}
                    {session.location.includes("Allied Wellness App") && (
                      <div>AW App</div>
                    )}
                    {!session.location.includes("calendly") &&
                      !session.location.includes("Allied Wellness App") && (
                        <div>Phone</div>
                      )}
                  </IonCardTitle>

                  <div
                    className="thisweek-session-membername"
                    onClick={() => handleMemberClick(session)}
                  >
                    {/* display the member name */}
                    {session.memberName}
                  </div>
                  {/* display the session status if it is cancelled */}
                  {session.status && (
                    <div className="thisweek-session-cancelled">
                      {session.status.toUpperCase()}
                    </div>
                  )}
                </IonCard>
              ))}
          </IonCol>
        ))}
      </IonRow>
    </div>
  );
}

export default ThisWeekSessions;
