import { doc, getFirestore, updateDoc } from "@firebase/firestore";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonCheckbox,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonContent,
  IonInput,
} from "@ionic/react";
import { useState } from "react";

type Props = {
  member: any;
};

const MemberHra: React.FC<Props> = ({ member }) => {
  const [showHRAModal, setShowHRAModal] = useState(false);
  const [getBetterSleep, setGetBetterSleep] = useState(false);
  const [eatBetter, setEatBetter] = useState(false);
  const [quitSmoking, setQuitSmoking] = useState(false);
  const [manageStress, setManageStress] = useState(false);
  const [increaseActivity, setIncreaseActivity] = useState(false);
  const [improveMentalHealth, setImproveMentalHealth] = useState(false);
  const [loseWeight, setLoseWeight] = useState(false);
  const [activitySatisfaction, setActivitySatisfaction] = useState("");
  const [WellnessFeelingDown, setWellnessFeelingDown] = useState("");
  const [dietSatisfaction, setDietSatisfaction] = useState("");
  const [sleepSatisfaction, setSleepSatisfaction] = useState("");
  const [stressLevelSatisfaction, setStressLevelSatisfaction] = useState("");
  const [anxiety, setAnxiety] = useState(false);
  const [arrhythmia, setArrhythmia] = useState(false);
  const [asthma, setAsthma] = useState(false);
  const [backpain, setBackpain] = useState(false);
  const [congestiveHeartFailure, setCongestiveHeartFailure] = useState(false);
  const [copd, setCopd] = useState(false);
  const [depression, setDepression] = useState(false);
  const [diabetes, setDiabetes] = useState("");
  const [hypertension, setHypertension] = useState(false);
  const [smoker, setSmoker] = useState(false);
  const [bloodPressureMeds, setBloodPressureMeds] = useState(false);
  const [highCholesterol, setHighCholesterol] = useState(false);
  const [gender, setGender] = useState("");
  const [heightFt, setHeightFt] = useState("");
  const [heightIn, setHeightIn] = useState("");
  const [weight, setWeight] = useState("");

  const handleHRA = async (e: any) => {
    e.preventDefault();

    // make sure all values are not blank
    if (
      activitySatisfaction === "" ||
      dietSatisfaction === "" ||
      sleepSatisfaction === "" ||
      stressLevelSatisfaction === "" ||
      gender === "" ||
      heightFt === "" ||
      heightIn === "" ||
      weight === "" ||
      diabetes === ""
    ) {
      alert("Please fill out all fields");
      return;
    } else {
      // update firebase
      const userDoc = {
        getBetterSleep,
        eatBetter,
        quitSmoking,
        manageStress,
        increaseActivity,
        improveMentalHealth,
        loseWeight,
        activitySatisfaction,
        WellnessFeelingDown,
        dietSatisfaction,
        sleepSatisfaction,
        stressLevelSatisfaction,
        anxiety,
        arrhythmia,
        asthma,
        backpain,
        congestiveHeartFailure,
        copd,
        depression,
        diabetes,
        highCholesterol,
        hypertension: hypertension ? "yes" : "no",
        smoker: smoker ? "yes" : "no",
        bloodPressureMeds: bloodPressureMeds ? "yes" : "no",
        gender,
        heightFt: parseInt(heightFt),
        heightIn: parseInt(heightIn),
        weight: parseInt(weight),
        hraComplete: true,
      };

      const db = getFirestore();
      const docRef = doc(db, "users", member.uid);
      updateDoc(docRef, userDoc)
        .then(() => {
          alert("HRA Updated");
          setShowHRAModal(false);
          window.location.reload();
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Health Risk Assessment</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {member.hraComplete ? (
          <>
            <IonList>
              {member.quitSmoking ? (
                <IonItem>
                  <b>Quit Smoking</b>: Yes
                </IonItem>
              ) : null}

              {member.manageStress ? (
                <IonItem>
                  <b>Manage Stress</b>: Yes
                </IonItem>
              ) : null}

              {member.loseWeight ? (
                <IonItem>
                  <b>Loose Weight</b>: Yes
                </IonItem>
              ) : null}

              {member.increaseActivity ? (
                <IonItem>
                  <b>Increase Activity</b>: Yes
                </IonItem>
              ) : null}

              {member.improveMentalHealth ? (
                <IonItem>
                  <b>Improve Mental Health</b>: Yes
                </IonItem>
              ) : null}

              {member.getBetterSleep ? (
                <IonItem>
                  <b>Get Better Sleep</b>: Yes
                </IonItem>
              ) : null}

              {member.eatBetter ? (
                <IonItem>
                  <b>Eat Better</b>: Yes
                </IonItem>
              ) : null}

              {member.highCholesterol ? (
                <IonItem>
                  <b>High Cholesterol </b>: {member.highCholesterol ? "Yes" : "No"}
                </IonItem>
              ) : null}

              <IonItem>
                <b>Feeling Down</b>: {member.WellnessFeelingDown}
              </IonItem>

              <IonItem>
                <b>Activity Satisfaction</b>: {member.activitySatisfaction}
              </IonItem>

              <IonItem>
                <b>Diet Satisfaction</b>: {member.dietSatisfaction}
              </IonItem>

              <IonItem>
                <b>Sleep Satisfaction</b>: {member.sleepSatisfaction}
              </IonItem>

              <IonItem>
                <b>Stress Level Satisfaction</b>:{" "}
                {member.stressLevelSatisfaction}
              </IonItem>

              <IonItem>
                <b>Anxiety</b>: {member.anxiety ? "Yes" : "No"}
              </IonItem>

              <IonItem>
                <b>Arrhythmia</b>: {member.arrhythmia ? "Yes" : "No"}
              </IonItem>

              <IonItem>
                <b>Asthma</b>: {member.asthma ? "Yes" : "No"}
              </IonItem>

              <IonItem>
                <b>Backpain</b>: {member.backpain ? "Yes" : "No"}
              </IonItem>

              <IonItem>
                <b>Congestive Heart Failure</b>:{" "}
                {member.congestiveHeartFailure ? "Yes" : "No"}
              </IonItem>

              <IonItem>
                <b>COPD</b>: {member.copd ? "Yes" : "No"}
              </IonItem>

              <IonItem>
                <b>Depression</b>: {member.depression ? "Yes" : "No"}
              </IonItem>

              <IonItem>
                <b>Diabetes</b>: {member.diabetes}
              </IonItem>

              <IonItem>
                <b>Hypertension</b>: {member.hypertension}
              </IonItem>

              <IonItem>
                <b>Blood Pressure Medication</b>: {member.bloodPressureMeds}
              </IonItem>

              <IonItem>
                <b>Smoker</b>: {member.smoker}
              </IonItem>
            </IonList>
          </>
        ) : (
          <IonButton onClick={() => setShowHRAModal(true)}>
            Complete Member HRA
          </IonButton>
        )}
      </IonCardContent>
      <IonModal isOpen={showHRAModal}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setShowHRAModal(false)}>
                Close
              </IonButton>
            </IonButtons>
            <IonTitle className="mainPageTitle">
              Health Risk Assessment
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={(e) => handleHRA(e)}>
            <IonList>
              <IonItem>
                <IonLabel>Gender</IonLabel>
                <IonSelect
                  value={gender}
                  placeholder="Select One"
                  onIonChange={(e) => setGender(e.detail.value)}
                >
                  <IonSelectOption value="male">Male</IonSelectOption>
                  <IonSelectOption value="female">Female</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Height</IonLabel>
                <IonInput
                  type="number"
                  placeholder="Feet"
                  value={heightFt}
                  onIonChange={(e) => setHeightFt(e.detail.value)}
                ></IonInput>
                <IonInput
                  type="number"
                  placeholder="Inches"
                  value={heightIn}
                  onIonChange={(e) => setHeightIn(e.detail.value)}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel>Weight</IonLabel>
                <IonInput
                  type="number"
                  placeholder="Pounds"
                  value={weight}
                  onIonChange={(e) => setWeight(e.detail.value)}
                ></IonInput>
              </IonItem>

              <IonItem>
                <p>Get Better Sleep </p>
                <IonCheckbox
                  checked={getBetterSleep}
                  className="ion-margin-start"
                  onIonChange={(e) => setGetBetterSleep(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Eat Better </p>
                <IonCheckbox
                  checked={eatBetter}
                  className="ion-margin-start"
                  onIonChange={(e) => setEatBetter(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Improve Mental Health </p>
                <IonCheckbox
                  checked={improveMentalHealth}
                  className="ion-margin-start"
                  onIonChange={(e) => setImproveMentalHealth(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>

              <IonItem>
                <p>Increase Activity </p>
                <IonCheckbox
                  checked={increaseActivity}
                  className="ion-margin-start"
                  onIonChange={(e) => setIncreaseActivity(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>

              <IonItem>
                <p>Loose Weight </p>
                <IonCheckbox
                  checked={loseWeight}
                  className="ion-margin-start"
                  onIonChange={(e) => setLoseWeight(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Manage Stress </p>
                <IonCheckbox
                  checked={manageStress}
                  className="ion-margin-start"
                  onIonChange={(e) => setManageStress(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Quit Smoking </p>
                <IonCheckbox
                  checked={quitSmoking}
                  className="ion-margin-start"
                  onIonChange={(e) => setQuitSmoking(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Anxiety</p>
                <IonCheckbox
                  checked={anxiety}
                  className="ion-margin-start"
                  onIonChange={(e) => setAnxiety(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Arrhythmia</p>
                <IonCheckbox
                  checked={arrhythmia}
                  className="ion-margin-start"
                  onIonChange={(e) => setArrhythmia(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Asthma</p>
                <IonCheckbox
                  checked={asthma}
                  className="ion-margin-start"
                  onIonChange={(e) => setAsthma(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Backpain</p>
                <IonCheckbox
                  checked={backpain}
                  className="ion-margin-start"
                  onIonChange={(e) => setBackpain(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Congestive Heart Failure</p>
                <IonCheckbox
                  checked={congestiveHeartFailure}
                  className="ion-margin-start"
                  onIonChange={(e) =>
                    setCongestiveHeartFailure(e.detail.checked)
                  }
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>COPD</p>
                <IonCheckbox
                  checked={copd}
                  className="ion-margin-start"
                  onIonChange={(e) => setCopd(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Depression</p>
                <IonCheckbox
                  checked={depression}
                  className="ion-margin-start"
                  onIonChange={(e) => setDepression(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <IonLabel>Diabetes</IonLabel>
                <IonSelect
                  value={diabetes}
                  placeholder="Select One"
                  onIonChange={(e) => setDiabetes(e.detail.value)}
                >
                  <IonSelectOption value="type1">Type 1</IonSelectOption>
                  <IonSelectOption value="type2">Type 2</IonSelectOption>
                  <IonSelectOption value="no">No</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <p>Hypertension</p>
                <IonCheckbox
                  checked={hypertension}
                  className="ion-margin-start"
                  onIonChange={(e) => setHypertension(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Smoker</p>
                <IonCheckbox
                  checked={smoker}
                  className="ion-margin-start"
                  onIonChange={(e) => setSmoker(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>Blood Pressure Medication</p>
                <IonCheckbox
                  checked={bloodPressureMeds}
                  className="ion-margin-start"
                  onIonChange={(e) => setBloodPressureMeds(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <p>High Cholesterol</p>
                <IonCheckbox
                  checked={highCholesterol}
                  className="ion-margin-start"
                  onIonChange={(e) => setHighCholesterol(e.detail.checked)}
                ></IonCheckbox>
              </IonItem>
              <IonItem>
                <IonLabel>Feeling Down</IonLabel>
                <IonSelect
                  value={WellnessFeelingDown}
                  placeholder="Select One"
                  onIonChange={(e) => setWellnessFeelingDown(e.detail.value)}
                >
                  <IonSelectOption value="Not at All">
                    Not at All
                  </IonSelectOption>
                  <IonSelectOption value="Several Days">
                    Several Days
                  </IonSelectOption>
                  <IonSelectOption value="More than Half the Days">
                    More than half the Days
                  </IonSelectOption>
                  <IonSelectOption value="Nearly Every Day">
                    Nearly everyday
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Activity Satisfaction</IonLabel>
                <IonSelect
                  value={activitySatisfaction}
                  placeholder="Select One"
                  onIonChange={(e) => setActivitySatisfaction(e.detail.value)}
                >
                  <IonSelectOption value="Very Happy">
                    Very Happy
                  </IonSelectOption>
                  <IonSelectOption value="Happy">Happy</IonSelectOption>
                  <IonSelectOption value="Neutral">Neutral</IonSelectOption>
                  <IonSelectOption value="Unhappy">Unhappy</IonSelectOption>
                  <IonSelectOption value="Very Unhappy">
                    {" "}
                    Very Unhappy
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Diet Satisfaction</IonLabel>
                <IonSelect
                  value={dietSatisfaction}
                  placeholder="Select One"
                  onIonChange={(e) => setDietSatisfaction(e.detail.value)}
                >
                  <IonSelectOption value="Very Happy">
                    Very Happy
                  </IonSelectOption>
                  <IonSelectOption value="Happy">Happy</IonSelectOption>
                  <IonSelectOption value="Neutral">Neutral</IonSelectOption>
                  <IonSelectOption value="Unhappy">Unhappy</IonSelectOption>
                  <IonSelectOption value="Very Unhappy">
                    Very Unhappy
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Sleep Satisfaction</IonLabel>
                <IonSelect
                  value={sleepSatisfaction}
                  placeholder="Select One"
                  onIonChange={(e) => setSleepSatisfaction(e.detail.value)}
                >
                  <IonSelectOption value="Very Happy">
                    Very Happy
                  </IonSelectOption>
                  <IonSelectOption value="Happy">Happy</IonSelectOption>
                  <IonSelectOption value="Neutral">Neutral</IonSelectOption>
                  <IonSelectOption value="Unhappy">Unhappy</IonSelectOption>
                  <IonSelectOption value="Very Unhappy">
                    Very Unhappy
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Stress Level Satisfaction</IonLabel>
                <IonSelect
                  value={stressLevelSatisfaction}
                  placeholder="Select One"
                  onIonChange={(e) =>
                    setStressLevelSatisfaction(e.detail.value)
                  }
                >
                  <IonSelectOption value="Very Happy">
                    Very Happy
                  </IonSelectOption>
                  <IonSelectOption value="Happy">Happy</IonSelectOption>
                  <IonSelectOption value="Neutral">Neutral</IonSelectOption>
                  <IonSelectOption value="Unhappy">Unhappy</IonSelectOption>
                  <IonSelectOption value="Very Unhappy">
                    Very Unhappy
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>
            <IonButton type="submit" expand="full" className="ion-margin">
              Submit
            </IonButton>
          </form>
        </IonContent>
      </IonModal>
    </IonCard>
  );
};

export default MemberHra;
