import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";

const TestEmailDigest: React.FC = () => {
  const [emailDigestLoading, setEmailDigestLoading] = useState(false);

  const testEmailDigest = async () => {
    setEmailDigestLoading(true);
    fetch(
      "https://us-central1-allied-wellness-22d1f.cloudfunctions.net/Koro-BodeeWeeklyDigestTestUrl",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: "test",
        }),
      }
    )
      .then((response) => {
        setEmailDigestLoading(false);
      })
      .catch((error) => {
        setEmailDigestLoading(false);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonMenuButton />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle>Test EmailDigest</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonButton onClick={testEmailDigest}>
          {emailDigestLoading ? (
            <IonSpinner></IonSpinner>
          ) : (
            <>Test Email Digest</>
          )}
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default TestEmailDigest;
