import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { useUserData } from "../../lib/hooks";
import "./Add.css";
//import spinner
import React, { useContext } from "react";
import axios from "axios";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { UserContext } from "../../lib/context";
import SetTimeZone from "../../components/Preferences/TimeZone";

const Preferences: React.FC = () => {
  const { user, profile } = useContext(UserContext);


  const updateSMSNotifications = async () => {
    const db = getFirestore();
    const userDoc = doc(db, "users", user.uid);
    await updateDoc(userDoc, {
      smsNotificationsOn: !profile.smsNotificationsOn,
    });
  };


  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Preferences</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItem>
            <IonLabel>SMS Notifications</IonLabel>
            <IonToggle slot="end" onClick={updateSMSNotifications} checked={profile.smsNotificationsOn}></IonToggle>
          </IonItem>
          {/* <IonItem>
            <IonLabel>Checked Toggle</IonLabel>
            <IonToggle slot="end" checked={true}></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Disabled Toggle</IonLabel>
            <IonToggle slot="end" disabled={true}></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Disabled Checked Toggle</IonLabel>
            <IonToggle slot="end" checked={true} disabled={true}></IonToggle>
          </IonItem> */}
          <SetTimeZone />
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Preferences;
