import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { getAuth, updatePassword } from "firebase/auth";
import { doc, getFirestore, writeBatch, getDoc } from "firebase/firestore";
import React from "react";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../lib/context";
import { useUserData } from "../../lib/hooks";

import "./ProfileCreate.css";

const ProfileCreate: React.FC = () => {
  const userData = useUserData();
  const { user, profile } = useContext(UserContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [specialty, setSpecialty] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [title, setTitle] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [about, setAbout] = useState("");
  const [calendlyLink, setCalendlyLink] = useState("");
  const [password, setPassword] = React.useState<string>();
  const [passwordConfirm, setPasswordConfirm] = React.useState<string>();

  const validateFisrtName = () => {
    var firstNameHelp = document.getElementById("firstNameHelp");
    var regex = /^[a-z ,.'-]+$/i;

    if (firstName === undefined || firstName === "") {
      firstNameHelp.innerHTML = "First name is required";
      return false;
    }

    // make sure only accepts letters
    else if (!regex.test(firstName)) {
      firstNameHelp.innerHTML = "First name must only contain letters or ,.'-";
      return false;
    } else if (firstName.length < 2) {
      firstNameHelp.innerHTML = "First name must be at least 2 characters";
      return false;
    } else {
      firstNameHelp.innerHTML = "";
      return true;
    }
  };

  const validateLastName = () => {
    var lastNameHelper = document.getElementById("lastNameHelp");
    var regex = /^[a-z ,.'-]+$/i;

    if (lastName === undefined || firstName === "") {
      lastNameHelper.innerHTML = "Last name is required";
      return false;
    }

    // make sure only accepts letters
    else if (!regex.test(lastName)) {
      lastNameHelper.innerHTML = "Last name must only contain letters or ,.'-";
      return false;
    } else if (lastName.length < 2) {
      lastNameHelper.innerHTML = "Last name must be at least 2 characters";
      return false;
    } else {
      lastNameHelper.innerHTML = "";
      return true;
    }
  };

  const validatePhone = () => {
    const phoneInput = document.getElementById("phoneHelp");
    console.log("phone", phone);
    var re = /^\d{3}-\d{3}-\d{4}$/;

    if (phone === undefined || phone === "") {
      phoneInput.innerHTML = "Phone number is required";
      return false;
    }

    else if (!re.test(String(phone).toLowerCase())) {
      phoneInput.innerHTML = "Phone number must be in the format 123-456-7890";
      return false;
    } else {
      phoneInput.innerHTML = "";
      return true;
    }

  };

  const validateSpecialty = () => {
    var specialtyHelp = document.getElementById("specialtyHelp");

    console.log("specialty", specialty);

    if (specialty.length === 0) {
      specialtyHelp.innerHTML = "Please select at least one specialty";
      return false;
    } else {
      specialtyHelp.innerHTML = "";
      return true;
    }
  };

  const validateTitle = () => {
    var titleHelp = document.getElementById("titleHelp");

    if (title === undefined || title === "") {
      titleHelp.innerHTML = "Title is required";
      return false;
    } else {
      titleHelp.innerHTML = "";
      return true;
    }
  };

  const validateQualifications = () => {
    var qualificationsHelp = document.getElementById("qualificationsHelp");

    if (qualifications === undefined || qualifications === "") {
      qualificationsHelp.innerHTML = "Qualifications is required";
      return false;
    } else {
      qualificationsHelp.innerHTML = "";
      return true;
    }
  };

  const validateAbout = () => {
    var aboutHelp = document.getElementById("aboutHelp");

    if (about === undefined || about === "") {
      aboutHelp.innerHTML = "About is required";
      return false;
    } else {
      aboutHelp.innerHTML = "";
      return true;
    }
  };

  const validateCalendlyLink = () => {
    var calendlyLinkHelp = document.getElementById("calendlyLinkHelp");

    if (calendlyLink === undefined || calendlyLink === "") {
      calendlyLinkHelp.innerHTML = "Calendly link is required";
      return false;
    } else {
      calendlyLinkHelp.innerHTML = "";
      return true;
    }
  };

  const validatePassword = () => {
    var passwordHelper = document.getElementById("passwordHelp");
    //pasword regex
    var regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (password === undefined || password === "") {
      passwordHelper.innerHTML = "Password is required";
      return false;
    } else if (password.length < 8) {
      passwordHelper.innerHTML = "Password must be at least 8 characters";
      return false;
    } else if (!regex.test(password)) {
      passwordHelper.innerHTML =
        "Password must contain one letter, one number, and one special character";
      return false;
    } else {
      passwordHelper.innerHTML = "";
      return true;
    }
  };

  const validateConfirmPassword = () => {
    var confirmPasswordHelper = document.getElementById("passwordConfirmHelp");

    if (passwordConfirm === undefined || passwordConfirm === "") {
      confirmPasswordHelper.innerHTML = "Confirm password is required";
      return false;
    } else if (passwordConfirm !== password) {
      confirmPasswordHelper.innerHTML = "Passwords do not match";
      return false;
    } else {
      confirmPasswordHelper.innerHTML = "";
      return true;
    }
  };

  const updatePasswordFunction = async () => {
    if (password === passwordConfirm) {
      const auth = getAuth();

      const user = auth.currentUser;

      updatePassword(user, password)
        .then(() => {
          // Update successful.
          return true;
        })
        .catch((error) => {
          // An error ocurred
          console.log(error);
          return false;
        });
    } else {
      alert("Passwords do not match");
      return false;
    }
  };

  const createProfile = async (e) => {
    e.preventDefault();

    const loginHelper = document.getElementById("loginHelp");

    if (
      validateFisrtName() &&
      validateLastName() &&
      validateSpecialty &&
      validatePassword() &&
      validateConfirmPassword() &&
      validateTitle() &&
      validateQualifications() &&
      validateAbout() &&
      validateCalendlyLink() &&
      validatePhone()
    ) {
      console.log("valid");
    } else {
      console.log("invalid");
      return;
    }

    const passwordChangedStatus = updatePasswordFunction();

    //get

    if (passwordChangedStatus) {
      // Create refs for both documents
      const userDoc = doc(getFirestore(), "users", userData.user.uid);

      // Commit both docs together as a batch write.
      const batch = writeBatch(getFirestore());

      //make uid all lower case

      batch.set(userDoc, {
        firstName: firstName,
        lastName: lastName,
        uid: userData.user.uid,
        email: userData.user.email,
        title: title,
        qualifications: qualifications,
        about: about,
        calendlyLink: calendlyLink,
        specialty: specialty,
        active: true,
        role: "coach",
        hraComplete: true,
        phone: phone,
        smsNotificationsOn: true,
        cometChatUid: userData.user.uid.toLowerCase(),
        photoURL:
          "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/default.png?alt=media&token=c8450ca6-7ef6-49a3-95ff-2246452d62cc",
      });

      await batch
        .commit()
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("password not changed");
      loginHelper.innerHTML = "Make sure your password is correct";
      return;
    }
  };

  //fetch specialities from firebase
  const fetchSpecialties = async () => {
    const specialtiesRef = doc(getFirestore(), "coaches", "specialties");
    const specialties = await getDoc(specialtiesRef);

    setSpecialties(specialties.data().specialties);
  };

  useEffect(() => {
    fetchSpecialties();
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonCard className="ion-padding">
          <IonCardHeader>
            <IonCardTitle className="ion-text-center">
              Profile Data
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <form onSubmit={createProfile}>
              <IonItem>
                <IonLabel position="stacked">First Name</IonLabel>
                <IonInput
                  placeholder=""
                  onIonChange={(e: any) => {
                    setFirstName(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateFisrtName();
                  }}
                ></IonInput>
                <p className="formHelp" id="firstNameHelp"></p>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Last Name</IonLabel>
                <IonInput
                  placeholder=""
                  onIonChange={(e: any) => {
                    setLastName(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateLastName();
                  }}
                ></IonInput>
                <p className="formHelp" id="lastNameHelp"></p>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Phone Number (for notifications only) xxx-xxx-xxxx</IonLabel>
                <IonInput
                  placeholder=""
                  onIonChange={(e: any) => {
                    setPhone(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validatePhone();
                  }}
                ></IonInput>
                <p className="formHelp" id="phoneHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Specialty</IonLabel>
                <IonSelect
                  multiple={true}
                  cancelText="Cancel"
                  okText="Okay"
                  onIonChange={(e) => setSpecialty(e.detail.value)}
                  onIonDismiss={(e: any) => {
                    validateSpecialty();
                  }}
                >
                  {specialties.map((specialty: any) => (
                    <IonSelectOption key={specialty} value={specialty}>
                      {specialty}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                <p className="formHelp" id="specialtyHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Title</IonLabel>
                <IonInput
                  placeholder="Registered Dietitian"
                  onIonChange={(e: any) => {
                    setTitle(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateTitle();
                  }}
                ></IonInput>
                <p className="formHelp" id="titleHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Qualifications</IonLabel>
                <IonInput
                  placeholder=""
                  onIonChange={(e: any) => {
                    setQualifications(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateQualifications();
                  }}
                ></IonInput>
                <p className="formHelp" id="qualificationsHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">1-3 Sentence About</IonLabel>
                <IonInput
                  placeholder=""
                  onIonChange={(e: any) => {
                    setAbout(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateAbout();
                  }}
                ></IonInput>
                <p className="formHelp" id="aboutHelp"></p>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Calendly Link</IonLabel>
                <IonInput
                  placeholder=""
                  onIonChange={(e: any) => {
                    setCalendlyLink(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateCalendlyLink();
                  }}
                ></IonInput>
                <p className="formHelp" id="calendlyLinkHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">New Password</IonLabel>
                <IonInput
                  type="password"
                  onIonChange={(e: any) => {
                    setPassword(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validatePassword();
                  }}
                ></IonInput>
                <p className="formHelp" id="passwordHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Confirm New Password</IonLabel>
                <IonInput
                  type="password"
                  onIonChange={(e: any) => {
                    setPasswordConfirm(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateConfirmPassword();
                  }}
                ></IonInput>
                <p className="formHelp" id="passwordConfirmHelp"></p>
              </IonItem>

              <div className="ion-padding-top" id="loginHelp"></div>

              <IonButton type="submit" className="ion-padding">
                Create Account
              </IonButton>
            </form>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ProfileCreate;
