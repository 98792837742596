import { IonApp, setupIonicReact } from "@ionic/react";
import PublicRoutes from "./pages/public/PublicRouter";
import PrivateRoutes from "./pages/private/Router/PrivateRouter";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css"; 



import { UserContext } from "./lib/context";
import { useUserData } from "./lib/hooks";

import { CometChat } from "@cometchat-pro/chat";

setupIonicReact();

//intialize comet chat
const appID = process.env.REACT_APP_COMET_APP_ID;
const region = process.env.REACT_APP_COMET_REGION;
const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build();
CometChat.init(appID, appSetting).then(
  () => {
    console.log("Initialization completed successfully");
    // You can now call login function.
  },
  (error) => {
    console.log("Initialization failed with error:", error);
    // Check the reason for error and take appropriate action.
  }
); 

const App = () => {
  const userData = useUserData();

  return (
    <>
      <UserContext.Provider value={userData}>
        <IonApp>{userData.user ? <PrivateRoutes /> : <PublicRoutes />}</IonApp>
      </UserContext.Provider>
    </>
  );
};

export default App;
