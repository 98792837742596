import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
//import spinner
import { useState } from "react";
import React from "react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updateEmail,
} from "firebase/auth";

const EditHealthInfo: React.FC = () => {
  //email uststate
  const [email, setEmail] = useState();
  const [confirmEmail, setConfirmEmail] = useState();
  const [password, setPassword] = useState();
  const [completed, setCompleted] = useState(false);

  //email validation
  const validateEmail = () => {
    var emailHelper = document.getElementById("emailHelp");
    var regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email === undefined || email === "") {
      emailHelper.innerHTML = "Email is required";
      return false;
    } else if (!regex.test(email)) {
      emailHelper.innerHTML = "Email is invalid";
      return false;
    } else {
      emailHelper.innerHTML = "";
      return true;
    }
  };

  const validateConfirmEmail = () => {
    var confirmEmailHelper = document.getElementById("emailConfirmHelp");

    if (confirmEmail === undefined || confirmEmail === "") {
      confirmEmailHelper.innerHTML = "Confirm email is required";
      return false;
    } else if (confirmEmail !== email) {
      confirmEmailHelper.innerHTML = "Emails do not match";
      return false;
    } else {
      confirmEmailHelper.innerHTML = "";
      return true;
    }
  };

  const updateEmailEverywhere = async (e) => {
    var passwordHelper = document.getElementById("passwordHelp");

    e.preventDefault();

    const auth = getAuth();
    const user = auth.currentUser;

    const credential = EmailAuthProvider.credential(user.email, password);

    if (validateEmail() && validateConfirmEmail()) {
      reauthenticateWithCredential(user, credential)
        .then(async () => {
          // User re-authenticated.
          var ref = doc(getFirestore(), "users", user.uid);
          await updateDoc(ref, {
            email: email,
          });

          updateEmail(auth.currentUser, email)
            .then(() => {
              setCompleted(true);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          passwordHelper.innerHTML = "Password is incorrect";
        });
    } else {
    }
  };

  return (
    <IonCard>
      <IonCardHeader>Change Email</IonCardHeader>
      <IonCardContent>
        {completed ? (
          <p className="ion-padding ion-text-center">
            Congradualtions! <br></br> Email Changed to {email}
          </p>
        ) : (
          <form onSubmit={updateEmailEverywhere}>
            <IonItem>
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput
                type="password"
                onIonChange={(e: any) => {
                  setPassword(e.detail.value);
                }}
              ></IonInput>
              <p className="formHelp" id="passwordHelp"></p>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">New Email</IonLabel>
              <IonInput
                type="email"
                onIonChange={(e: any) => {
                  setEmail(e.detail.value);
                }}
                onIonBlur={validateEmail}
              ></IonInput>
              <p className="formHelp" id="emailHelp"></p>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Confirm New Email</IonLabel>
              <IonInput
                type="email"
                onIonChange={(e: any) => {
                  setConfirmEmail(e.detail.value);
                }}
                onIonBlur={validateConfirmEmail}
              ></IonInput>
              <p className="formHelp" id="emailConfirmHelp"></p>
            </IonItem>

            <div className="ion-padding-top" id="login-help"></div>

            <IonButton type="submit" className="ion-padding">
              Update
            </IonButton>
          </form>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default EditHealthInfo;
