import {
  IonButton,
  IonContent,
  IonItem,
  IonPopover,
} from "@ionic/react";
import { useContext, useState, useCallback, useEffect } from "react";
import {
  fetchGetUser,
} from "../../../../API/memberAPI";
import { MemberUidContext } from "../../../../lib/context/MemberUidContext";
import { userType } from "../../../../Types/user";

export default function CompliantHistory() {
  const memberUid = useContext(MemberUidContext);
  const [compliantHistoryData, setCompliantHistoryData] = useState<userType[]>(
    []
  );

  const fetchUserDB = useCallback(async () => {
    const compliantDB = await fetchGetUser(memberUid);
    setCompliantHistoryData(compliantDB);
    console.log("see me", compliantHistoryData[0]);
  }, [memberUid]);

  useEffect(() => {
    fetchUserDB();
  }, [fetchUserDB]);

  return (
    <>
      <IonButton id="click-trigger" color={"danger"} slot="end">
        History
      </IonButton>
      <IonPopover trigger="click-trigger" triggerAction="click">
        {compliantHistoryData.map((userHistoryCompliant, index) => (
          <IonContent class="ion-padding" key={index}>
            {userHistoryCompliant.complianceHistory.map((note, noteIndex) => (
              <IonItem
                key={noteIndex}
                color={note[Object.keys(note)[0]] ? "success" : "danger"}
                lines="none"
              >
                {Object.keys(note)[0]}
              </IonItem>
            ))}
          </IonContent>
        ))}
      </IonPopover>
    </>
  );
}
