//tempalte for component in react
import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import "./MemberLookupByEmail.css";
import { starSharp } from "ionicons/icons";

export default function MyMembers() {
  const [searchClicked, setSearchClicked] = useState(false);
  const [searchEmail, setSearchEmail] = useState("");

  function SearchBar() {}

  function SessionLookup(props) {
    const [user, setUser] = useState([]);

    useEffect(() => {
      fetchUser();
    }, []);

    const fetchUser = async () => {
      //get sessiosn from firebase
      const q = query(
        collection(getFirestore(), "users"),
        where("email", "==", props.email.toLowerCase())
      );

      var users = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        console.log("user", doc.data());
        users.push({ id: doc.id, ...doc.data() });
      });

      setUser(users);
    };

    function RenderCompliance({ compliant, compliantSetDate }) {
      if (compliant) {
        if(compliantSetDate === null) {
          return <IonLabel color="success">Compliant</IonLabel>;
        }
        else{
          const date = compliantSetDate.toDate();
          // check to see if before 20th of month
          if(date.getDate() < 20) {
          return <IonLabel color="success">Compliant</IonLabel>;
          }
          else{
            return <IonLabel color="success">Compliant <IonIcon icon={starSharp}></IonIcon></IonLabel>;
          }
        }
      } else {
        return <IonLabel color="danger">Non-Compliant</IonLabel>;
      }
    }

    const RenderHraComplete = ({ hraComplete }) => {
      console.log(hraComplete);
      if (hraComplete) {
        return <IonLabel color="success">Complete</IonLabel>;
      }
      return <IonLabel color="danger">Incomplete</IonLabel>;
    };

    const RenderUser = () => {
      let users = [];
      user.forEach((member, index) => {
        users.push(
          <>
            <IonItem key={member.uid}>
              <IonAvatar slot="start">
                {" "}
                <img src={member.photoURL} />
              </IonAvatar>
              <IonLabel>
                {member.firstName} {member.lastName} {member.dependent ? "(Dependent)" : ""} 
              </IonLabel>
              <IonLabel>{member.email}</IonLabel>
              <IonLabel>
                <RenderCompliance compliant={member.compliant} compliantSetDate={member.compliantSetDate}/>
              </IonLabel>
              <IonLabel>{member.groupName}</IonLabel>
              <IonLabel className="ion-text-wrap">
                <RenderHraComplete hraComplete={member.hraComplete} />
              </IonLabel>
              <IonLabel>
                <IonButton href={"/member-dash/" + member.uid} target="_blank">
                  Dashboard
                </IonButton>
              </IonLabel>
            </IonItem>
          </>
        );
      });

      if (users.length == 0) {
        return (
          <div>
            No user found with email <b>{props.email}</b>
          </div>
        );
      }

      return <>{users}</>;
    };

    return (
      <>
        <RenderUser />
        <IonButton onClick={() => setSearchClicked(false)}>
          Search Again
        </IonButton>
      </>
    );
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Member Lookup By Email</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {searchClicked ? (
          <SessionLookup email={searchEmail} />
        ) : (
          <>
            <IonItem>
              <IonLabel position="stacked">Member Email</IonLabel>
              <IonInput
                type="email"
                placeholder="Enter Email"
                onIonChange={(e) => setSearchEmail(e.detail.value)}
              />
            </IonItem>
            <IonButton onClick={() => setSearchClicked(true)}>Lookup</IonButton>
          </>
        )}
      </IonCardContent>
    </IonCard>
  );
}
