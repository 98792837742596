import { useEffect, useState } from "react";
import "./style.css";
import { doc, getDoc, getFirestore } from "@firebase/firestore";
import { IonLabel } from "@ionic/react";

type Props = {
  question: any;
};

const PostQuestionSurvey: React.FC<Props> = ({ question }) => {
  const [quizData, setQuizData] = useState(null);

  const RenderDate = ({ date }) => {

    console.log("date", date)

    const dateOnj = new Date(date.seconds * 1000);

    return (
      <span>
        {dateOnj.toLocaleDateString()} {dateOnj.toLocaleTimeString()}
      </span>
    );
  };

  if (
    question.answer === "" ||
    question.answer == null ||
    question.answer === undefined
  ) {
    return <IonLabel><p>No post question survey yet...</p></IonLabel>
  }

  //       questionSuccessRating: rating,
  //       goneInstead: goneInstead,
  //       speedRating: speedRating,
  //       speakToExpert: speakToExpert,
  //       useFeatureAgain: useFeatureAgain,
  //       recommendFeature: recommendFeature,
  //       whyNotRecommend: whyNotRecommend,
  //       feedback: feedback,
  //       responseSubmittedDate: new Date(),

  return (
    <>
      <div>
        <h2>Post Question Survey</h2>
        <IonLabel>
          <p className="ion-margin-bottom">
            Submitted on <RenderDate date={question["responseSubmittedDate"]} />
          </p>
        </IonLabel>

        <IonLabel>
          <p>
            Question success rating:{" "}
            <span className="bold">{question["questionSuccessRating"]}</span>
          </p>
        </IonLabel>
        <IonLabel>
          <p>
            Speed rating:{" "}
            <span className="bold">{question["speedRating"]}</span>
          </p>
        </IonLabel>
        <IonLabel>
          <p>
            Gone instead:{" "}
            <span className="bold">{question["goneInstead"]}</span>
          </p>
        </IonLabel>
        <IonLabel>
          <p>
            Speak to expert:{" "}
            <span className="bold">{question["speakToExpert"]}</span>
          </p>
        </IonLabel>
        <IonLabel>
          <p>
            Use feature again:{" "}
            <span className="bold">{question["useFeatureAgain"]}</span>
          </p>
        </IonLabel>
        <IonLabel>
          <p>
            Recommend feature:{" "}
            <span className="bold">{question["recommendFeature"]}</span>
          </p>
        </IonLabel>
        {question["recommendFeature"] === "n o" && (
          <IonLabel>
            <p>
              Why not recommend:{" "}
              <span className="bold">{question["whyNotRecommend"]}</span>
            </p>
          </IonLabel>
        )}

        <IonLabel>
          <p>
            Feedback: <span className="bold">{question["feedback"]}</span>
          </p>
        </IonLabel>
      </div>
    </>
  );
};

export default PostQuestionSurvey;
