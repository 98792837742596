import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import {
  query,
  collection,
  getFirestore,
  getDocs,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import React from "react";
import { CSVLink } from "react-csv";
import "./ComplianceReport.css";

const BodeeDataReport: React.FC = () => {
  const [csvData, setCsvData] = React.useState([]);

  const resetReport = () => {
    setCsvData([]);
  };

  const getMembers = async () => {
    var publicAvatars = [];

    // get all docs from users
    const q = query(collection(getFirestore(), "koroPublicAvatars"));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (document) => {
      var row = document.data();

      if (row.dateCreated) {
        row.dateCreatedSeconds = row.dateCreated.seconds;
        row.dateCreated = row.dateCreated.toDate().toLocaleString() || "N/A";
      }

      if (row.lastUsed) {
        row.lastUsedSeconds = row.lastUsed.seconds;
        row.lastUsed = row.lastUsed.toDate().toLocaleString() || "N/A";
      }

      // get tracking data too
      await getDoc(doc(getFirestore(), "tracking", document.id)).then((doc) => {
        // console.log("doc", doc.data());
        if (doc.exists()) {
          const newRow = {
            id: document.id,
            ...row,
            android: doc.data().android,
            ios: doc.data().ios,
            desktop: doc.data().desktop,
          };
          setCsvData((csvData) => [...csvData, newRow]);
        }
      });
    });
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Bodee Public Avatar Report</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {csvData.length === 0 ? (
          <IonButton onClick={getMembers}>Get Report</IonButton>
        ) : (
          <>
            <CSVLink
              data={csvData}
              className="downloadButton"
              filename={"BodeePublicAvatarReport" + Date.now() + ".csv" }
            >
              Download Report Here
            </CSVLink>{" "}
            <br></br>
            <IonButton onClick={resetReport}>New Report</IonButton>
          </>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default BodeeDataReport;
