import { heartOutline } from "ionicons/icons";
import { useEffect } from "react";

export interface Goals {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  img: string;
  id: string;
  questions: number;
}

const testImg = "assets/bodee/Find Peace.png"


  // Manage Anxiety & Depression
  // Manage your Diabetes
  // Overcome Addiction
  // Reduce Stress
  // Rehab an Injury
  // Sleep Better
  

// create interest object from list above
export const GoalsList: Goals[] = [
  {
    title: "Achieve Mindfulness",
    url: "/goal-achieve-mindfulness",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FAchieve%20Mindfullness.jpg?alt=media&token=e3d33513-53d8-4fbc-89dc-b74441126a85",
    id: "goal-achieve-mindfulness",
    questions: 0,
  },
  {
    title: "Eat Better",
    url: "/goal-eat-better",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FEat%20Better.jpg?alt=media&token=94a6b6e9-d836-48f6-b2c1-e9c820f8292b",
    id: "goal-eat-better",
    questions: 0,
  },
  {
    title: "Improve Emotional Wellbeing",
    url: "/goal-improve-emotional-wellbeing",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FImprove%20Emotional%20Wellness.jpg?alt=media&token=1d2fce4d-27e0-4f4b-8089-f117ceef05e1",
    id: "goal-improve-emotional-wellbeing",
    questions: 0,
  },
  {
    title: "Improve Fertility",
    url: "/goal-improve-fertility",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FImprove%20Fertility.jpg?alt=media&token=e024d392-2650-4b39-830d-f0ba00502a93",
    id: "goal-improve-fertility",
    questions: 0,
  },
  {
    title: "Improve Fitness",
    url: "/goal-improve-fitness",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FImprove%20Fitness.jpg?alt=media&token=93ba2d7c-4309-472f-aab1-938e7e7f31c7",
    id: "goal-improve-fitness",
    questions: 0,
  },
  {
    title: "Improve Flexibility and Range of Motion",
    url: "/goal-improve-flexibility",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FImprove%20Flexibility%20and%20Range%20of%20Motion.jpg?alt=media&token=85fd5f11-4ccc-425b-b20b-86dd2bb6e455",
    id: "goal-improve-flexibility",
    questions: 0,
  },
  {
    title: "Improve Happiness & State of Mind",
    url: "/goal-improve-happiness",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FImprove%20Happiness%20%26%20State%20of%20Mind.jpg?alt=media&token=21a88eed-6f48-48e8-9374-8e87da396b5e",
    id: "goal-improve-happiness",
    questions: 0,
  },
  {
    title: "Improve Maternal Health",
    url: "/goal-improve-maternal-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FImprove%20Maternal%20Health.jpg?alt=media&token=744c736f-3798-4019-aa92-c6d601e4d62f",
    id: "goal-improve-maternal-health",
    questions: 0,
  },
  {
    title: "Improve Overall Health",
    url: "/goal-improve-overall-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FImprove%20Overall%20Health.jpg?alt=media&token=aeb60ba1-f1e0-4d65-8c8e-14314f174158",
    id: "goal-improve-overall-health",
    questions: 0,
  },
  {
    title: "Improve Sexual Health & Desire",
    url: "/goal-improve-sexual-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FImprove%20Sexual%20Health%20%26%20Desire.jpg?alt=media&token=75da1cac-c55a-492a-a493-66a405e3b7f9",
    id: "goal-improve-sexual-health",
    questions: 0,
  },
  {
    title: "Improve your Immune System",
    url: "/goal-improve-immune-system",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FImprove%20your%20Immune%20System.jpg?alt=media&token=8c332f0e-176e-4463-8672-f4dda70d1a05",
    id: "goal-improve-immune-system",
    questions: 0,
  },
  {
    title: "Look & Feel Younger",
    url: "/goal-look-feel-younger",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FLook%20%26%20Feel%20Younger.jpg?alt=media&token=6b99149e-80c2-488e-b6f4-faa5906f7163",
    id: "goal-look-feel-younger",
    questions: 0,
  },
  {
    title: "Lose Weight & Gain Muscle",
    url: "/goal-lose-weight",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FLose%20Weight%20%26%20Gain%20Muscle.jpg?alt=media&token=2cab9073-89d6-496a-b49c-4e64ae0b3529",
    id: "goal-lose-weight",
    questions: 0,
  },
  {
    title: "Manage Anxiety & Depression",
    url: "/goals-manage-anxiety-depression",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FManage%20Anxiety%20%26%20Depression.jpg?alt=media&token=4869cb1c-9522-476f-a37a-ed56588e3f05",
    id: "goals-manage-anxiety-depression",
    questions: 0,
  },
  {
    title: "Manage your Diabetes",
    url: "/goals-manage-diabetes",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FManage%20your%20Diabetes.jpg?alt=media&token=a638d097-f137-4a90-99ca-b38ccda9b9d7",
    id: "goals-manage-diabetes",
    questions: 0,
  },
  {
    title: "Overcome Addiction",
    url: "/goals-overcome-addiction",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FOvercome%20Addiction.jpg?alt=media&token=1d2aaac8-d336-4004-a4db-dc82bea80db0",
    id: "goals-overcome-addiction",
    questions: 0,
  },
  {
    title: "Reduce Stress",
    url: "/goals-reduce-stress",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FReduce%20Stress.jpg?alt=media&token=920cd708-b598-4ce7-9108-35be380e117c",
    id: "goals-reduce-stress",
    questions: 0,
  },
  {
    title: "Rehab an Injury",
    url: "/goals-rehab-injury",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FRehab%20an%20Injury.jpg?alt=media&token=1cbde4a7-c1ff-4f2c-8217-33e8a13b0096",
    id: "goals-rehab-injury",
    questions: 0,
  },
  {
    title: "Sleep Better",
    url: "/goals-sleep-better",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fgoals%2FSleep%20Better.jpg?alt=media&token=8dfb55e8-7f08-4b7a-bda8-a0038154cb88",
    id: "goals-sleep-better",
    questions: 0,
  },
];