import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import "./style.css";
import {
  query,
  collection,
  getFirestore,
  where,
  orderBy,
  getDocs,
} from "@firebase/firestore";
import React from "react";
import { CSVLink } from "react-csv";

type Props = {};

const GroupExport: React.FC<Props> = () => {
  const [groups, setGroups] = React.useState([]);
  const [groupId, setGroupId] = React.useState("");

  const [csvData, setCsvData] = React.useState([]);

  const resetReport = () => {
    setCsvData([]);
  };

  const fetchGroups = async () => {
    const q = query(
      collection(getFirestore(), "groups"),
      where("active", "==", true),
      orderBy("group", "asc")
    );

    var groupsData = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      groupsData.push(doc.data());
    });

    // sort groups by group name alphabetically
    groupsData.sort((a, b) => {
      if (a.group < b.group) {
        return -1;
      }
      if (a.group > b.group) {
        return 1;
      }
      return 0;
    });

    setGroups(groupsData);
  };

  const getMembers = async () => {
    var members = [];

    // get all docs from users

    const id = groupId.split("%")[0];

    console.log("GROUP ID: x" + id + "x")

    const q = query(
      collection(getFirestore(), "users"),
      where("fromGroup", "==", id)
    );

    members = [];

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      console.log(doc.data())
      const row = {
        externalID: doc.data().externalID,
        walmartHealthId: "",
        "Email*": doc.data().email,
        Phone_Number_xxx_xxxx_xxx: doc.data().phone,
        First_Name: doc.data().firstName,
        Last_Name: doc.data().lastName,
        "DOB_mm/dd/yyyy*": doc.data().dob,
        Address_1: doc.data().addr1,
        Address_2: doc.data().addr2,
        City: doc.data().city,
        State: doc.data().state,
        Zip: doc.data().zip,
        hasFacescan: doc.data().hasFacescan ? "Yes" : "No",
        hasCoaching: doc.data().hasCoaching ? "Yes" : "No",
        hasTelemedicine: doc.data().hasTelemedicine ? "Yes" : "No",
        hasQuestis: doc.data().hasQuestis ? "Yes" : "No",
        status: doc.data().active ? "a" : "d",
      };

      members.push(row);
    });

    console.log(members)

    setCsvData(members);
  };

  const listGroups = groups.map((group) => (
    <IonSelectOption
      key={group.groupID}
      value={
        group.groupID + "%" + group.group + "%" + group.walmartHealthPlanCode
      }
    >
      {group.group}
    </IonSelectOption>
  ));

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Eligibility File Export</IonCardTitle>
        </IonCardHeader>
        <IonItem lines="none">
          <IonLabel position="stacked">Group</IonLabel>
          <IonSelect
            onFocus={(e) => fetchGroups()}
            cancelText="Cancel"
            okText="Okay"
            onIonChange={(e) => {
              setGroupId(e.detail.value);
            }}
          >
            {listGroups}
          </IonSelect>
        </IonItem>
        {csvData.length === 0 ? (
          <IonButton onClick={getMembers}>Create Export</IonButton>
        ) : (
          <>
            <CSVLink
              data={csvData}
              className="downloadButton"
              filename={"EligibilityExport.csv"}
            >
              Download Export Here
            </CSVLink>{" "}
            <br></br>
            <IonButton onClick={resetReport}>New Export</IonButton>
          </>
        )}
      </IonCard>
    </>
  );
};

export default GroupExport;
