import * as react from "@ionic/react";
import "./Members.css";
//import spinner
import React from "react";
import MyMembers from "../../components/Members/MyMembers";
import MemberLookupByEmail from "../../components/Members/MemberLookupByEmail";

const Members: React.FC = () => {
  return (
    <react.IonPage>
      <react.IonContent fullscreen>
        <react.IonHeader>
          <react.IonToolbar>
            <react.IonButtons slot="start">
              <react.IonMenuToggle>
                <react.IonMenuButton />
              </react.IonMenuToggle>
            </react.IonButtons>
            <react.IonTitle>Members</react.IonTitle>
          </react.IonToolbar>
        </react.IonHeader>

        <MyMembers />
        <MemberLookupByEmail />
      </react.IonContent>
    </react.IonPage>
  );
};

export default Members;
