import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { setupMaster } from "cluster";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { informationCircle } from "ionicons/icons";
import React, { useEffect, useState } from "react";

import "./UpcomingSessions.css";

export default function SessionLookup() {
  const [emailLookup, setEmailLookup] = useState("");
  const [lookupClicked, setLookupClicked] = useState(false);

  function SessionLookup(props) {
    const [appointment, setAppointment] = useState([]);

    const openSession = (sessionLink) => {
      window.open(sessionLink, "_blank");
    };
    const openSessionDetails = (sessionID) => {
      window.open("/session/" + sessionID, "_self");
    };

    useEffect(() => {
      fetchSessions();
    }, []);

    const fetchSessions = async () => {
      //get sessiosn from firebase
      const q = query(
        collection(getFirestore(), "sessions"),
        where("memberEmail", "==", props.email)
      );

      var sessions = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        sessions.push({ id: doc.id, ...doc.data() });
      });

      console.log("ses", sessions);

      setAppointment(sessions);
    };


    const RenderSessionsUpcomming = () => {
      let sessions = [];
      appointment.forEach((session, index) => {
        sessions.push(
          <IonItem
              key={session.id}
              className="todo"
              lines="none"
              color="primary"
              routerLink={"/session/" + session.id}
            >
              <IonIcon icon={informationCircle} slot="end"></IonIcon>{" "}
              <IonLabel class="ion-text-center todo-text ion-text-wrap">
                {" "}
                {session.startTimePretty}
                <p>{session.eventTypeName}</p>
              </IonLabel>{" "}
            </IonItem>
        );
      });

      if (sessions.length == 0) {
        return <div>No appointments found</div>;
      }

      return <>{sessions}</>;
    };

    return (
      <>
        <p>Member: {props.email}</p>
        <IonButton onClick={() => setLookupClicked(false)}>
          Search Again
        </IonButton>
        <RenderSessionsUpcomming />
      </>
    );
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Session Lookup</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {lookupClicked ? (
          <SessionLookup email={emailLookup} />
        ) : (
          <>
            <IonItem>
              <IonLabel position="stacked">Member Email</IonLabel>
              <IonInput
                type="email"
                placeholder="Enter Email"
                onIonChange={(e) => setEmailLookup(e.detail.value)}
              />
            </IonItem>
            <IonButton onClick={() => setLookupClicked(true)}>Lookup</IonButton>
          </>
        )}
      </IonCardContent>
    </IonCard>
  );
}
