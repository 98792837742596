import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonMenuToggle,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  query,
  collection,
  getFirestore,
  doc,
  orderBy,
  getDocs,
  updateDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../lib/context";
import "./Content.css";
import Loader from "../../components/Loaderv2";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

const Content: React.FC = () => {
  const cropperRef = useRef<ReactCropperElement>(null);

  const [post, setPost] = useState([]);
  const [addPost, setAddPost] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [postCatagory, setPostCatagory] = useState<string>();
  const [postCatagories, setPostCatagories] = useState([]);
  const [postTags, setPostTags] = useState([]);
  const [postCalories, setPostCalories] = useState<number>();
  const [postLength, setPostLength] = useState<number>();
  const [postType, setPostType] = useState<string>();
  const [postTitle, setPostTitle] = useState<string>();
  const [postBlurb, setPostBlurb] = useState<string>();
  const [postPhoto, setPostPhoto] = useState<File>();
  const [postPhotoUrl, setPostPhotoUrl] = useState<string>();
  const [postLink, setPostLink] = useState<string>();
  const { user, profile } = useContext(UserContext);
  const [editPostData, setEditPostData] = useState({});
  const [loading, setLoading] = useState(false);
  const [editPhoto, setEditPhoto] = useState(false);
  const [newPhoto, setNewPhoto] = useState<File>();

  const getAllPost = async () => {
    const q = query(
      collection(getFirestore(), "content"),
      orderBy("date", "desc")
    );

    var post = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      //push each post to the post array with doc id
      //add post data and id to array
      post.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    setPost(post);
  };

  const validateCatagory = (catagories) => {
    var catagoryHelper = document.getElementById("catagoryHelp");
    if (catagories === undefined || catagories.length === 0) {
      catagoryHelper.innerHTML = "Please select a catagory";
      return false;
    } else {
      catagoryHelper.innerHTML = "";
      return true;
    }
  };

  const validateTags = (tags) => {
    var tagsHelper = document.getElementById("tagsHelp");
    if (tags === undefined || tags.length === 0) {
      tagsHelper.innerHTML = "Please select tags";
      return false;
    } else {
      tagsHelper.innerHTML = "";
      return true;
    }
  };

  const validateCalories = (calories) => {
    var caloriesHelper = document.getElementById("caloriesHelp");
    if (calories === undefined || calories === "") {
      caloriesHelper.innerHTML = "Please add calories";
      return false;
    } else {
      caloriesHelper.innerHTML = "";
      return true;
    }
  };

  const validateLength = (length) => {
    var lengthHelper = document.getElementById("lengthHelp");
    if (length === undefined || length === "") {
      lengthHelper.innerHTML = "Please add length";
      return false;
    } else {
      lengthHelper.innerHTML = "";
      return true;
    }
  };

  const validateType = (type: string) => {
    var typeHelper = document.getElementById("typeHelp");
    if (type === undefined || type === "") {
      typeHelper.innerHTML = "Please select a type";
      return false;
    } else {
      typeHelper.innerHTML = "";
      return true;
    }
  };

  const validateTitle = (title: string) => {
    var titleHelper = document.getElementById("titleHelp");
    if (title === undefined || title === "") {
      titleHelper.innerHTML = "Please enter a title";
      return false;
    } else {
      titleHelper.innerHTML = "";
      return true;
    }
  };

  const validateBlurb = (blurb: string) => {
    var blurbHelper = document.getElementById("blurbHelp");
    if (blurb === undefined || blurb === "") {
      blurbHelper.innerHTML = "Please enter a blurb";
      return false;
    } else {
      blurbHelper.innerHTML = "";
      return true;
    }
  };

  const validatePhoto = () => {
    var photoHelper = document.getElementById("photoHelp");
    if (postPhoto === undefined) {
      photoHelper.innerHTML = "Please select a photo";
      return false;
    } else {
      photoHelper.innerHTML = "";
      return true;
    }
  };

  const validateLink = (link: string) => {
    var linkHelper = document.getElementById("linkHelp");
    if (link === undefined || link === "") {
      linkHelper.innerHTML = "Please enter a link";
      return false;
    } else {
      linkHelper.innerHTML = "";
      return true;
    }
  };

  const getCropData = async () => {
    if (cropperRef.current?.cropper) {
      // if (file) {
      //   authService
      //     .uploadAvatar(userId, file)
      //     .then(() => {
      //       refetchUser(userId);
      //       cancelEdit();
      //     })
      //     .catch((e) => alert(e));
      // }
    }
  };

  const addContent = async (e) => {
    e.preventDefault();

    if (
      validateCatagory(postCatagories) &&
      validateTags(postTags) &&
      validateType(postType) &&
      validateTitle(postTitle) &&
      validateBlurb(postBlurb) &&
      validatePhoto() &&
      validateLink(postLink)
    ) {
      setLoading(true);

      var keyWords = postTitle.split(" ");
      keyWords = keyWords.concat(postTags);
      keyWords = keyWords.concat(postCatagories);
      keyWords = keyWords.concat(postType);

      //make everything lowercase in keyWords
      keyWords = keyWords.map((word) => word.toLowerCase());

      const file = await fetch(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      )
        .then((res) => res.blob())
        .then((blob) => {
          console.log(new File([blob], "newAvatar.png", { type: "image/png" }));
          const storage = getStorage();
          const profilePhotoRef = ref(storage, "content/" + postTitle);
          uploadBytes(
            profilePhotoRef,
            new File([blob], "newAvatar.png", { type: "image/png" })
          ).then(() => {
            getDownloadURL(profilePhotoRef).then(async (url) => {
              const post = {
                catagoryList: postCatagories,
                type: postType,
                title: postTitle,
                blurb: postBlurb,
                photo: url,
                link: postLink,
                date: new Date().toISOString(),
                isActive: true,
                posterID: user.uid,
                poster: profile.email,
                dateTimeStamp: new Date(),
                tags: postTags,
                keyWords: keyWords,
                length: postLength === undefined ? "" : postLength,
                calories: postCalories === undefined ? "" : postCalories,
              };
              await addDoc(collection(getFirestore(), "content"), post);
              setAddPost(false);
              setLoading(false);
              setPostPhoto(undefined);
              getAllPost();
            });
          });
        });
    }
  };

  const editPostDatabase = async (e) => {
    e.preventDefault();
    setLoading(true);


    if (
      validateCatagory(editPostData["catagoryList"]) &&
      validateTags(editPostData["tags"]) &&
      validateType(editPostData["type"]) &&
      validateTitle(editPostData["title"]) &&
      validateBlurb(editPostData["blurb"])
    ) {
      if (editPhoto) {
        console.log("photo changed");

        const file = await fetch(
          cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
        )
          .then((res) => res.blob())
          .then((blob) => {
            console.log(
              new File([blob], "newAvatar.png", { type: "image/png" })
            );
            const storage = getStorage();
            const profilePhotoRef = ref(
              storage,
              "content/" + editPostData["title"]
            );
            uploadBytes(
              profilePhotoRef,
              new File([blob], "newAvatar.png", { type: "image/png" })
            ).then(() => {
              getDownloadURL(profilePhotoRef).then(async (url) => {
                const ref = doc(getFirestore(), "content", editPostData["id"]);
                await updateDoc(ref, {
                  catagoryList: editPostData["catagoryList"],
                  type: editPostData["type"],
                  title: editPostData["title"],
                  blurb: editPostData["blurb"],
                  tags: editPostData["tags"],
                  length: editPostData["length"],
                  calories: editPostData["calories"],
                  photo: url,
                })
                  .then(() => {
                    getAllPost();
                    setEditPhoto(false);
                    setNewPhoto(undefined);
                    setLoading(false);
                    setEditModal(false);
                  })
                  .catch(() => {
                    console.log("error");
                    setLoading(false);
                  });
              });
            });
          });
      } else {
        const ref = doc(getFirestore(), "content", editPostData["id"]);
        await updateDoc(ref, {
          catagoryList: editPostData["catagoryList"],
          type: editPostData["type"],
          title: editPostData["title"],
          blurb: editPostData["blurb"],
          tags: editPostData["tags"],
          length: editPostData["length"],
          calories: editPostData["calories"],
        })
          .then(() => {
            getAllPost();
            setEditModal(false);
          })
          .catch(() => {
            console.log("error");
          });
      }
    }
  };

  const changeActiveStatus = async (id, oldStatus) => {
    const db = getFirestore();
    const ref = doc(db, `content/${id}`);
    const data = {
      isActive: !oldStatus,
    };
    await updateDoc(ref, data);

    getAllPost();
  };

  const deletePost = async (id) => {
    const db = getFirestore();
    const ref = doc(db, `content/${id}`);
    await deleteDoc(ref);

    getAllPost();
  };

  const editPost = async (post) => {
    console.log(post);
    setEditPostData(post);
    setEditModal(true);
  };

  useEffect(() => {
    getAllPost();
  }, []);

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    // console.log(cropper.getCroppedCanvas().toDataURL());
    // setPostPhotoUrl(cropper.getCroppedCanvas().toDataURL());
    // setPostPhotoUrl(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Content</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* <IonButton onClick={test}>Test</IonButton> */}

        <IonList>
          <IonButton onClick={(e) => setAddPost(true)}>Add Post</IonButton>
          {post.map((post) => (
            <IonItem key={post.id}>
              <IonThumbnail slot="start">
                <img src={post.photo} />
              </IonThumbnail>
              <IonLabel>
                <div className="ion-text-wrap title">{post.title}</div>
                <p>
                  {post.type} - Posted by: {post.poster}{" "}
                </p>
                <p className="ion-text-wrap">{post.blurb}</p>
              </IonLabel>
              <IonButton
                fill="outline"
                target="_blank"
                href={post.link}
                slot="end"
              >
                View
              </IonButton>
              <IonButton
                fill="outline"
                slot="end"
                onClick={(e: any) => editPost(post)}
              >
                Edit
              </IonButton>
              <IonButton
                fill="outline"
                slot="end"
                onClick={(e: any) => changeActiveStatus(post.id, post.isActive)}
              >
                {post.isActive ? "Deactivate" : "Activate"}
              </IonButton>
              <IonButton
                fill="outline"
                slot="end"
                onClick={(e: any) => deletePost(post.id)}
              >
                Delete
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      </IonContent>

      <IonModal isOpen={addPost}>
        <IonContent>
          {loading ? <Loader /> : null}
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={(e) => {
                  setAddPost(false);
                }}
              >
                Back
              </IonButton>
            </IonButtons>
            <IonTitle>Add Post</IonTitle>
          </IonToolbar>

          <form
            onSubmit={(e) => {
              addContent(e);
            }}
          >
            <IonItem>
              <IonLabel>What catagory is post?</IonLabel>
              <IonSelect
                multiple={true}
                className="selector"
                onIonDismiss={(e: any) => validateCatagory(e.target.value)}
                onIonChange={(e) => {
                  setPostCatagory(e.detail.value);
                  setPostCatagories(e.detail.value);
                }}
              >
                <IonSelectOption value="nutrition">Nutrition</IonSelectOption>
                <IonSelectOption value="fitness">Fitness</IonSelectOption>
                <IonSelectOption value="mental-health">
                  Mental Health
                </IonSelectOption>
                <IonSelectOption value="rehabilitation">
                  Rehabilitation
                </IonSelectOption>
                <IonSelectOption value="addiction">Addiction</IonSelectOption>
                <IonSelectOption value="other">Other</IonSelectOption>
              </IonSelect>
              <p className="formHelp" id="catagoryHelp"></p>
            </IonItem>
            <IonItem>
              <IonLabel>Tags</IonLabel>
              <IonSelect
                multiple={true}
                className="selector"
                onIonDismiss={(e: any) => validateTags(e.target.value)}
                onIonChange={(e) => {
                  setPostTags(e.detail.value);
                }}
              >
                <IonSelectOption value="workout">Workout</IonSelectOption>
                <IonSelectOption value="recipe">Recipe</IonSelectOption>
                <IonSelectOption value="workshop">Workshop</IonSelectOption>
                <IonSelectOption value="quiz">Quiz</IonSelectOption>
                <IonSelectOption value="other">Other</IonSelectOption>
              </IonSelect>
              <p className="formHelp" id="tagsHelp"></p>
            </IonItem>
            <IonItem>
              <IonLabel>What type of post?</IonLabel>
              <IonSelect
                onIonDismiss={(e: any) => validateType(e.target.value)}
                onIonChange={(e) => setPostType(e.detail.value)}
              >
                <IonSelectOption value="Video">Video</IonSelectOption>
                <IonSelectOption value="Blog">Blog</IonSelectOption>
                <IonSelectOption value="Document">Document</IonSelectOption>
              </IonSelect>
              <p className="formHelp" id="typeHelp"></p>
            </IonItem>
            {postTags.includes("recipe") ? (
              <>
                <IonItem>
                  <IonLabel>Calories</IonLabel>
                  <IonInput
                    type="number"
                    required
                    placeholder="190"
                    onIonBlur={(e: any) => validateCalories(e.target.value)}
                    onIonChange={(e: any) => setPostCalories(e.target.value)}
                  ></IonInput>
                  <p className="formHelp" id="caloriesHelp"></p>
                </IonItem>
              </>
            ) : (
              <></>
            )}
            {postTags.includes("recipe") ||
            postTags.includes("workout") ||
            postType === "Video" ? (
              <>
                <IonItem>
                  <IonLabel>Length (minutes)</IonLabel>
                  <IonInput
                    type="number"
                    required
                    placeholder="35"
                    onIonBlur={(e: any) => validateLength(e.target.value)}
                    onIonChange={(e: any) => setPostLength(e.target.value)}
                  ></IonInput>
                  <p className="formHelp" id="lengthHelp"></p>
                </IonItem>
              </>
            ) : (
              <></>
            )}
            <IonItem>
              <IonLabel>Title</IonLabel>
              <IonInput
                type="text"
                required
                placeholder="How to Get Fit"
                onIonBlur={(e: any) => validateTitle(e.target.value)}
                onIonChange={(e) => setPostTitle(e.detail.value)}
              ></IonInput>
              <p className="formHelp" id="titleHelp"></p>
            </IonItem>
            <IonItem>
              <IonLabel>Blurb</IonLabel>
              <IonTextarea
                placeholder="1-3 sentences describing the post"
                required
                onIonBlur={(e: any) => validateBlurb(e.target.value)}
                onIonChange={(e) => setPostBlurb(e.detail.value)}
              ></IonTextarea>

              <p className="formHelp" id="blurbHelp"></p>
            </IonItem>
            <IonItem>
              <IonLabel>Photo</IonLabel>
              <input
                required
                type="file"
                name="photo"
                onBlur={validatePhoto}
                onChange={(e) => setPostPhoto(e.target.files[0])}
              />
              {postPhoto ? (
                <Cropper
                  src={URL.createObjectURL(postPhoto)}
                  style={{ height: 400, width: 400 }}
                  // Cropper.js options
                  initialAspectRatio={16 / 9}
                  guides={false}
                  dragMode="move"
                  viewMode={1}
                  autoCropArea={1}
                  scalable={true}
                  cropBoxMovable={false}
                  cropBoxResizable={false}
                  crop={onCrop}
                  ref={cropperRef}
                />
              ) : (
                <></>
              )}

              <p className="formHelp" id="photoHelp"></p>
            </IonItem>
            <IonItem>
              <IonLabel>Link to content</IonLabel>
              <IonInput
                type="text"
                required
                placeholder="www.blog.com"
                onIonBlur={(e: any) => validateLink(e.target.value)}
                onIonChange={(e) => setPostLink(e.detail.value)}
              ></IonInput>
              <p className="formHelp" id="linkHelp"></p>
            </IonItem>
            <p className="formHelp" id="formHelper  "></p>
            <IonButton type="submit" className="ion-padding" expand="block">
              Add
            </IonButton>
          </form>
        </IonContent>
      </IonModal>

      <IonModal isOpen={editModal}>
        <IonContent>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={(e) => {
                  setEditModal(false);
                }}
              >
                Back
              </IonButton>
            </IonButtons>
            <IonTitle>Edit Post</IonTitle>
          </IonToolbar>
          <form
            onSubmit={(e) => {
              editPostDatabase(e);
            }}
          >
            <IonItem>
              <IonLabel>What catagory is post?</IonLabel>
              <IonSelect
                multiple={true}
                onIonDismiss={(e: any) => validateCatagory(e.target.value)}
                onIonChange={(e) =>
                  setEditPostData({
                    ...editPostData,
                    catagoryList: e.detail.value,
                  })
                }
                value={editPostData["catagoryList"]}
              >
                <IonSelectOption value="nutrition">Nutrition</IonSelectOption>
                <IonSelectOption value="fitness">Fitness</IonSelectOption>
                <IonSelectOption value="mental-health">
                  Mental Health
                </IonSelectOption>
                <IonSelectOption value="rehabilitation">
                  Rehabilitation
                </IonSelectOption>
                <IonSelectOption value="addiction">Addiction</IonSelectOption>
                <IonSelectOption value="other">Other</IonSelectOption>
              </IonSelect>
              <p className="formHelp" id="catagoryHelp"></p>
            </IonItem>
            <IonItem>
              <IonLabel>Tags</IonLabel>
              <IonSelect
                multiple={true}
                className="selector"
                onIonDismiss={(e: any) => validateTags(e.target.value)}
                onIonChange={(e) =>
                  setEditPostData({ ...editPostData, tags: e.detail.value })
                }
                value={editPostData["tags"]}
              >
                <IonSelectOption value="workout">Workout</IonSelectOption>
                <IonSelectOption value="recipe">Recipe</IonSelectOption>
                <IonSelectOption value="workshop">Workshop</IonSelectOption>
                <IonSelectOption value="quiz">Quiz</IonSelectOption>
                <IonSelectOption value="other">Other</IonSelectOption>
              </IonSelect>
              <p className="formHelp" id="tagsHelp"></p>
            </IonItem>
            <IonItem>
              <IonLabel>What type of post?</IonLabel>
              <IonSelect
                onIonDismiss={(e: any) => validateType(e.target.value)}
                onIonChange={(e) =>
                  setEditPostData({ ...editPostData, type: e.detail.value })
                }
                value={editPostData["type"]}
              >
                <IonSelectOption value="Blog">Blog</IonSelectOption>
                <IonSelectOption value="Video">Video</IonSelectOption>
                <IonSelectOption value="Document">Document</IonSelectOption>
              </IonSelect>
              <p className="formHelp" id="typeHelp"></p>
            </IonItem>
            {editPostData["tags"] && editPostData["tags"].includes("recipe") ? (
              <>
                <IonItem>
                  <IonLabel>Calories</IonLabel>
                  <IonInput
                    type="number"
                    required
                    placeholder="190"
                    onIonBlur={(e: any) => validateCalories(e.target.value)}
                    onIonChange={(e: any) =>
                      setEditPostData({
                        ...editPostData,
                        calories: e.target.value,
                      })
                    }
                    value={editPostData["calories"]}
                  ></IonInput>
                  <p className="formHelp" id="caloriesHelp"></p>
                </IonItem>
              </>
            ) : (
              <></>
            )}
            {editPostData["tags"] &&
            editPostData["type"] &&
            (editPostData["tags"].includes("recipe") ||
              editPostData["tags"].includes("workout") ||
              editPostData["type"] === "Video") ? (
              <>
                <IonItem>
                  <IonLabel>Length (minutes)</IonLabel>
                  <IonInput
                    type="number"
                    required
                    placeholder="35"
                    value={editPostData["length"]}
                    onIonBlur={(e: any) => validateLength(e.target.value)}
                    onIonChange={(e: any) =>
                      setEditPostData({
                        ...editPostData,
                        length: e.target.value,
                      })
                    }
                  ></IonInput>
                  <p className="formHelp" id="lengthHelp"></p>
                </IonItem>
              </>
            ) : (
              <></>
            )}
            <IonItem>
              <IonLabel>Title</IonLabel>
              <IonInput
                type="text"
                required
                placeholder="How to Get Fit"
                onIonBlur={(e: any) => validateTitle(e.target.value)}
                onIonChange={(e) =>
                  setEditPostData({ ...editPostData, title: e.detail.value })
                }
                value={editPostData["title"]}
              ></IonInput>
              <p className="formHelp" id="titleHelp"></p>
            </IonItem>
            <IonItem>
              <IonLabel>Blurb</IonLabel>
              <IonTextarea
                placeholder="1-3 sentences describing the post"
                required
                className="ion-padding"
                onIonBlur={(e: any) => validateBlurb(e.target.value)}
                onIonChange={(e) =>
                  setEditPostData({ ...editPostData, blurb: e.detail.value })
                }
                value={editPostData["blurb"]}
              ></IonTextarea>
              <p className="formHelp" id="blurbHelp"></p>
            </IonItem>
            <IonItem>
              <IonLabel>Photo</IonLabel>
              <input
                type="file"
                name="photo"
                onBlur={validatePhoto}
                onChange={(e) => {
                  setNewPhoto(e.target.files[0]);
                  setEditPhoto(true);
                }}
              />
              {newPhoto ? (
                <Cropper
                  src={URL.createObjectURL(newPhoto)}
                  style={{ height: 400, width: 400 }}
                  // Cropper.js options
                  initialAspectRatio={16 / 9}
                  guides={false}
                  dragMode="move"
                  viewMode={1}
                  autoCropArea={1}
                  scalable={true}
                  cropBoxMovable={false}
                  cropBoxResizable={false}
                  crop={onCrop}
                  ref={cropperRef}
                />
              ) : (
                <></>
              )}

              <p className="formHelp" id="photoHelp"></p>
            </IonItem>
            <p className="formHelp" id="formHelper  "></p>
            <IonButton type="submit" className="ion-padding" expand="block">
              Add
            </IonButton>
          </form>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Content;
