import "./Topbar.css";
import * as react from "@ionic/react";
import { home, menu, person } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router";

const Topbar: React.FC = () => {
  const history = useHistory();
  const handleClickMyProfile = () => {
    history.replace("/page/MyProfile");
    // handle profile click
  };

  const handleClickHome = () => {
    history.replace("/page/home");
    // handle home click
  };

  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT === "prod" ? null : (
        <>
          {" "}
          <react.IonHeader>- Dev/Staging -</react.IonHeader>
        </>
      )}

      <react.IonToolbar color="light">
        <react.IonTitle color="primary">ALLIED WELLNESS</react.IonTitle>
        <react.IonMenuButton slot="start" id="mainMenu"></react.IonMenuButton>
        <react.IonButton slot="end" onClick={handleClickHome}>
          <react.IonIcon slot="icon-only" icon={home}></react.IonIcon>
        </react.IonButton>
        <react.IonButton slot="primary" onClick={handleClickMyProfile}>
          <react.IonIcon slot="icon-only" icon={person}></react.IonIcon>
        </react.IonButton>
      </react.IonToolbar>
    </>
  );
};

export default Topbar;
