import { heartOutline } from "ionicons/icons";
import { useEffect } from "react";

export interface ClinicalConcerns {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  img: string;
  id: string;
  questions: number;
}

// Headaches & Neuropathic Pain
// Hormone Health
// Medication & Prescriptions
// Men's Health
// Neurological Health
// Obesity & Weightloss
// Oral Care
// Physical Therapy
// Respiratory Health
// Sexual Health
// Sleep Health & Insomnia
// Surgery & Orthopedics
// Women's Health


const testImg =
  "https://www.villaggioorizzonte.it/wp-content/uploads/2019/11/vacanza-yoga-natura.jpg";

// create clinical concern object list object from list above
export const ClinicalConcernsList: ClinicalConcerns[] = [
  {
    title: "Acne & Skincare",
    url: "/clinical-concern-acne-skincare",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FAcne%20%26%20Skincare.png?alt=media&token=e3b212a5-abb5-4fc8-ad57-4ae809b1f5bc",
    id: "clinical-concern-acne-skincare",
    questions: 0,
  },
  {
    title: "Addiction Management",
    url: "/clinical-concern-addiction-management",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FAddiction%20Management.png?alt=media&token=ad6fa799-f289-4fd3-a32d-72680d7bea5f",
    id: "clinical-concern-addiction-management",
    questions: 0,
  },
  {
    title: "Allergy / Immunology",
    url: "/clinical-concern-allergy-immunology",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FAllergy%20Immunology.png?alt=media&token=9aafac87-70fc-472c-bd13-2e5073f81c42",
    id: "clinical-concern-allergy-immunology",
    questions: 0,
  },
  {
    title: "Anxiety / Depression / Stress",
    url: "/clinical-concern-anxiety-depression-stress",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FAnxiety%20Depression%20Stress.png?alt=media&token=34cbe76c-ad59-4fed-a80a-40ae164c1c69",
    id: "clinical-concern-anxiety-depression-stress",
    questions: 0,
  },
  {
    title: "Auto-Immune Disorders",
    url: "/clinical-concern-auto-immune-disorders",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FAuto-Immune%20Disorders.png?alt=media&token=e212bf1a-c8c8-4652-adfc-9f7b9d19130a",
    id: "clinical-concern-auto-immune-disorders",
    questions: 0,
  },
  {
    title: "Cancer",
    url: "/clinical-concern-cancer",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FCancer.png?alt=media&token=b67d6ea2-b9aa-4142-8492-dcd49d5944fe",
    id: "clinical-concern-cancer",
    questions: 0,
  },
  {
    title: "Cardiology & Heart Health",
    url: "/clinical-concern-cardiology-heart-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FCardiology%20%26%20Heart%20Health.png?alt=media&token=144b0ea7-2f16-43b1-a397-34cc7ad4afba",
    id: "clinical-concern-cardiology-heart-health",
    questions: 0,
  },
  {
    title: "Child & Adolescent Health",
    url: "/clinical-concern-child-adolescent-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FChild%20%26%20Adolescence%20Health.png?alt=media&token=c9947b49-8a73-4bf2-8574-ae0e6aa28576",
    id: "clinical-concern-child-adolescent-health",
    questions: 0,
  },
  {
    title: "Chronic Illness",
    url: "/clinical-concern-chronic-illness",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FChronic%20Illness.png?alt=media&token=b7889244-b30b-4a9b-81d1-e328de1333cd",
    id: "clinical-concern-chronic-illness",
    questions: 0,
  },
  {
    title: "Cold & Flu",
    url: "/clinical-concern-cold-flu",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FCold%20%26%20Flu.png?alt=media&token=ba95e757-3d89-4e73-99b1-80288902ee02",
    id: "clinical-concern-cold-flu",
    questions: 0,
  },
  {
    title: "COVID-19",
    url: "/clinical-concern-covid-19",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FCOVID-19.png?alt=media&token=10efa833-95d4-49bf-bd70-e7b79f9f91d2",
    id: "clinical-concern-covid-19",
    questions: 0,
  },
  {
    title: "Diabetes",
    url: "/clinical-concern-diabetes",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FDiabetes.png?alt=media&token=994089c3-1fc7-4647-807b-30b1a6627ec2",
    id: "clinical-concern-diabetes",
    questions: 0,
  },
  {
    title: "Ear, Nose, and Throat",
    url: "/clinical-concern-ear-nose-throat",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FEar%20Nose%20and%20Throat.png?alt=media&token=eb168078-67a6-4655-be4d-15be3cefda1b",
    id: "clinical-concern-ear-nose-throat",
    questions: 0,
  },
  {
    title: "Fertility & Maternal Health",
    url: "/clinical-concern-fertility-maternal-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FFertility%20%26%20Maternal%20Health.png?alt=media&token=d6e4d434-281e-45be-8e9e-94220c7894f4",
    id: "clinical-concern-fertility-maternal-health",
    questions: 0,
  },
  {
    title: "Fitness / Diet / Nutrition / Supplementation",
    url: "/clinical-concern-fitness-diet-nutrition-supplementation",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FFitness%20Diet%20Nutrition%20Supplementation.png?alt=media&token=0baca24a-29b0-4ce4-b11f-ffba42bfe298",
    id: "clinical-concern-fitness-diet-nutrition-supplementation",
    questions: 0,
  },
  {
    title: "Gastrointestinal Health",
    url: "/clinical-concern-gastrointestinal-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FGastrointestinal%20Health.png?alt=media&token=f336beb6-25c5-4dff-b148-250c98da94b6",
    id: "clinical-concern-gastrointestinal-health",
    questions: 0,
  },
  {
    title: "Headaches & Neuropathic Pain",
    url: "/clinical-concern-headaches",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FHeaches%20%26%20Neuropathic%20Pain.png?alt=media&token=5e07377e-856e-4094-9e92-ebc3bdb3d7f6",
    id: "clinical-concern-headaches",
    questions: 0,
  },
  {
    title: "Hormone Health",
    url: "/clinical-concern-hormone-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FHormone%20Health.png?alt=media&token=2e599146-c1be-4b11-9a24-1bb2f309152e",
    id: "clinical-concern-hormone-health",
    questions: 0,
  },
  {
    title: "Medication & Prescriptions",
    url: "/clinical-concern-medication",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FMedication%20%26%20Prescriptions.png?alt=media&token=99aa4b6c-0bf1-4c5a-9d27-48c7f2d2bc58",
    id: "clinical-concern-medication",
    questions: 0,
  },
  {
    title: "Men's Health",
    url: "/clinical-concern-mens-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FMen's%20Health.jpg?alt=media&token=f6b1f029-971c-4468-b9aa-ac9fdb8c16d3",
    id: "clinical-concern-mens-health",
    questions: 0,
  },
  {
    title: "Neurological Health",
    url: "/clinical-concern-neurological-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FNeurological%20Health.jpg?alt=media&token=c0785745-bbe2-4ff2-9143-c5cbf5c5e091",
    id: "clinical-concern-neurological-health",
    questions: 0,
  },
  {
    title: "Obesity & Weightloss",
    url: "/clinical-concern-obesity",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FObesity%20%26%20Weight%20Loss.png?alt=media&token=4b5a1dbc-9c39-4ea0-841b-770f662ad9b8",
    id: "clinical-concern-obesity",
    questions: 0,
  },
  {
    title: "Oral Care",
    url: "/clinical-concern-oral-care",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FOral%20Care.png?alt=media&token=598a1b3d-8bef-4a3a-9231-de31f7daddc9",
    id: "clinical-concern-oral-care",
    questions: 0,
  },
  {
    title: "Physical Therapy",
    url: "/clinical-concern-physical-therapy",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FPhysical%20Therapy.png?alt=media&token=774aa78e-c327-43fa-bb0c-eddb29144820",
    id: "clinical-concern-physical-therapy",
    questions: 0,
  },
  {
    title: "Respiratory Health",
    url: "/clinical-concern-respiratory-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FRespiratory%20Health.png?alt=media&token=e08394a5-0238-4a52-b1df-036d82f0866c",
    id: "clinical-concern-respiratory-health",
    questions: 0,
  },
  {
    title: "Sexual Health",
    url: "/clinical-concern-sexual-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FSexual%20Health.jpg?alt=media&token=97291c5e-b359-490d-9148-bcf052bf8d36",
    id: "clinical-concern-sexual-health",
    questions: 0,
  },
  {
    title: "Sleep Health & Insomnia",
    url: "/clinical-concern-sleep-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FSleep%20Health%20%26%20Insomnia.jpeg?alt=media&token=4a1c3990-4dd3-4622-bc33-1b3c93465a3a",
    id: "clinical-concern-sleep-health",
    questions: 0,
  },
  {
    title: "Surgery & Orthopedics",
    url: "/clinical-concern-surgery",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FSurgery%20%26%20Orthopedics.png?alt=media&token=82ecddb9-df58-4856-84ec-1f658514b2d0",
    id: "clinical-concern-surgery",
    questions: 0,
  },
  {
    title: "Women's Health",
    url: "/clinical-concern-womens-health",
    iosIcon: heartOutline,
    mdIcon: heartOutline,
    img: "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/bodee%2Fclinical%20concerns%2FWomen's%20Health.png?alt=media&token=0278bd2f-a4bc-4737-b4e7-a036d979cda3",
    id: "clinical-concern-womens-health",
    questions: 0,
  },
];




