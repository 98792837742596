export const timeSince = (date) => {

  // console.log(date)

  if (isNaN(date.seconds)) {
    return "0 seconds";
  }

  const newDate = new Date(date.seconds * 1000);

  // console.log(newDate)

  // calculate how long ago 

  const seconds = Math.floor((new Date().getTime() - newDate.getTime()) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval + "y";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + "mo";
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + "d";
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + "h";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + "m";
  }

  return Math.floor(seconds) + "s";
  
};

export default timeSince;
