import { IonButton, IonLabel } from "@ionic/react";
import "./style.css";
import { doc, getFirestore, updateDoc } from "@firebase/firestore";
import { useContext, useState } from "react";
import { UserContext } from "../../../../../lib/context";

type Props = {
  q;
};

const ClaimQuestionButton: React.FC<Props> = ({ q }) => {
  const { profile } = useContext(UserContext);

  const [question, setQuestion] = useState(q);

  const claimQuestion = async () => {
    console.log("claiming question");
    const id = question.id;

    setQuestion({
      ...question,
      claimed: true,
      claimedBy: profile.uid,
      claimedByDisplayName: profile.firstName + " " + profile.lastName,
      claimedDate: new Date(),
    });

    const docRef = doc(getFirestore(), "koroQuestions", id);
    updateDoc(docRef, {
      claimed: true,
      claimedBy: profile.uid,
      claimedByDisplayName: profile.firstName + " " + profile.lastName,
      claimedDate: new Date(),
    });
  };

  const RenderDate = ({ date }) => {
    const newDate = new Date(date.seconds * 1000);

    // see if date.seconds exist
    if (date.seconds) {
      return (
        <>
          {newDate.toLocaleDateString()} {newDate.toLocaleTimeString()}
        </>
      );
    } else {
      return (
        <>
          {date.toLocaleDateString()} {date.toLocaleTimeString()}
        </>
      );
    }
  };

  if (question.claimed) {
    return (
      <>
        <IonLabel className="ion-text-wrap ion-text-center">
          <p>
            Claimed by: {question.claimedByDisplayName} on{" "}
            <RenderDate date={question.claimedDate} />
          </p>
        </IonLabel>
      </>
    );
  }

  return (
    <>
      <IonButton shape="round" onClick={() => claimQuestion()}>
        Claim Question
      </IonButton>
    </>
  );
};

export default ClaimQuestionButton;
