import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { NewNotificationType } from "../../Types/notification";
import "./Notifications.css";
import {
  IonButton,
  IonCard,
  IonCardSubtitle,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  useIonAlert,
} from "@ionic/react";
import { chatbox, trash } from "ionicons/icons";
import { UserContext } from "../../lib/context";

function Notifications() {
  const [notifications, setNotifications] = useState<NewNotificationType[]>([]);
  const [dissmissedNotification, setDissmissedNotification] = useState("");
  const [presentAlert] = useIonAlert();
  const { user, profile } = useContext(UserContext);
  const history = useHistory();

  const handleMemberClick = (noti: NewNotificationType) => {
    const route = "/member-dash/" + noti.memberUid;
    window.location.href = route;
    history.push(route);
  };
  const handleChatClick = (noti: NewNotificationType) => {
    const route = "/page/chat/" + noti.memberUid;
    window.location.href = route;
    history.push(route);
  };

  // Subscribe to notifications collection on component mount and unsubscribe on component unmount
  useEffect(() => {
    const q = query(
      collection(getFirestore(), "notifications"),
      where("coachUid", "==", user.uid)
    );
    console.log(user.uid);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setNotifications(
        snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );
    });

    return unsubscribe;
  }, [user.uid]);
  const handleDeleteClick = async (item: NewNotificationType) => {
    setDissmissedNotification(item.id);
    await updateDoc(doc(collection(getFirestore(), "notifications"), item.id), {
      dismissed: true,
    });
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === item.id
          ? { ...notification, dismissed: true }
          : notification
      )
    );
  };
  const handleClearAllClick = async () => {
    // Present an alert to confirm the action
    presentAlert({
      header: "DELETE ALL NOTIFICATIONS?",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Confirm",
          handler: async () => {
            // Update the dismissed property of all the notifications to true
            await Promise.all(
              notifications.map((notification) =>
                updateDoc(
                  doc(
                    collection(getFirestore(), "notifications"),
                    notification.id
                  ),
                  {
                    dismissed: true,
                  }
                )
              )
            );
            // Update the state of notifications to reflect the changes
            setNotifications((prevNotifications) =>
              prevNotifications.map((notification) => ({
                ...notification,
                dismissed: true,
              }))
            );
          },
        },
      ],
    });
  };

  const sortedNotifications = notifications.sort((a, b) => {
    if (a.opened === b.opened) {
      // If both notifications are either opened or unopened, sort by time ago
      return moment(b.timeStamp.toDate()).diff(moment(a.timeStamp.toDate()));
    } else {
      // Otherwise, sort unopened notifications before opened notifications
      return a.opened ? 1 : -1;
    }
  });

  // Render the list of notifications
  return (
    <div>
      <IonButton color="danger" onClick={handleClearAllClick}>
        Clear All
      </IonButton>
      {sortedNotifications.map((item) => {
        const timeAgo =
          item.timeStamp && moment(item.timeStamp.toDate()).fromNow();

        if (!item.dismissed) {
          return (

              <IonCard className="card-container">
                <div className="profile-container">
                  <img
                    alt="Member Profile"
                    src={item.photoUrl}
                    className="profileimage"
                    onClick={() => handleMemberClick(item)}
                    />
                </div>
                <div className="membername">
                  <IonTitle
                    color="primary"
                    className="iontitle-member"
                    onClick={() => handleMemberClick(item)}
                    >
                    {item.memberName}
                  </IonTitle>
                  <IonCardSubtitle>{item.msgType}</IonCardSubtitle>
                  <IonCardSubtitle color="danger">{timeAgo}</IonCardSubtitle>
                </div>
                <div className="trash-button">
                  <IonButton
                    color="secondary"
                    onClick={() => handleChatClick(item)}
                    >
                    <IonIcon slot="icon-only" icon={chatbox} />
                  </IonButton>
                  <IonButton
                    color="danger"
                    onClick={() => handleDeleteClick(item)}
                    >
                    <IonIcon slot="icon-only" icon={trash} />
                  </IonButton>
                </div>
              </IonCard>
          );
        }
      })}
    </div>
  );
}

export default Notifications;
